import * as React from "react"
import { type VariantProps } from "class-variance-authority"
import { Link as TanstackLink } from '@tanstack/react-router'

import { cn } from "@/lib/utils"
import { focusRing } from "@/components/ui/shared/component-state"

import { buttonVariants } from "../form/button/button-variant"
import { Icons } from "../icons"

export type LinkVariantProps = VariantProps<typeof buttonVariants>

export interface LinkProps
    extends Omit<React.ComponentProps<typeof TanstackLink>, "size">,
    LinkVariantProps {
}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
    ({ className, variant, size, to, href, children, ...props }, ref) => {
        const [isExternal] = React.useState(
            isExternalURL(to) ||
            isExternalURL(href)
        )
        return (
            <TanstackLink
                className={cn(
                    focusRing,
                    isExternal ? "flex flex-row gap-1 items-center justify-start" : "",
                    buttonVariants({ variant, size, className }),
                )}
                ref={ref}
                href={href}
                to={to}
                {...props}
            >
                <>
                    {children}

                    {
                        isExternal && <Icons.itinerary.indicator.link />
                    }
                </>
            </TanstackLink>
        )
    }
)
Link.displayName = "Link"

export { Link }

function isExternalURL(url: string | URL | undefined) {
    if (!url || !url.toString().includes('http')) {
        return false;
    }

    const u = url instanceof URL ? url : new URL(url);
    return u.origin !== location.origin;
} 
