import { Icon, IconOptions, LatLngExpression } from "leaflet"
import { Marker } from "react-leaflet/Marker"

import { getLatLng } from "@/lib/utils"
import { appRoute } from "../routes/app-route"
import { MAP_ASSETS } from "@/config/assets"


export function MapMarkers() {
  const placeFrom = appRoute.useSearch({ select: (search) => search.from})
  const placeTo = appRoute.useSearch({ select: (search) => search.to})
  const from = placeFrom?.latlng;
  const to = placeTo?.latlng;

  return (
    <>
      <RouteMarker
        marker={{
          position: from,
          icon: MAP_ASSETS.marker.start,
        }}
      />

      <RouteMarker
        marker={{
          position: to,
          icon: MAP_ASSETS.marker.end,
        }}
      />
    </>
  )
}

type RouteMarkerProps = {
  marker: {
    icon: IconOptions
    position?: LatLngExpression
  }
}
export function RouteMarker({ marker }: RouteMarkerProps) {
  if (!marker.position) {
    return
  }

  const latlng = getLatLng(marker.position)
  return (
    <>
      <Marker
        position={latlng}
        icon={
          new Icon(marker.icon)
        }
      />
    </>
  )
}
