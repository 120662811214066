import { ItineraryIcon, ItineraryItem } from "@/components/ui/containers/timeline";
import { getIconFromMode } from "../get-icon-from-mode";
import { StopWithLeg } from "./itinerary-details.legs";
import { Icons } from "@/components/ui/icons";
import { ItineraryDetailsStep } from "./itinerary-details.step";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/containers/accordion";
import { timeSpanInSecondsFormatter } from "@/lib/formatters/time-span-formatters";
import { LegSchema, PlaceSchema } from "@/services/otp/validations/planner.graphql";
import { ModeSchema } from "@/services/otp/validations/base.grpahql";
import { RouteSchema } from "@/services/otp/validations/routes.graphql";
import { titleCaseFormatter } from "@/lib/formatters/string-formatters";
import { ItineraryDetailsStop } from "./itinerary-details.stop";
import { Link } from "@tanstack/react-router";

export function ItineraryDetailsLeg({
    stop,
    leg,
}:
    StopWithLeg
) {

    return (
        <ItineraryItem
            title={stop.title}
            description={stop.description}
            borderStyling={leg?.mode === "WALK" ? "dotted" : "solid"}
            variant={leg?.mode === "WALK" ? "border" : "primary"}
            {...stop.props}
        >
            {{
                icon: (stop?.icon
                    ? stop.icon
                    : <ItineraryIcon icon={<Icons.itinerary.stop />} />
                ),
                rest: (leg && <ItineraryDetailsLegContent {...leg} />
                )
            }}
        </ItineraryItem>
    )
}

function ItineraryDetailsLegContent({ mode, duration, steps, intermediateStops, route, agency, to }: LegSchema) {
    return (
        <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2 items-center">
                <span>
                    {getIconFromMode(mode)}
                </span>
                <span>
                    {getDescriptionFromLeg(to, route, mode)}
                </span>
            </div>

            {
                intermediateStops && intermediateStops.length > 0 &&
                <Accordion type="single" collapsible>
                    <AccordionItem value="item-1">
                        <AccordionTrigger className="py-1 text-muted-foreground text-sm w-32">
                            <span className="flex flex-row gap-1">
                                {timeSpanInSecondsFormatter(duration)}
                                {" "}ride ({intermediateStops.length} intermediate stop{intermediateStops.length === 1 ? "" : "s"})
                            </span>
                        </AccordionTrigger>
                        <AccordionContent>
                            {intermediateStops && intermediateStops.map((s) => {
                                return (
                                    <ItineraryDetailsStop
                                        key={`${s.name}`}
                                        {...s}
                                    />
                                )
                            })}
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            }

            {
                steps && steps.length > 0 &&
                <Accordion type="single" collapsible>
                    <AccordionItem value="item-1">
                        <AccordionTrigger className="py-1 text-muted-foreground text-sm w-32">
                            {duration
                                ? <>{timeSpanInSecondsFormatter(duration)}</>
                                : <>N/A</>
                            }
                        </AccordionTrigger>
                        <AccordionContent>
                            {steps && steps.map((s) => {
                                return (
                                    <ItineraryDetailsStep
                                        key={`${s.lat}-${s.lon}`}
                                        {...s}
                                    />
                                )
                            })}
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            }

            {
                agency && agency.name !== "" && (
                    <div className="flex flex-row gap-1 text-xs text-strong">
                        Service operated by{" "}
                        <Link 
                            target="_blank"
                            className="font-bold hover:underline flex flex-row gap-1 items-center" 
                            to={agency.fareUrl ?? ""}
                        >
                            {agency.name}
                            <Icons.itinerary.indicator.link className="w-3 h-3" />
                        </Link>
                    </div>
                )
            }

        </div >

    )
}

function getDescriptionFromLeg(to?: PlaceSchema | null, route?: RouteSchema | null, mode?: ModeSchema | null) {
    const action = route?.longName
        ?? titleCaseFormatter(mode)
        ?? ""

    const location = to?.name

    return `${action} to ${location}`

}