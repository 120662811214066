"use client"

import * as React from "react"
import { VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import { inputVariants } from "@/components/ui/form/input/input-variants"

import { LocationSearch } from "./location-search"
import { InjectMapProps } from "@/types/leaflet"
import { DropdownMenuActions } from "@/components/menus/dropdown-menu.actions"

export interface SearchProps
  extends React.HTMLAttributes<HTMLDivElement>,
    InjectMapProps,
    VariantProps<typeof inputVariants> {}

export const Search = React.forwardRef<HTMLDivElement, SearchProps>(
  ({ className, map, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "container px-2 grid shadow-input h-16",
          "grid-cols-2 sm:grid-cols-[auto_minmax(4rem,_1fr)_minmax(4rem,_1fr)]",
          className
        )}
        {...rest}
      >
        <DropdownMenuActions className="hidden sm:grid pl-0.5" map={map} />

        <LocationSearch map={map} />
      </div>
    )
  }
)

Search.displayName = "Search"
