import { Icons } from "@/components/ui/icons"
import { ModeSchema } from "@/services/otp/validations/base.grpahql"
import { LucideProps } from "lucide-react"

const defaultProps: LucideProps = {};

export function getIconFromMode(mode?: ModeSchema | null, props?: LucideProps) {
    if (mode === "WALK") {
        return Icons.itinerary.walk(props ?? defaultProps)
    }

    if (mode === "BUS") {
        return Icons.itinerary.bus(props ?? defaultProps)
    }

    if (mode === "CAR") {
        return Icons.itinerary.car(props ?? defaultProps)
    }

    if (mode === "FERRY") {
        return Icons.itinerary.ferry(props ?? defaultProps)
    }

    return Icons.itinerary.stop(props ?? defaultProps)
}