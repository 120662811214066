import React from "react";
import { cn } from "@/lib/utils";

const ListItem = React.forwardRef<HTMLLIElement, React.HTMLAttributes<HTMLLIElement>>(
  (props, ref) => {
    const { children, className, ...other } = props;
    return (
      <>
        <li
          ref={ref}
          className={cn(
            "list-item-root",
            // common
            "w-full flex justify-between items-center text-foreground py-2",
            className,
          )}
          {...other}
        >
          {children}
        </li>
      </>
    );
  },
);

ListItem.displayName = "ListItem";

export { ListItem };