import { Itinerary, ItineraryIcon, ItineraryIconProps, ItineraryItemProps } from "@/components/ui/containers/timeline";
import { ItineraryDetailsLeg } from "./itinerary-details.leg";
import { MAP_ASSETS } from "@/config/assets";
import { appRoute } from "@/components/routes/app-route";
import { ItinerarySchema, LegSchema } from "@/services/otp/validations/planner.graphql";
import { AppPlace } from "@/components/routes/app-route.zod";
import { sentenceCaseFormatter } from "@/lib/formatters/string-formatters";
import Typography from "@/components/ui/typeography/typeography";

export function ItineraryDetailsLegs({ startTime, endTime, legs }: ItinerarySchema) {
    const from = appRoute.useSearch({ select: (s) => s.from })
    const to = appRoute.useSearch({ select: (s) => s.to })

    if (!startTime || !endTime || !legs || !from || !to) {
        return;
    }
    const transformedLegs = transformLegsToStops(from, to, startTime, endTime, legs)

    if (!transformedLegs || transformedLegs.length <= 0) {
        return;
    }

    return (
        <span className="flex flex-col gap-4">
            <Typography variant={"h3"} color={"foreground"}>Itinerary</Typography>

            <Itinerary className="mb-4">
                {/** Legs */}
                {transformedLegs.map((l) => {
                    return (
                        <ItineraryDetailsLeg
                            key={`${l.stop.time.toUTCString()}-${l.stop.title}`}
                            {...l}
                        />
                    )
                })}
            </Itinerary>
        </span>
    )
}

export interface StopWithLeg {
    stop: {
        time: Date,
        title: string,
        description?: string,
        icon?: React.ReactElement<ItineraryIconProps>;
        props?: Partial<ItineraryItemProps>
    },
    leg?: LegSchema,
}

function transformLegsToStops(from: AppPlace, to: AppPlace, startTime: number, endTime: number, legs: ItinerarySchema["legs"]) {
    if (!legs) {
        return [];
    }

    const stopWithLegList: StopWithLeg[] = []

    for (let i = 0; i < legs.length; i++) {
        const leg = legs[i]
        if (!leg) {
            break;
        }

        if (i === 0) {
            stopWithLegList.push({
                stop: {
                    time: new Date(startTime),
                    title: from.key,
                    icon: <ItineraryIcon variant={"secondary"} icon={<img src={MAP_ASSETS.marker.start.iconUrl} className="aspect-square w-4 h-4" />} />,
                    props: {
                        title: from.key,
                    }
                },
                leg: leg
            })
            continue;
        }

        if (leg.startTime) {
            stopWithLegList.push({
                stop: {
                    time: new Date(leg.startTime),
                    title: sentenceCaseFormatter(tryGetLegTitle(leg))
                },
                leg: leg
            })
            continue;
        }
    }

    stopWithLegList.push({
        stop: {
            time: new Date(endTime),
            title: to.key,
            icon: <ItineraryIcon variant={"secondary"} icon={<img src={MAP_ASSETS.marker.end.iconUrl} width={16} />} />,
            props: {
                title: to.key,
                borderStyling: "none"
            }
        },
    })

    return stopWithLegList;
}

function tryGetLegTitle(leg: LegSchema) {
    return  leg.from?.name 
        ?? leg.route?.longName
        ?? leg.agency?.name
        ?? leg.mode
        ?? "N/A"
}
