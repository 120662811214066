import { MenuItem } from "@/components/ui/primitives/menu-item";
import { SetPlaceItemContent, SetPlaceItemProps } from "./set-place-item.content";
import { useDropMarker } from "@/hooks/use-drop-marker";

export function SetPlaceItemInteractive({ location, variant }: Omit<SetPlaceItemProps, "contextOpenedAt">) {
    const [_, setDropping] = useDropMarker();

    return (
        <>
            <MenuItem
                id="set_place_interactive"
                variant={variant}
                onClick={() => {
                    setDropping(location)
                }}
                className="gap-4"
            >
                <SetPlaceItemContent location={location} />
            </MenuItem>
        </>
    )
}
