/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'
import * as goober from 'goober'
import { clsx as cx } from 'clsx'
import {
  getStatusColor,
  useIsMounted,
  useSafeState,
} from './utils'
import Explorer from './explorer'
import { tokens } from './tokens'
import {
  DevtoolsOnCloseContext,
  ShadowDomTargetContext,
  useDevtoolsOnClose,
} from './context'
import { usePlanner } from '@/hooks/use-planner'
import { getPlanKey } from '@/hooks/use-is-selected-plan'
import { appRoute } from '../routes/app-route'
import { LegSchema } from '@/services/otp/validations/planner.graphql'
import { ItineraryCard } from '../overlay/itineraries/itinerary-card'
import { Button } from '../ui/form/button'
import { Bug } from 'lucide-react'

interface DevtoolsOptions {
  /**
   * Set this true if you want the dev tools to default to being open
   */
  initialIsOpen?: boolean
  /**
   * Use this to add props to the panel. For example, you can add className, style (merge and override default style), etc.
   */
  panelProps?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >
  /**
   * Use this to add props to the close button. For example, you can add className, style (merge and override default style), onClick (extend default handler), etc.
   */
  closeButtonProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  /**
   * Use this to add props to the toggle button. For example, you can add className, style (merge and override default style), onClick (extend default handler), etc.
   */
  toggleButtonProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
  /**
   * The position of the logo to open and close the devtools panel.
   * Defaults to 'bottom-left'.
   */
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
  /**
   * Use this to render the devtools inside a different type of container element for a11y purposes.
   * Any string which corresponds to a valid intrinsic JSX element is allowed.
   * Defaults to 'footer'.
   */
  containerElement?: string | any
  /**
   * Use this to attach the devtool's styles to specific element in the DOM.
   */
  shadowDOMTarget?: ShadowRoot
}

interface DevtoolsPanelOptions {
  /**
   * The standard React style object used to style a component with inline styles
   */
  style?: React.CSSProperties
  /**
   * The standard React className property used to style a component with classes
   */
  className?: string
  /**
   * A boolean variable indicating whether the panel is open or closed
   */
  isOpen?: boolean
  /**
   * A function that toggles the open and close state of the panel
   */
  setIsOpen: (isOpen: boolean) => void
  /**
   * Handles the opening and closing the devtools panel
   */
  handleDragStart?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /**
   * Use this to attach the devtool's styles to specific element in the DOM.
   */
  shadowDOMTarget?: ShadowRoot
}

function Logo(props: React.HTMLAttributes<HTMLButtonElement>) {
  const { className, ...rest } = props
  const styles = useStyles()
  return (
    <button {...rest} className={cx(styles.logo, className)}>
      <div className={styles.mdotLogo}>MDOT GTFS</div>
      <div className={styles.itinerariesLogo}>Itineraries</div>
    </button>
  )
}

export function ItinerariesDevtools(
  props: DevtoolsOptions,
): React.ReactElement | null {
  const { shadowDOMTarget } = props

  return (
    <ShadowDomTargetContext.Provider value={shadowDOMTarget}>
      <FloatingMdotDevtools {...props} />
    </ShadowDomTargetContext.Provider>
  )
}

function FloatingMdotDevtools({
  initialIsOpen,
  panelProps = {},
  closeButtonProps = {},
  toggleButtonProps = {},
  position = 'bottom-left',
  containerElement: Container = 'footer',
  shadowDOMTarget,
}: DevtoolsOptions): React.ReactElement | null {
  const [rootEl, setRootEl] = React.useState<HTMLDivElement>()
  const panelRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useLocalStorage(
    'mdotDevtoolsOpen',
    initialIsOpen,
  )
  const [devtoolsHeight, setDevtoolsHeight] = useLocalStorage<number | null>(
    'mdotDevtoolsHeight',
    null,
  )
  const [isResolvedOpen, setIsResolvedOpen] = useSafeState(false)
  const [isResizing, setIsResizing] = useSafeState(false)
  const isMounted = useIsMounted()
  const styles = useStyles()

  const handleDragStart = (
    panelElement: HTMLDivElement | null,
    startEvent: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (startEvent.button !== 0) return // Only allow left click for drag

    setIsResizing(true)

    const dragInfo = {
      originalHeight: panelElement?.getBoundingClientRect().height ?? 0,
      pageY: startEvent.pageY,
    }

    const run = (moveEvent: MouseEvent) => {
      const delta = dragInfo.pageY - moveEvent.pageY
      const newHeight = dragInfo.originalHeight + delta

      setDevtoolsHeight(newHeight)

      if (newHeight < 70) {
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    }

    const unsub = () => {
      setIsResizing(false)
      document.removeEventListener('mousemove', run)
      document.removeEventListener('mouseUp', unsub)
    }

    document.addEventListener('mousemove', run)
    document.addEventListener('mouseup', unsub)
  }

  const isButtonClosed = isOpen ?? false

  React.useEffect(() => {
    setIsResolvedOpen(isOpen ?? false)
  }, [isOpen, isResolvedOpen, setIsResolvedOpen])

  React.useEffect(() => {
    if (isResolvedOpen) {
      const previousValue = rootEl?.parentElement?.style.paddingBottom

      const run = () => {
        const containerHeight = panelRef.current?.getBoundingClientRect().height
        if (rootEl?.parentElement) {
          rootEl.parentElement.style.paddingBottom = `${containerHeight}px`
        }
      }

      run()

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', run)

        return () => {
          window.removeEventListener('resize', run)
          if (rootEl?.parentElement && typeof previousValue === 'string') {
            rootEl.parentElement.style.paddingBottom = previousValue
          }
        }
      }
    }
    return
  }, [isResolvedOpen, rootEl?.parentElement])

  React.useEffect(() => {
    if (rootEl) {
      const el = rootEl
      const fontSize = getComputedStyle(el).fontSize
      el.style.setProperty('--tsrd-font-size', fontSize)
    }
  }, [rootEl])

  const { style: panelStyle = {}, ...otherPanelProps } = panelProps

  const {
    // style: closeButtonStyle = {},
    onClick: onCloseClick,
    // ...otherCloseButtonProps
  } = closeButtonProps

  const {
    onClick: onToggleClick,
    className: toggleButtonClassName,
    ...otherToggleButtonProps
  } = toggleButtonProps

  // Do not render on the server
  if (!isMounted()) return null

  const resolvedHeight = devtoolsHeight ?? 500

  return (
    <Container ref={setRootEl} className="MdotRouterDevtools">
      <DevtoolsOnCloseContext.Provider
        value={{
          onCloseClick: onCloseClick ?? (() => { }),
        }}
      >
        <BaseMdotDevtoolsPanel
          ref={panelRef as any}
          {...otherPanelProps}
          className={cx(
            styles.devtoolsPanelContainer,
            styles.devtoolsPanelContainerVisibility(!!isOpen),
            styles.devtoolsPanelContainerResizing(isResizing),
            styles.devtoolsPanelContainerAnimation(
              isResolvedOpen,
              resolvedHeight + 16,
            ),
          )}
          style={{
            height: resolvedHeight,
            ...panelStyle,
          }}
          isOpen={isResolvedOpen}
          setIsOpen={setIsOpen}
          handleDragStart={(e) => handleDragStart(panelRef.current, e)}
          shadowDOMTarget={shadowDOMTarget}
        />
      </DevtoolsOnCloseContext.Provider>

      <Button
        type="button"
        {...otherToggleButtonProps}
        aria-label="MDOT GTFS Project DevTools"
        onClick={(e) => {
          setIsOpen(true)
          onToggleClick && onToggleClick(e)
        }}
        className={cx(
          styles.mainCloseBtn,
          styles.mainCloseBtnPosition(position),
          styles.mainCloseBtnAnimation(!isButtonClosed),
          toggleButtonClassName,
        )}
      >
        <Bug className='w-4 h-4 text-muted-inverted-foreground' />
        {/* <div className={styles.mainCloseBtnDivider}>-</div>
        <div className={styles.routerLogoCloseButton}>Devtools</div> */}
      </Button>
    </Container>
  )
}

export const MdotDevtoolsPanel = React.forwardRef<
  HTMLDivElement,
  DevtoolsPanelOptions
>(function MdotDevtoolsPanel(props, ref) {
  const { shadowDOMTarget } = props

  return (
    <ShadowDomTargetContext.Provider value={shadowDOMTarget}>
      <DevtoolsOnCloseContext.Provider
        value={{
          onCloseClick: () => { },
        }}
      >
        <BaseMdotDevtoolsPanel ref={ref} {...props} />
      </DevtoolsOnCloseContext.Provider>
    </ShadowDomTargetContext.Provider>
  )
})

const BaseMdotDevtoolsPanel = React.forwardRef<
  HTMLDivElement,
  DevtoolsPanelOptions
>(function BaseMdotDevtoolsPanel(props, ref): React.ReactElement {
  const {
    isOpen = true,
    setIsOpen,
    handleDragStart,
    shadowDOMTarget,
    ...panelProps
  } = props

  const { onCloseClick } = useDevtoolsOnClose()
  const styles = useStyles()
  const { className, ...otherPanelProps } = panelProps

  const [activeId, setActiveId] = useLocalStorage(
    'MdotDevtoolsActiveRouteId',
    '',
  )

  const planner = usePlanner(0);
  const data = appRoute.useSearch({ select: (search) => search.data });
  const selected = useMemo(() => {
    if (!planner.data?.plan?.itineraries || !data?.selected) {
      return;
    }

    const match = planner.data.plan.itineraries.find(i => data.selected === getPlanKey(i));
    return match;
  }, [planner, data])



  const activeMatch = React.useMemo(() => {
    const matches = [
      ...(selected?.legs ?? []),
    ]
    return matches.find((m) => getLegId(m) === activeId || getLegId(m) === activeId)
  }, [activeId, selected?.legs])

  return (
    <div
      ref={ref}
      className={cx(
        styles.devtoolsPanel,
        'MdotDevtoolsPanel',
        className,
      )}
      {...otherPanelProps}
    >
      {handleDragStart ? (
        <div className={styles.dragHandle} onMouseDown={handleDragStart}></div>
      ) : null}
      <button
        className={styles.panelCloseBtn}
        onClick={(e) => {
          setIsOpen(false)
          onCloseClick(e)
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="6"
          fill="none"
          viewBox="0 0 10 6"
          className={styles.panelCloseBtnIcon}
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
            d="M1 1l4 4 4-4"
          ></path>
        </svg>
      </button>
      <div className={styles.firstContainer}>
        <div className={styles.row}>
          <Logo
            aria-hidden
            onClick={(e) => {
              setIsOpen(false)
              onCloseClick(e)
            }}
          />
        </div>

        <div className={styles.routerExplorerContainer}>
          <div className={styles.routerExplorer}>
            <Explorer
              label="Planner"
              value={planner}
              defaultExpanded={{
                state: {} as any,
                context: {} as any,
                options: {} as any,
              }}
              filterSubEntries={(subEntries: any) => {
                return subEntries.filter((d: any) => typeof d.value !== 'function')
              }}
            />
          </div>
        </div>
      </div>

      {selected &&
        <div className={styles.secondContainer}>
          <div className={styles.matchesContainer}>
            <div className={styles.detailsHeader}>
              <span>Selected itinerary</span>
            </div>
            <div className={styles.detailsContent}>
              <code className={styles.maskedLocation}>
                {/* <code> */}
                <Explorer
                  label="Plan"
                  value={selected}
                />
              </code>
            </div>
            <div className={styles.detailsHeader}>
              <div className={styles.routeMatchesToggle}>
                Legs
              </div>
              <div className={styles.detailsHeaderInfo}>
                <div>agency / route</div>
              </div>
            </div>
            <div className={cx(styles.routesContainer)}>
              <div>
                {(selected.legs)?.map(
                  (match, i) => {
                    const id = getLegId(match, i)
                    return (
                      <div
                        key={id}
                        role="button"
                        aria-label={`Open match details for ${id}`}
                        onClick={() =>
                          setActiveId(activeId === id ? '' : id)
                        }
                        className={cx(styles.matchRow(match === activeMatch))}
                      >
                        <div
                          className={cx(
                            styles.matchIndicator(getStatusColor(match)),
                          )}
                        />

                        <code
                          className={styles.matchID}
                        >{`${match.agency?.gtfsId ? `${match.agency?.gtfsId} / ${match.route?.longName}` : `${match.mode}`}`}</code>
                      </div>
                    )
                  },
                )}
              </div>
            </div>
          </div>
        </div>
      }

      {activeMatch ? (
        <div className={styles.thirdContainer}>
          <div className={styles.detailsHeader}>Match Details</div>
          <div>
            <div className={styles.matchDetails}>
              <div
                className={styles.matchStatus(
                  "success",
                  false
                )}
              >
                <div>
                  success
                </div>
              </div>
              <div className={styles.matchDetailsInfoLabel}>
                <div>ID:</div>
                <div className={styles.matchDetailsInfo}>
                  <code>{getLegId(activeMatch)}</code>
                </div>
              </div>
              <div className={styles.matchDetailsInfoLabel}>
                <div>State:</div>
                <div className={styles.matchDetailsInfo}>
                
                </div>
              </div>
              <div className={styles.matchDetailsInfoLabel}>
                <div>Last Updated:</div>
                <div className={styles.matchDetailsInfo}>
                  {activeMatch.serviceDate
                    ? new Date(activeMatch.serviceDate).toLocaleTimeString()
                    : 'N/A'}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.detailsHeader}>Explorer</div>
          <div className={styles.detailsContent}>
            <Explorer label="Match" value={activeMatch} defaultExpanded={{}} />
          </div>
        </div>
      ) : null}

      {planner.data?.extended ? (
        <div className={styles.fourthContainer}>
          <div className={styles.detailsHeader}>Extended itineraires</div>
          <div className={styles.detailsContent}>

            <div className="flex flex-col gap-3 p-8">
              {planner.data.extended.itineraries?.valid && planner.data.extended.itineraries.valid.length > 0 && (
                <>
                  <span>Valid</span>
                  {planner.data.extended.itineraries?.valid.map((f) => {
                    return (
                      <ItineraryCard key={getPlanKey(f)} {...f} />
                    )
                  })}
                </>
              )}

              {planner.data.extended.itineraries?.filtered && planner.data.extended.itineraries.filtered.length > 0 && (
                <>
                  <span>Filtered</span>
                  {planner.data.extended.itineraries?.filtered.map((f) => {
                    return (
                      <ItineraryCard key={getPlanKey(f)} {...f} />
                    )
                  })}
                </>
              )}

              {planner.data.extended.itineraries?.future && planner.data.extended.itineraries.future.length > 0 && (
                <>
                  <span>Future</span>
                  {planner.data.extended.itineraries?.future.map((f) => {
                    return (
                      <ItineraryCard key={getPlanKey(f)} {...f} />
                    )
                  })}
                </>
              )}
            </div>

          </div>
        </div>
      ) : null}
    </div>
  )
})

const stylesFactory = (shadowDOMTarget?: ShadowRoot) => {
  const { colors, font, size, alpha, border } = tokens
  const { fontFamily, lineHeight, size: fontSize } = font
  const css = shadowDOMTarget
    ? goober.css.bind({ target: shadowDOMTarget })
    : goober.css

  return {
    devtoolsPanelContainer: css`
      direction: ltr;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 99999;
      width: 100%;
      max-height: 90%;
      border-top: 1px solid ${colors.gray[700]};
      transform-origin: top;
    `,
    devtoolsPanelContainerVisibility: (isOpen: boolean) => {
      return css`
        visibility: ${isOpen ? 'visible' : 'hidden'};
      `
    },
    devtoolsPanelContainerResizing: (isResizing: boolean) => {
      if (isResizing) {
        return css`
          transition: none;
        `
      }

      return css`
        transition: all 0.4s ease;
      `
    },
    devtoolsPanelContainerAnimation: (isOpen: boolean, height: number) => {
      if (isOpen) {
        return css`
          pointer-events: auto;
          transform: translateY(0);
        `
      }
      return css`
        pointer-events: none;
        transform: translateY(${height}px);
      `
    },
    logo: css`
      cursor: pointer;
      display: flex;
      flex-direction: column;
      background-color: transparent;
      border: none;
      font-family: ${fontFamily.sans};
      gap: ${tokens.size[0.5]};
      padding: 0px;
      &:hover {
        opacity: 0.7;
      }
      &:focus-visible {
        outline-offset: 4px;
        border-radius: ${border.radius.xs};
        outline: 2px solid ${colors.blue[800]};
      }
    `,
    mdotLogo: css`
      font-size: ${font.size.md};
      font-weight: ${font.weight.bold};
      line-height: ${font.lineHeight.xs};
      white-space: nowrap;
      color: ${colors.gray[300]};
    `,
    itinerariesLogo: css`
      font-weight: ${font.weight.semibold};
      font-size: ${font.size.xs};
      background: linear-gradient(to right, #84cc16, #10b981);
      background-clip: text;
      -webkit-background-clip: text;
      line-height: 1;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    `,
    devtoolsPanel: css`
      display: flex;
      font-size: ${fontSize.sm};
      font-family: ${fontFamily.sans};
      background-color: ${colors.darkGray[700]};
      color: ${colors.gray[300]};

      @media (max-width: 700px) {
        flex-direction: column;
      }
      @media (max-width: 600px) {
        font-size: ${fontSize.xs};
      }
    `,
    dragHandle: css`
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 4px;
      cursor: row-resize;
      z-index: 100000;
      &:hover {
        background-color: ${colors.purple[400]}${alpha[90]};
      }
    `,
    firstContainer: css`
      flex: 1 1 500px;
      min-height: 40%;
      max-height: 100%;
      overflow: auto;
      border-right: 1px solid ${colors.gray[700]};
      display: flex;
      flex-direction: column;
    `,
    routerExplorerContainer: css`
      overflow-y: auto;
      flex: 1;
    `,
    routerExplorer: css`
      padding: ${tokens.size[2]};
    `,
    row: css`
      display: flex;
      align-items: center;
      padding: ${tokens.size[2]} ${tokens.size[2.5]};
      gap: ${tokens.size[2.5]};
      border-bottom: ${colors.darkGray[500]} 1px solid;
      align-items: center;
    `,
    detailsHeader: css`
      font-family: ui-sans-serif, Inter, system-ui, sans-serif, sans-serif;
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: ${colors.darkGray[600]};
      padding: 0px ${tokens.size[2]};
      font-weight: ${font.weight.medium};
      font-size: ${font.size.xs};
      min-height: ${tokens.size[8]};
      line-height: ${font.lineHeight.xs};
      text-align: left;
      display: flex;
      align-items: center;
    `,
    maskedBadge: css`
      background: ${colors.yellow[900]}${alpha[70]};
      color: ${colors.yellow[300]};
      display: inline-block;
      padding: ${tokens.size[0]} ${tokens.size[2.5]};
      border-radius: ${border.radius.full};
      font-size: ${font.size.xs};
      font-weight: ${font.weight.normal};
      border: 1px solid ${colors.yellow[300]};
    `,
    maskedLocation: css`
      color: ${colors.yellow[300]};
    `,
    detailsContent: css`
      padding: ${tokens.size[1.5]} ${tokens.size[2]};
      display: flex;
      align-items: center;
      font-size: ${font.size.xs};
    `,
    routeMatchesToggle: css`
      display: flex;
      align-items: center;
      border: 1px solid ${colors.gray[500]};
      border-radius: ${border.radius.sm};
      overflow: hidden;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      padding-top: 0.05rem;
      padding-bottom: 0.05rem;
    `,
    routeMatchesToggleBtn: (active: boolean, showBorder: boolean) => {
      const base = css`
        appearance: none;
        border: none;
        font-size: 12px;
        padding: 4px 8px;
        background: transparent;
        cursor: pointer;
        font-family: ${fontFamily.sans};
        font-weight: ${font.weight.medium};
      `
      const classes = [base]

      if (active) {
        const activeStyles = css`
          background: ${colors.darkGray[400]};
          color: ${colors.gray[300]};
        `
        classes.push(activeStyles)
      } else {
        const inactiveStyles = css`
          color: ${colors.gray[500]};
          background: ${colors.darkGray[800]}${alpha[20]};
        `
        classes.push(inactiveStyles)
      }

      if (showBorder) {
        classes.push(css`
          border-right: 1px solid ${tokens.colors.gray[500]};
        `)
      }

      return classes
    },
    detailsHeaderInfo: css`
      flex: 1;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      font-weight: ${font.weight.normal};
      color: ${colors.gray[400]};
    `,
    matchRow: (active: boolean) => {
      const base = css`
        display: flex;
        border-bottom: 1px solid ${colors.darkGray[400]};
        cursor: pointer;
        align-items: center;
        padding: ${size[1]} ${size[2]};
        gap: ${size[2]};
        font-size: ${fontSize.xs};
        color: ${colors.gray[300]};
      `
      const classes = [base]

      if (active) {
        const activeStyles = css`
          background: ${colors.darkGray[500]};
        `
        classes.push(activeStyles)
      }

      return classes
    },
    matchIndicator: (color: 'green' | 'red' | 'yellow' | 'gray' | 'blue') => {
      const base = css`
        flex: 0 0 auto;
        width: ${size[3]};
        height: ${size[3]};
        background: ${colors[color][900]};
        border: 1px solid ${colors[color][500]};
        border-radius: ${border.radius.full};
        transition: all 0.25s ease-out;
        box-sizing: border-box;
      `
      const classes = [base]

      if (color === 'gray') {
        const grayStyles = css`
          background: ${colors.gray[700]};
          border-color: ${colors.gray[400]};
        `
        classes.push(grayStyles)
      }

      return classes
    },
    matchID: css`
      flex: 1;
      line-height: ${lineHeight['xs']};
    `,
    ageTicker: (showWarning: boolean) => {
      const base = css`
        display: flex;
        gap: ${size[1]};
        font-size: ${fontSize.xs};
        color: ${colors.gray[400]};
        font-variant-numeric: tabular-nums;
        line-height: ${lineHeight['xs']};
      `

      const classes = [base]

      if (showWarning) {
        const warningStyles = css`
          color: ${colors.yellow[400]};
        `
        classes.push(warningStyles)
      }

      return classes
    },
    secondContainer: css`
      flex: 1 1 500px;
      min-height: 40%;
      max-height: 100%;
      overflow: auto;
      border-right: 1px solid ${colors.gray[700]};
      display: flex;
      flex-direction: column;
    `,
    thirdContainer: css`
      flex: 1 1 500px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      height: 100%;
      border-right: 1px solid ${colors.gray[700]};

      @media (max-width: 700px) {
        border-top: 2px solid ${colors.gray[700]};
      }
    `,
    fourthContainer: css`
      flex: 1 1 500px;
      min-height: 40%;
      max-height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
    `,
    routesContainer: css`
      overflow-x: auto;
      overflow-y: visible;
    `,
    routesRowContainer: (active: boolean, isMatch: boolean) => {
      const base = css`
        display: flex;
        border-bottom: 1px solid ${colors.darkGray[400]};
        align-items: center;
        padding: ${size[1]} ${size[2]};
        gap: ${size[2]};
        font-size: ${fontSize.xs};
        color: ${colors.gray[300]};
        cursor: ${isMatch ? 'pointer' : 'default'};
        line-height: ${lineHeight['xs']};
      `
      const classes = [base]

      if (active) {
        const activeStyles = css`
          background: ${colors.darkGray[500]};
        `
        classes.push(activeStyles)
      }

      return classes
    },
    routesRow: (isMatch: boolean) => {
      const base = css`
        flex: 1 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: ${fontSize.xs};
        line-height: ${lineHeight['xs']};
      `

      const classes = [base]

      if (!isMatch) {
        const matchStyles = css`
          color: ${colors.gray[400]};
        `
        classes.push(matchStyles)
      }

      return classes
    },
    routeParamInfo: css`
      color: ${colors.gray[400]};
      font-size: ${fontSize.xs};
      line-height: ${lineHeight['xs']};
    `,
    nestedRouteRow: (isRoot: boolean) => {
      const base = css`
        margin-left: ${isRoot ? 0 : size[3.5]};
        border-left: ${isRoot ? '' : `solid 1px ${colors.gray[700]}`};
      `
      return base
    },
    code: css`
      font-size: ${fontSize.xs};
      line-height: ${lineHeight['xs']};
    `,
    matchesContainer: css`
      flex: 1 1 auto;
      overflow-y: auto;
    `,
    cachedMatchesContainer: css`
      flex: 1 1 auto;
      overflow-y: auto;
      max-height: 50%;
    `,
    maskedBadgeContainer: css`
      flex: 1;
      justify-content: flex-end;
      display: flex;
    `,
    matchDetails: css`
      display: flex;
      flex-direction: column;
      padding: ${tokens.size[2]};
      font-size: ${tokens.font.size.xs};
      color: ${tokens.colors.gray[300]};
      line-height: ${tokens.font.lineHeight.sm};
    `,
    matchStatus: (
      status: 'pending' | 'success' | 'error' | 'notFound' | 'redirected',
      isFetching: false | 'beforeLoad' | 'loader',
    ) => {
      const colorMap = {
        pending: 'yellow',
        success: 'green',
        error: 'red',
        notFound: 'purple',
        redirected: 'gray',
      } as const

      const color =
        isFetching && status === 'success'
          ? isFetching === 'beforeLoad'
            ? 'purple'
            : 'blue'
          : colorMap[status]

      return css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        border-radius: ${tokens.border.radius.sm};
        font-weight: ${tokens.font.weight.normal};
        background-color: ${tokens.colors[color][900]}${tokens.alpha[90]};
        color: ${tokens.colors[color][300]};
        border: 1px solid ${tokens.colors[color][600]};
        margin-bottom: ${tokens.size[2]};
        transition: all 0.25s ease-out;
      `
    },
    matchDetailsInfo: css`
      display: flex;
      justify-content: flex-end;
      flex: 1;
    `,
    matchDetailsInfoLabel: css`
      display: flex;
    `,
    mainCloseBtn: css`
      background: ${colors.darkGray[700]};
      padding: ${size[1]} ${size[3]} ${size[1]} ${size[3]};
      border-radius: ${border.radius.md};
      position: fixed;
      z-index: 99999;
      display: inline-flex;
      width: fit-content;
      cursor: pointer;
      appearance: none;
      border: 0;
      gap: 8px;
      align-items: center;
      border: 1px solid ${colors.gray[500]};
      font-size: ${font.size.xs};
      cursor: pointer;
      transition: all 0.25s ease-out;

      &:hover {
        background: ${colors.darkGray[500]};
      }
    `,
    mainCloseBtnPosition: (
      position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right',
    ) => {
      const base = css`
        ${position === 'top-left' ? `top: ${size[2]}; left: ${size[2]};` : ''}
        ${position === 'top-right' ? `top: ${size[2]}; right: ${size[2]};` : ''}
        ${position === 'bottom-left'
          ? `bottom: ${size[2]}; left: ${size[2]};`
          : ''}
        ${position === 'bottom-right'
          ? `bottom: ${size[2]}; right: ${size[2]};`
          : ''}
      `
      return base
    },
    mainCloseBtnAnimation: (isOpen: boolean) => {
      if (isOpen) {
        return css`
          opacity: 1;
          pointer-events: auto;
          visibility: visible;
        `
      }
      return css`
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
      `
    },
    routerLogoCloseButton: css`
      font-weight: ${font.weight.semibold};
      font-size: ${font.size.xs};
      background: linear-gradient(to right, #98f30c, #00f4a3);
      background-clip: text;
      -webkit-background-clip: text;
      line-height: 1;
      -webkit-text-fill-color: transparent;
      white-space: nowrap;
    `,
    mainCloseBtnDivider: css`
      width: 1px;
      background: ${tokens.colors.gray[600]};
      height: 100%;
      border-radius: 999999px;
      color: transparent;
    `,
    mainCloseBtnIconContainer: css`
      position: relative;
      width: ${size[5]};
      height: ${size[5]};
      background: pink;
      border-radius: 999999px;
      overflow: hidden;
    `,
    mainCloseBtnIconOuter: css`
      width: ${size[5]};
      height: ${size[5]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      filter: blur(3px) saturate(1.8) contrast(2);
    `,
    mainCloseBtnIconInner: css`
      width: ${size[4]};
      height: ${size[4]};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    panelCloseBtn: css`
      position: absolute;
      cursor: pointer;
      z-index: 100001;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      background-color: ${colors.darkGray[700]};
      &:hover {
        background-color: ${colors.darkGray[500]};
      }

      top: 0;
      right: ${size[2]};
      transform: translate(0, -100%);
      border-right: ${colors.darkGray[300]} 1px solid;
      border-left: ${colors.darkGray[300]} 1px solid;
      border-top: ${colors.darkGray[300]} 1px solid;
      border-bottom: none;
      border-radius: ${border.radius.sm} ${border.radius.sm} 0px 0px;
      padding: ${size[1]} ${size[1.5]} ${size[0.5]} ${size[1.5]};

      &::after {
        content: ' ';
        position: absolute;
        top: 100%;
        left: -${size[2.5]};
        height: ${size[1.5]};
        width: calc(100% + ${size[5]});
      }
    `,
    panelCloseBtnIcon: css`
      color: ${colors.gray[400]};
      width: ${size[2]};
      height: ${size[2]};
    `,
  }
}

let _styles: ReturnType<typeof stylesFactory> | null = null

function useStyles() {
  const shadowDomTarget = React.useContext(ShadowDomTargetContext)
  if (_styles) return _styles
  _styles = stylesFactory(shadowDomTarget)

  return _styles
}

function getLegId(leg?: LegSchema, index?: number) {
  if (!leg) {
    return `${index}`;
  }

  return `${leg.mode}-${leg.agency?.name}-${leg.route?.longName}-${new Date(leg.startTime ?? "")}`
}