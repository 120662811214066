import { createRoute } from "@tanstack/react-router"
import { rootRoute } from "./root-route"
import { HelpPage } from "../+help.page"
import { appSearchSchema } from "./app-route.zod"

export const helpRoute = createRoute({
    getParentRoute: () => rootRoute,
    path: '/help',
    component: HelpPage,
    validateSearch: appSearchSchema,
})

