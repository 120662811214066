import { appRoute } from "@/components/routes/app-route";
import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { Card, CardHeader, CardTitle } from "@/components/ui/containers/card";
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from "@/components/ui/containers/drawer";
import { Button } from "@/components/ui/form/button";
import { Icons } from "@/components/ui/icons";
import { useMediaQuery } from "@/hooks/use-media-query";
import { cn } from "@/lib/utils";
import { ComponentClassName } from "@/types/utility"
import { ItinerariesContent } from "./itineraries.content";
import { InjectMapProps } from "@/types/leaflet";

type GenerateItineraryCardProps = ComponentClassName<InjectMapProps>;

export function Itineraries({ className, map }: GenerateItineraryCardProps) {
  const plan = appRoute.useSearch({ select: (search) => search.data })
  if (!plan?.open) {
    return <></>;
  }

  return (
    <ItinerariesInternal map={map} className={className} />
  )
}

/**
 * Decides whether to return a basic card or a drawer for mobile views
 * @returns React Component
 */
function ItinerariesInternal({ className, map }: GenerateItineraryCardProps) {
  const isTablet = useMediaQuery("(max-width: 768px)");
  const { setPlanModel } = useSetAppSearch(map)
  const plan = appRoute.useSearch({ select: (search) => search.data })


  if (isTablet) {
    return (
      <Drawer open={!!plan && plan.open} onClose={() => setPlanModel()}>
        <DrawerContent
          className="px-2 md:px-8 gap-4 min-h-[97%]"
        >
          <DrawerHeader className="relative flex-row items-center p-0 md:p-4">
            <GoToPlanner className="absolute" />

            <DrawerTitle className="flex-1 sm:pl-12">
              Available routes
            </DrawerTitle>

            <DrawerDescription className="sr-only">
              Mobile itineraries content.
            </DrawerDescription>
          </DrawerHeader>

          <ItinerariesContent />
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Card
      className={cn(
        "h-full animate-in fade-in slide-in-from-bottom-8 md:slide-in-from-bottom-0 md:slide-in-from-left-12 duration-300 md:duration-300 flex flex-col pb-2 px-8 gap-3",
        className
      )}>
      <CardHeader className="px-0 pb-0">
        <CardTitle className="flex justify-between w-full">
          <GoToPlanner />

          Available routes

          <Button
            variant={"ghost"}
            size={"icon"}
            onClick={() => setPlanModel()}
          >
            <Icons.actions.close className="w-4 h-4" />
            <span className="sr-only">Close planner</span>
          </Button>
        </CardTitle>
      </CardHeader>

      <ItinerariesContent />
    </Card>
  )
}

export function GoToPlanner({
  className,
  children = <Icons.actions.back className="w-5 h-5 text-foreground" />,
}: ComponentClassName) {
  const { set } = useSetAppSearch();
  const data = appRoute.useSearch({ select: (s) => s.data })

  return (
    <Button
      variant={"ghost"}
      size={"icon"}
      className={cn(
        data?._t !== "p" ? "" : "hidden",
        className
      )}
      onClick={() => {
        set((prev) => {
          if (!prev.data || prev.data._t === "p") {
            return prev;
          }

          prev.data._t = "p"
          return prev;
        })
      }}
    >
      {children}
      <span className="sr-only">Close planner</span>
    </Button>
  )
}
