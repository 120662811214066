import { ReactElement, ReactNode } from "react";
import { MEDICAL_ROUTE_ID_KEY, TRANSER_TRIP_ID_KEY } from "@/config/otp";
import { LucideProps } from "lucide-react";
import { Icons } from "@/components/ui/icons";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/containers/alert";
import { AppItinerary } from "@/types/otp/+extended";

type CalloutProps = NonNullable<AppItinerary["legs"]>[number]

type IndicatorKey = "medical" | "same_day_scheduling" | "call_ahead_scheduling" | "estimated_times" | "multiple_transfers"
type IndicatorWithValue = IndicatorKey | {
    key: IndicatorKey,
    value: unknown
}

const INDICATORS_MAP = {
    medical: <ItineraryCardAlert key={"medical"}>{{
        icon: <Icons.itinerary.indicator.medical />,
        description: <>Must meet eligibility requirements.</>,
    }}</ItineraryCardAlert>,
    "same_day_scheduling": (value: unknown) => <ItineraryCardAlert key={"same_day"}>{{
        icon: <Icons.itinerary.indicator.scheduledDayOf />,
        description: <>Must call at least {value} minutes ahead of time.</>,
    }}</ItineraryCardAlert>,
    "call_ahead_scheduling": (value: unknown) => <ItineraryCardAlert key={"call_ahead"}>{{
        icon: <Icons.itinerary.indicator.callAhead />,
        description: <>Must call at least {value} days ahead of time.</>,
    }}</ItineraryCardAlert>,
    "estimated_times": <ItineraryCardAlert key={"estimated_times"}>{{
        icon: <Icons.itinerary.indicator.estimatedTimes />,
        description: <>Travel times are estimated and vary depending on vehicle availability.</>,
    }}</ItineraryCardAlert>,
    "multiple_transfers": <ItineraryCardAlert key={"multiple_transfers"}>{{
        icon: <Icons.itinerary.indicator.multipleTransfers />,
        description: <>Must coordinate with multiple agencies.</>,
    }}</ItineraryCardAlert>,

} as const satisfies Record<IndicatorKey, ReactNode | ((value: unknown) => ReactNode)>

export function ItineraryCardAlerts({ legs }:
    Pick<AppItinerary, "legs">
) {
    if (!legs || legs.length <= 0) {
        return;
    }

    const indicators: IndicatorWithValue[] = []

    for (const leg of legs) {
        tryAddMedical(leg, indicators);
        tryAddCallAhead(leg, indicators);
        tryAddSameDay(leg, indicators);
        tryAddMultipleTransfers(leg, indicators);
    }

    return (
        <Alert variant={"warning"}>
            <AlertTitle className="mb-1.5">Alerts</AlertTitle>

            <AlertDescription className="flex flex-col gap-0.5">
                {indicators.map((i) => {
                    const key = typeof i === "string" ? i : i.key;
                    const value = typeof i === "string" ? undefined : i.value;

                    const indicator = INDICATORS_MAP[key]
                    if (typeof indicator === "function") {
                        return indicator(value)
                    }

                    return indicator;
                })}

                {INDICATORS_MAP["estimated_times"]}
            </AlertDescription>
        </Alert>
    )
}

function ItineraryCardAlert({
    children,
}: {
    children: {
        icon: ReactElement<LucideProps>,
        description: ReactNode,
    }
}) {
    const { icon, description } = children

    return (
        <div className="flex flex-row gap-2 text-start">
            <span>
                {icon}
            </span>

            {description}
        </div>
    )
}

function tryAddMedical(leg: CalloutProps, indicators: IndicatorWithValue[]) {
    if (indicatorGuard("medical", leg, indicators)) {
        return;
    }

    if (!leg.route?.gtfsId?.includes(MEDICAL_ROUTE_ID_KEY)) {
        return;
    }

    indicators.push("medical")
}

function tryAddSameDay(leg: CalloutProps, indicators: IndicatorWithValue[]) {
    if (indicatorGuard("same_day_scheduling", leg, indicators)) {
        return;
    }

    if (!("extended" in leg)
        || !leg.extended.pickupInformation?.prior_notice_duration_min
    ) {
        return;
    }

    const potentialDuration = +leg.extended.pickupInformation.prior_notice_duration_min;
    if (potentialDuration <= 0) {
        return;
    }

    indicators.push({
        key: "same_day_scheduling",
        value: leg.extended.pickupInformation.prior_notice_duration_min
    })
}


function tryAddCallAhead(leg: CalloutProps, indicators: IndicatorWithValue[]) {
    if (indicatorGuard("call_ahead_scheduling", leg, indicators)) {
        return;
    }

    if (!("extended" in leg)
        || typeof leg.extended.pickupInformation?.prior_notice_start_day !== "number"
        || leg.extended.pickupInformation.prior_notice_start_day <= 0
    ) {
        return;
    }

    indicators.push({
        key: "call_ahead_scheduling",
        value: leg.extended.pickupInformation.prior_notice_start_day
    })
}

function tryAddMultipleTransfers(leg: CalloutProps, indicators: IndicatorWithValue[]) {
    if (indicatorGuard("multiple_transfers", leg, indicators)) {
        return;
    }

    if (!leg.trip?.gtfsId?.includes(TRANSER_TRIP_ID_KEY)) {
        return;
    }

    indicators.push("multiple_transfers")
}

function indicatorGuard(check: IndicatorKey, leg: CalloutProps, indicators: IndicatorWithValue[]) {
    if (!leg) {
        return true;
    }

    const potentialMap = indicators.find((i) => typeof i === "string" ? i === check : i.key === check)
    if (potentialMap) {
        return true;
    }

    return false;
}
