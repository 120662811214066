import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { Button } from "@/components/ui/form/button";
import { Icons } from "@/components/ui/icons";
import { ComponentClassName } from "@/types/utility";
import { CardMessageWrapper } from "../../states/itineraries-state.contianer";

export function ItineraryDetailsEmpty({ className }: ComponentClassName) {
    const { set } = useSetAppSearch();

    return (
        <CardMessageWrapper
            icon={<Icons.plan.noRoute />}
            className={className}
        >
                No route selected. Select a route in the planner to discover more information here.
                <Button
                    variant={"default"}
                    className="text-inverted-foreground"
                    onClick={() => set((prev) => {
                        if (!prev.data || prev.data._t === "p") {
                            return prev;
                        }

                        prev.data._t = "p"
                        return prev;
                    })}
                >
                    Go to plannner
                </Button>
        </CardMessageWrapper>
    )
}
