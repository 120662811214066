import { StopSchema } from "@/services/otp/validations/planner.graphql";

export function ItineraryDetailsStop({ name, gtfsId }: StopSchema) {
    return (
        <div className="flex flex-row gap-1 items-center text-sm text-foreground">
            <span>
                {name ?? gtfsId ?? "Unable to find stop name"}
            </span>
        </div>
    )
}
