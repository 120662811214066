import { z } from "zod"

export const latLngTupleSchema = z.tuple([z.number(), z.number()])

const place = z.object({
  latlng: latLngTupleSchema,
  key: z.string()
})

const plannerOptionsNow = z.literal("now")
/**
 * Flag for selecting OTP mode when planning routes.
 * - `d`: Depart at
 * - `a`: Arrive by
 */
const plannerOptionsMode = z.union([z.literal('d'), z.literal('a')])

const plannerOptions = z.object({
  mode: plannerOptionsMode,
  time: z.string(),
})

/**
 * Flag to decide transport type filter
 * 
 * - `m`: Medical, veteran, etc.
 * - `n`: No Restrictions
 */
const transportType = z.union([z.literal("n"), z.literal("m")])

const planner = z.object({
  /**
   * Flag to decide the current state of the planner card.
   * 
   * - `p`: Planner - Elements for setting up filters and options for the OTP query alongside displaying all available routes.
   * - `t`: Trip Details - Elements for showing the currently selected itinerary and all steps within that trip.
   */
  _t: z.union([z.literal("p"), z.literal("t")]),
  selected: z.string().optional(),
  open: z.boolean().optional(),
  opts: z.union([
    z.undefined(),
    plannerOptions,
    plannerOptionsNow
  ]).optional(),

  /**
   * Flag for selecting banned routes when planning routes.
   * - `n`: Allow all trips excluding medical
   * - `m`: Allow all trips including medical
   */
  transportType: transportType,
})

const data = z.union([
  z.undefined(),
  planner,
])

const filter = z.object({
  counties: z.boolean().optional()
})

export const appSearchSchema = z.object({
  from: place.optional(),
  to: place.optional(),
  /**
   * Sets generic Ui state. Used to control overlay content, selected
   * route information, filters, etc.
   */
  data: data.optional(),
  /**
   * Specialized string representation of map view information.
   * 
   * __*In the format of {lng},{lat},{zoom}*__
   */
  m: z.string().optional(),
  /**
   * Filter for hiding and showing map layers
   */
  f: filter.optional(),
})

export type AppSearch = z.infer<typeof appSearchSchema>
export type AppPlace = z.infer<typeof place>
export type AppPlanner = z.infer<typeof planner>
export type AppData = z.infer<typeof data>
export type AppTransportType = z.infer<typeof transportType>
export type PlannerOptionsMode = z.infer<typeof plannerOptionsMode>
