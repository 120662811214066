import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { MenuItem, MenuItemProps } from "@/components/ui/primitives/menu-item";
import { requestCurrentLocation } from "@/hooks/use-current-location";
import { tryGetPlace } from "@/lib/places";

export function SetCurrentLocationItem({ variant }: MenuItemProps) {
    const { setFrom } = useSetAppSearch()

    return (
        <MenuItem
            variant={variant}
            onSelect={() => {
                requestCurrentLocation(async (latlng) => {
                    const potentialPlace = await tryGetPlace(latlng);

                    setFrom(potentialPlace)
                })
            }}
        >
            Use Current Location
        </MenuItem>
    )
}