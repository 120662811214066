import { z } from "zod"

export const translatedStringSchema = z.object({
    text: z.string().optional().nullable(),
    language: z.string().optional().nullable(),
})

export const idSchema = z.number();

export const alertCauseTypeSchema = z.union([
    z.literal("UNKNOWN_CAUSE"),
    z.literal("OTHER_CAUSE"),
    z.literal("TECHNICAL_PROBLEM"),
    z.literal("STRIKE"),
    z.literal("DEMONSTRATION"),
    z.literal("ACCIDENT"),
    z.literal("HOLIDAY"),
    z.literal("WEATHER"),
    z.literal("MAINTENANCE"),
    z.literal("export constRUCTION"),
    z.literal("POLICE_ACTIVITY"),
    z.literal("MEDICAL_EMERGENCY"),
])

export const alertSeverityLevelTypeSchema = z.union([
    z.literal("UNKNOWN_SEVERITY"),
    z.literal("INFO"),
    z.literal("WARNING"),
    z.literal("SEVERE"),
])


export const alertEffectTypeSchema = z.union([
    z.literal("NO_SERVICE"),
    z.literal("REDUCED_SERVICE"),
    z.literal("SIGNIFICANT_DELAYS"),
    z.literal("DETOUR"),
    z.literal("ADDITIONAL_SERVICE"),
    z.literal("MODIFIED_SERVICE"),
    z.literal("OTHER_EFFECT"),
    z.literal("UNKNOWN_EFFECT"),
    z.literal("STOP_MOVED"),
    z.literal("NO_EFFECT"),
    z.literal("ACCESSIBILITY_ISSUE"),
])


export const alertEntity = z.union([
    z.unknown(),
    z.unknown(),
])

export const alertSchema = z.object({
    id: idSchema.optional(),
    alertHash: z.number().optional().nullable(),
    feed: z.string().optional().nullable(),
    alertHeaderText: z.string().optional().nullable(),
    alertHeaderTextTranslations: z.array(translatedStringSchema).optional().nullable(),
    alertDescriptionText: z.string().optional().nullable(),
    alertDescriptionTextTranslations: z.array(translatedStringSchema).optional().nullable(),
    alertUrl: z.string().url().optional().nullable(),
    alertUrlTranslations: z.array(translatedStringSchema).optional().nullable(),
    alertEffect: alertEffectTypeSchema.optional().nullable(),
    alertCause: alertCauseTypeSchema.optional().nullable(),
    alertSeverityLevel: alertSeverityLevelTypeSchema.optional().nullable(),
    effectiveStartDate: z.number().optional().nullable(),
    effectiveEndDate: z.number().optional().nullable(),
    entities: z.array(alertEntity).optional().nullable(),
})


const inputFieldSchema = z.union([
    z.literal("DATE_TIME"), 
    z.literal("FROM"),
    z.literal("TO"),
])

const routingErrorCodeSchema = z.union([
    z.literal("NO_TRANSIT_CONNECTION"),
    z.literal("NO_TRANSIT_CONNECTION_IN_SEARCH_WINDOW"),
    z.literal("OUTSIDE_SERVICE_PERIOD"),
    z.literal("OUTSIDE_BOUNDS"),
    z.literal("LOCATION_NOT_FOUND"),
    z.literal("NO_STOPS_IN_RANGE"),
    z.literal("WALKING_BETTER_THAN_TRANSIT"),
])

export const routingErrorSchema = z.object({
    code: routingErrorCodeSchema.optional(),
    inputField: inputFieldSchema.optional().nullable(),
    description: z.string().optional().nullable(),
})

export const debugOutputSchema = z.object({
    totalTime: z.number().optional().nullable(),
    pathCalculationTime: z.number().optional().nullable(),
    precalculationTime: z.number().optional().nullable(),
    renderingTime: z.number().optional().nullable(),
    timedOut: z.boolean().optional().nullable(),
})

export const systemNoticeSchema = z.object({
    tag: z.string().optional().nullable(),
    text: z.string().optional().nullable(),
})