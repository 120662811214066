import { usePlanner } from "@/hooks/use-planner";
import { ItineraryCardError } from "../states/itinerary.error";
import { ItineraryCardEmpty } from "../states/itinerary.empty";
import { ItineraryCardNoRoutes } from "../states/itinerary.no-routes";
import { ItineraryListContent } from "./itinerary-list.content";
import { ItineraryCardLoading } from "../states/itinerary.loading";

export function ItineraryList() {
    const { data, isLoading, error, hasValidQuery } = usePlanner()

    if (error) {
        return <ItineraryCardError error={error} />
    }

    if (isLoading) {
        return <ItineraryCardLoading />
    }

    if (!hasValidQuery) {
        return <ItineraryCardEmpty />
    }

    if ((!data?.extended.itineraries?.valid || data.extended.itineraries.valid.length <= 0)
    ) {
        return <ItineraryCardNoRoutes potentialFutureItineraries={data?.extended.itineraries?.future} />;
    }

    return (
        <div className="mr-3 pb-4">
            <ItineraryListContent itineraries={data.extended.itineraries?.valid} />

            {/** As per future request, this code has been hidden */}
            {/** {data.extended.itineraries?.future && data.extended.itineraries.future.length > 0 &&
                <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="item-1">
                        <AccordionTrigger>Future plans...</AccordionTrigger>
                        <AccordionContent>
                            <PlanSelectList itineraries={data.extended.itineraries.future} />
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            } */}
        </div>
    )

}
