import { IconOptions } from "leaflet";

export const MAP_ASSETS = {
    marker: {
        start: {
            iconUrl: "https://www.google.com/maps/vt/icon/name=assets/icons/directions/directions_measle-2-medium.png&scale=1",
            iconSize: [16, 16],
            popupAnchor: [1, -16],
        } satisfies IconOptions,
        end: {
            iconUrl: "https://www.google.com/maps/vt/icon/name=assets/icons/spotlight/spotlight_pin_v4_outline-2-medium.png,assets/icons/spotlight/spotlight_pin_v4-2-medium.png,assets/icons/spotlight/spotlight_pin_v4_dot-2-medium.png&highlight=c5221f,ea4335,b31412?scale=1",
            iconSize: [28, 39],
            iconAnchor: [14, 39],
            shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
            shadowSize: [41, 41],
            popupAnchor: [1, -36],
        } satisfies IconOptions,
        stop: {
            iconUrl: "https://www.google.com/maps/vt/icon/name=assets/icons/spotlight/spotlight_pin_v4_outline-2-medium.png,assets/icons/spotlight/spotlight_pin_v4-2-medium.png,assets/icons/spotlight/spotlight_pin_v4_dot-2-medium.png&highlight=c5221f,ea4335,b31412?scale=1",
            iconSize: [28, 39],
            iconAnchor: [14, 39],
            shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
            shadowSize: [41, 41],
            popupAnchor: [1, -36],
        } satisfies IconOptions,
    },
    loading: "https://maps.gstatic.com/tactile/pane/spinner_color.gif",
} as const
