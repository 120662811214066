import { useSetAppSearch } from "@/components/routes/app-route.hooks"
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/containers/dialog"
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/containers/drawer"
import { Button } from "@/components/ui/form/button"
import { Label } from "@/components/ui/form/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/form/select"
import { InformationToken } from "@/components/ui/primitives/information-token"
import { logger } from "@/lib/notifications"
import { cn } from "@/lib/utils"
import React, { useState } from "react"
import { ItineraryFilterTrigger } from "./itinerary-filter.trigger"
import { useAppTransportType } from "@/hooks/use-app-transport-type"
import { AppTransportType } from "@/components/routes/app-route.zod"

const CONTENT_HEADER = "Itinerary filters"
const CONTENT_DESCRIPTION = "Update options below to filter itinerary results."

export function PlanOptionsExtended() {
    const [open, setOpen] = React.useState(false)
    const isDesktop = true //useMediaQuery("(min-width: 768px)")

    if (isDesktop) {
        return (
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <ItineraryFilterTrigger />
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>{CONTENT_HEADER}</DialogTitle>
                        <DialogDescription>
                            {CONTENT_DESCRIPTION}
                        </DialogDescription>
                    </DialogHeader>

                    <PlanOptionsForm />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Drawer open={open} onOpenChange={setOpen}>
            <DrawerTrigger asChild>
                <ItineraryFilterTrigger />
            </DrawerTrigger>
            <DrawerContent>
                <DrawerHeader className="text-left">
                    <DrawerTitle>{CONTENT_HEADER}</DrawerTitle>
                    <DrawerDescription>
                        {CONTENT_DESCRIPTION}
                    </DrawerDescription>
                </DrawerHeader>

                <PlanOptionsForm className="px-4" />
                <DrawerFooter className="pt-2">
                    <DrawerClose asChild>
                        <Button variant="outline">Cancel</Button>
                    </DrawerClose>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

function PlanOptionsForm({ className }: React.ComponentProps<"form">) {
    const { set } = useSetAppSearch()
    const defaultFilter = useAppTransportType()
    const [filter, setFilter] = useState<AppTransportType>(defaultFilter);

    return (
        <div className={cn("grid items-start gap-4", className)}>
            <div className="flex flex-col gap-2 min-h-32">
                <InformationToken
                    className="w-max flex flex-row items-center justify-center"
                    content={"Extends search with routes that require the selected eligability."}
                >
                    <Label>Eligability restrictions</Label>
                </InformationToken>

                <Select
                    defaultValue={filter}
                    onValueChange={(value) => {
                        if (value !== "n" && value !== "m") {
                            logger.error("PLAN OPTION FORM+PLAN SELECT - MUST BE A VALID AppTransportType")
                            return;
                        }

                        setFilter(value)
                    }}>
                    <SelectTrigger className="w-full border border-input">
                        <SelectValue placeholder="Select a filter" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="m">Medical, veteran, etc.</SelectItem>
                        <SelectItem value="n">No restrictions</SelectItem>
                    </SelectContent>
                </Select>
            </div>

            <DialogClose asChild>
                <Button
                    variant={"default"}
                    className="text-primary-foreground"
                    onClick={() => {
                        set((prev) => {
                            if (!prev.data) {
                                return prev
                            }

                            prev.data.transportType = filter

                            return prev;
                        })
                    }}
                >Save changes</Button>
            </DialogClose>
        </div>
    )
}
