import "./index.css"
import "leaflet/dist/leaflet.css"

import React from "react"
import ReactDOM from "react-dom/client"

import { RouterProvider } from "@tanstack/react-router"

import { router } from "@/components/routes"

import { Amplify } from 'aws-amplify';
import outputs from '@/../amplify_outputs.json';

Amplify.configure(outputs);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
