import { ReactNode } from "react"
import { ShortcutsProvider } from "./ui/primitives/key-board-shortcuts"

export function Providers({ children }: { children: ReactNode }) {
  return (
    <ShortcutsProvider os="mac">
      {children}
    </ShortcutsProvider>
  )
}
