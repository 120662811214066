const TITLE_CASE_EXCEPTIONS = ['of', 'the', 'and'];

export function titleCaseFormatter(str: string | null | undefined) {
    if (typeof str !== "string") {
        return ""
    }

    return str.toLowerCase().split(' ').map((word, i) => {
        return TITLE_CASE_EXCEPTIONS.includes(word) && i != 0 ? word : word.charAt(0).toUpperCase().concat(word.substring(1));
    }).join(' ');
} 

export function sentenceCaseFormatter(str: string | null | undefined) {
    if (typeof str !== "string") {
        return ""
    }

    const sentence = str
    return sentence.charAt(0).toUpperCase().concat(sentence.substring(1))
} 
