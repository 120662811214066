import { env } from "@/env.setup"
import { nullRoutes } from "."
import { generateGraphqlQuery } from "./queries/generate-otp-query.graphql"
import { ROUTES_SLIM_QUERY } from "./queries.graphql"
import { RouteSchema, routesGraphqlResponse } from "./validations/routes.graphql"
import { RoutesQueryParams } from "@/types/otp/routes.graphql"
import { QueryName } from "./validations/planner.graphql"
import { MEDICAL_ROUTE_ID_KEY } from "@/config/otp"


const ROUTES_QUERY = generateGraphqlQuery<
    RoutesQueryParams,
    typeof ROUTES_SLIM_QUERY,
    QueryName<typeof ROUTES_SLIM_QUERY>,
    typeof routesGraphqlResponse
>(
    ROUTES_SLIM_QUERY,
    "ROUTES",
    env.VITE_OTP_GRAPHQL_URL,
    routesGraphqlResponse,
)


export type GroupedRoutes = {
    medical: readonly RouteSchema[]
    medicalIds: string
    standard: readonly RouteSchema[]
    standardIds: string
    all: readonly RouteSchema[]
}

export async function setupRoutes() {
    const res = await ROUTES_QUERY()
    
    const routes = res.data?.routes
    if (!routes) {
        return nullRoutes;
    }
    
    const medicalRoutes = []
    const standardRoutes = []
    
    for (const route of routes) {
        if (route.gtfsId?.includes(MEDICAL_ROUTE_ID_KEY)) {
            medicalRoutes.push(route)
        } else {
            standardRoutes.push(route)
        }
    }

    return {
        all: routes,
        medical: medicalRoutes,
        medicalIds: medicalRoutes.map(r => r.gtfsId).join(','),
        standard: standardRoutes,
        standardIds: standardRoutes.map(r => r.gtfsId).join(','),
    } satisfies GroupedRoutes
}