import { ItinerarySchema, LegSchema } from "@/services/otp/validations/planner.graphql";
import { getIconFromMode } from "../get-icon-from-mode";
import { Icons } from "@/components/ui/icons";

/**
 * React component to show overview of the legs of the trip (i.e., walking, bus, car, etc.)
 * 
 * @param param0 
 * @returns React Component
 */
export function ItineraryCardGraphic({ legs }:
    Pick<ItinerarySchema, "legs">
) {
    if (!legs || legs.length <= 0) {
        return;
    }

    return (
        <div className="pt-4 pb-0 w-full overflow-x-auto grid grid-cols-1">
            <div className="flex flex-row gap-3 justify-center items-center w-max m-auto">
                {legs.map((l, i) => {
                    return (
                        <LegToGraphic
                            key={`${l.startTime}-${l.endTime}`}
                            index={i}
                            mode={l.mode}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export function LegToGraphic({ mode, index }: Pick<LegSchema, "mode"> & { index: number }) {

    return (
        <>
            {
                index !== 0 && (
                    <Icons.itinerary.transfer className="text-foreground" />
                )
            }
            {getIconFromMode(mode, {
                className: "w-8 h-8 fill-foreground"
            })}
        </>
    )
}