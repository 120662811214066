import { env } from '@/env.setup';
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { ItinerariesDevtools } from './itineraries-dev-tools';
import { useRouterState } from '@tanstack/react-router';

export function DevTools() {
    const state = useRouterState();
    if (state.isLoading || !env.DEV || !env.VITE_DEBUG_ENABLED) {
        return;
    }

    return (
        <>
            {
                state.location.pathname === "/" &&
                <ItinerariesDevtools
                    toggleButtonProps={{
                        className: "ml-40 h-8",
                    }}
                />
            }

            <TanStackRouterDevtools />
        </>
    )
}