"use client"

import * as React from "react"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import * as ContextMenuPrimitive from "@radix-ui/react-context-menu"
import { Check, Circle } from "lucide-react"

import { cn } from "@/lib/utils"

export type MenuItemProps = {
    variant?: 'dropdown' | 'context'
}

const MenuItem = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.Item | typeof ContextMenuPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item | typeof ContextMenuPrimitive.Item> &
    MenuItemProps & {
        inset?: boolean
    }
>(({ className, inset, variant, ...props }, ref) => {
    const Comp = variant === 'dropdown'
        ? DropdownMenuPrimitive.Item
        : ContextMenuPrimitive.Item;

    return (
        <Comp
            ref={ref}
            className={cn(
                "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
                inset && "pl-8",
                className
            )}
            {...props}
        />
    )
})
MenuItem.displayName = "MenuItem"

const MenuCheckboxItem = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem | typeof ContextMenuPrimitive.CheckboxItem>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem | typeof ContextMenuPrimitive.CheckboxItem> &
    MenuItemProps
>(({ className, children, variant, checked, ...props }, ref) => {
    const CompItem = variant === 'dropdown'
        ? DropdownMenuPrimitive.CheckboxItem
        : ContextMenuPrimitive.CheckboxItem;
    const CompIndicator = variant === 'dropdown'
        ? DropdownMenuPrimitive.ItemIndicator
        : ContextMenuPrimitive.ItemIndicator;
    
    return (
    <CompItem
        ref={ref}
        className={cn(
            "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className
        )}
        checked={checked}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <CompIndicator>
                <Check className="h-4 w-4" />
            </CompIndicator>
        </span>
        {children}
    </CompItem>
) })
MenuCheckboxItem.displayName = "MenuCheckboxItem"

const MenuRadioItem = React.forwardRef<
    React.ElementRef<typeof DropdownMenuPrimitive.RadioItem | typeof ContextMenuPrimitive.RadioItem>,
    React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem | typeof ContextMenuPrimitive.RadioItem> & 
    MenuItemProps
>(({ className, children, variant, ...props }, ref) => {
    const CompItem = variant === 'dropdown'
        ? DropdownMenuPrimitive.RadioItem
        : ContextMenuPrimitive.RadioItem;
    const CompIndicator = variant === 'dropdown'
        ? DropdownMenuPrimitive.ItemIndicator
        : ContextMenuPrimitive.ItemIndicator;
    
    return (
    <CompItem
        ref={ref}
        className={cn(
            "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <CompIndicator>
                <Circle className="h-2 w-2 fill-current" />
            </CompIndicator>
        </span>
        {children}
    </CompItem>
) })
MenuRadioItem.displayName = "MenuRadioItem"

const MenuShortcut = ({
    className,
    variant,
    ...props
}: React.HTMLAttributes<HTMLSpanElement> & MenuItemProps) => {
    return (
        <span
            className={cn(
                "ml-auto text-xs tracking-widest", 
                variant === 'dropdown' ? 'opacity-60' : 'text-muted-foreground',
                className
            )}
            {...props}
        />
    )
}
MenuShortcut.displayName = "MenuShortcut"

export {
    MenuItem,
    MenuCheckboxItem,
    MenuRadioItem,
    MenuShortcut,
}

