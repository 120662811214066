import { appRoute } from "@/components/routes/app-route";
import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/containers/tool-tip";
import { Button } from "@/components/ui/form/button";
import { Icons } from "@/components/ui/icons";
import { Separator } from "@/components/ui/primitives/separator";
import { MAP_ASSETS } from "@/config/assets";
import { cn } from "@/lib/utils";
import { ComponentClassName } from "@/types/utility";

export function PlanOverview() {
    const from = appRoute.useSearch({ select: (search) => search.from })
    const to = appRoute.useSearch({ select: (search) => search.to })

    return (
        <div className="flex flex-row gap-4 items-center px-4 md:px-0">
            <div className="w-full gap-2 grid-cols-1 hidden md:grid">
                <div className="flex flex-row px-2 items-center justify-between">
                    <img src={MAP_ASSETS.marker.start.iconUrl} className="aspect-square w-4 h-4" />

                    <Tooltip>
                        <TooltipTrigger asChild>
                            <span className="ml-2 w-full text-start text-ellipsis text-nowrap overflow-clip">
                                {from?.key}
                            </span>
                        </TooltipTrigger>

                        <TooltipContent>
                            {from?.key}
                        </TooltipContent>
                    </Tooltip>
                </div>

                <Separator className="col-span-2" />

                <div className="flex flex-row px-2 items-center justify-between">
                    <img src={MAP_ASSETS.marker.end.iconUrl} width={16} />
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <span className="ml-2 w-full text-start text-ellipsis text-nowrap overflow-clip">
                                {to?.key}
                            </span>
                        </TooltipTrigger>

                        <TooltipContent>
                            {to?.key}
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>

            <div className="grid grid-cols-[1fr,_auto,_1fr] gap-3 items-center md:hidden w-full">
                <Tooltip>
                    <TooltipTrigger className="grid grid-cols-[auto,_1fr] items-center">
                        <img src={MAP_ASSETS.marker.start.iconUrl} className="aspect-square w-4 h-4" />
                        <span className="ml-2 text-start text-nowrap overflow-hidden text-ellipsis">
                            {from?.key}
                        </span>
                    </TooltipTrigger>

                    <TooltipContent>
                        {from?.key}
                    </TooltipContent>
                </Tooltip>

                <Icons.itinerary.transfer />

                <Tooltip>
                    <TooltipTrigger className="grid grid-cols-[auto,_1fr] items-center">
                        <img src={MAP_ASSETS.marker.end.iconUrl} width={16} />
                        <span className="ml-2 text-start text-nowrap overflow-hidden text-ellipsis">
                            {to?.key}
                        </span>
                    </TooltipTrigger>

                    <TooltipContent>
                        {to?.key}
                    </TooltipContent>
                </Tooltip>
            </div>
            
            <FlipLocations className="min-w-6">
                <Icons.actions.swap className="stroke-[1.5px] text-foreground w-6 h-6 hidden md:block" />
                <Icons.actions.swapHorizontal className="stroke-[1.5px] text-foreground w-6 h-6 block md:hidden" />
            </FlipLocations>
        </div>
    )
}

function FlipLocations({
    className,
    children = <Icons.actions.swap className="stroke-[1.5px] text-foreground w-6 h-6" />
}: ComponentClassName) {
    const { set } = useSetAppSearch()

    return (
        <Button
            className={cn("", className)}
            variant={"ghost"}
            size={"icon"}
            onClick={() => {
                set(({ from, to, ...prev }) => ({
                    to: from,
                    from: to,
                    ...prev
                }))
            }}
        >
            {children}
        </Button>
    )
}