import type { z } from "zod";
import { validatedFetch } from "../../zod/validated-fetch";
import { PlanQueryParams, QueryParamPlace } from "@/types/otp";
import { AppPlace } from "@/components/routes/app-route.zod";
import { FetchInit } from "@/types/api";

type GenerateFetchProps<T extends z.ZodTypeAny> = Omit<Parameters<typeof validatedFetch<T>>["0"], "init" | "url"> & {
    url: string,
}

export function generatePlanForNow<T extends z.ZodTypeAny>(props: GenerateFetchProps<T>): (fetchProps: FetchNowProps, init?: FetchInit) => Promise<z.infer<T>> {
    const plannerFetch = generatePlannerFetch(props);

    return function fetch(fetchProps: FetchNowProps, init?: FetchInit) {
        return plannerFetch(createQueryParamsForNow(fetchProps), init)
    }
}

export function generatePlannerSlimFetch<T extends z.ZodTypeAny>(props: GenerateFetchProps<T>): (fetchProps: FetchProps, init?: FetchInit) => Promise<z.infer<T>> {
    const plannerFetch = generatePlannerFetch(props);

    return function fetch(fetchProps: FetchProps, init?: FetchInit) {
        return plannerFetch(createQueryParams(fetchProps), init)
    }
}

export function generatePlannerFetch<T extends z.ZodTypeAny>({
    schema,
    url,
}: GenerateFetchProps<T>): (props: PlanQueryParams, init?: FetchInit) => Promise<z.infer<T>> {

    return function fetch(props: PlanQueryParams, init?: FetchInit): Promise<z.infer<T>> {
        const queryParams = createQueryParams(props);
        const urlQueryParams = serializeQueryParams(queryParams).toString()
        const endpoint = `${url}?${urlQueryParams}`
        return validatedFetch({ url: endpoint, schema: schema, init: init });
    }
}


type FetchNowProps = Pick<
    PlanQueryParams,
    | "mode"
> & {
    arriveBy?: PlanQueryParams["arriveBy"],
    showIntermediateStops?: PlanQueryParams["showIntermediateStops"],
    from?: AppPlace,
    to?: AppPlace,
}


type FetchProps = Pick<
    PlanQueryParams,
    | "mode"
    | "toPlace"
    | "fromPlace"
    | "time"
    | "date"
> & {
    arriveBy?: PlanQueryParams["arriveBy"],
    showIntermediateStops?: PlanQueryParams["showIntermediateStops"],
}

function createQueryParams({ arriveBy = false, showIntermediateStops = true, ...rest }: FetchProps): PlanQueryParams {
    return {
        locale: "en_US",
        arriveBy: arriveBy,
        showIntermediateStops: showIntermediateStops,
        ...rest
    };
}

function createQueryParamsForNow({ from, to, arriveBy = false, showIntermediateStops = true, ...rest }: FetchNowProps): PlanQueryParams {
    const now = new Date();
    const month = now.getUTCMonth() + 1; // months from 1-12
    const day = now.getUTCDate();
    const year = now.getUTCFullYear();

    const hours = now.getHours()
    const minutes = now.getMinutes()
    const amOrPm = (hours >= 12) ? "pm" : "am";

    return {
        date: `${month}-${day}-${year}`,
        time: `${hours}:${minutes}${amOrPm}`,
        locale: "en_US",
        arriveBy: arriveBy,
        showIntermediateStops: showIntermediateStops,
        fromPlace: toQueryParamPlace(from),
        toPlace: toQueryParamPlace(to),
        ...rest
    };
}

export function toQueryParamPlace(place: AppPlace | undefined): QueryParamPlace {
    if (!place) {
        return '';
    }

    const { latlng } = place;
    return `${latlng[0]},${latlng[1]}`
}


/**
 * Converts properties to their string representation before adding to URLSearchParams list
 * @param props 
 * @returns 
 */
function serializeQueryParams(props: object): URLSearchParams {
    const params = new URLSearchParams();

    for (const { "0": key, "1": value } of Object.entries(props)) {
        params.append(key, getQueryParamValue(value))
    }

    return params
}

function getQueryParamValue(value: unknown): string {
    if (typeof value === 'object') {
        return JSON.stringify(value)
    }

    if (typeof value === 'boolean' || !!value) {
        return value.toString()
    }

    return ''
}