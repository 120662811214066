import { cn } from "@/lib/utils"
import React from "react"

export type ScrollAreaVariant = "div" | "article" | "p" | "section"

export const ScrollAreaNative = React.forwardRef<
HTMLDivElement,
React.HTMLAttributes<HTMLDivElement> & {
    variant?: ScrollAreaVariant,
}
>(({ className, variant, children, ...props }, ref) => {
    const Comp = variant ?? "div"

    return (
        <Comp
            ref={ref}
            className={cn(
                "h-full overflow-y-auto scrollbar-thin scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thumb-slate-400/50 scrollbar-track-slate-200 hover:scrollbar-thumb-slate-600 active:scrollbar-thumb-slate-700",
                className
            )}
            {...props}
        >
            {children}
        </Comp>
    )
})

ScrollAreaNative.displayName = "ScrollAreaNative"