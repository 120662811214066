import { appRoute } from "@/components/routes/app-route";
import { getPlanKey } from "@/hooks/use-is-selected-plan";
import { usePlanner } from "@/hooks/use-planner";
import { cn } from "@/lib/utils";
import { ComponentClassName } from "@/types/utility";
import { ItineraryDetailsEmpty } from "./states/itinerary-details.empty";
import { ItineraryDetailsHeader } from "./itinerary-details.header";
import { ItineraryDetailsFooter } from "./itinerary-details.footer";
import { ItineraryDetailsLegs } from "./itinerary-details.legs";
import { ItineraryCardAlerts } from "../itinerary-card/itinerary-card.alerts";
import { ItineraryDetailsLoading } from "./states/itinerary-details.loading";

export function ItineraryDetails({ className }: ComponentClassName) {
    const { data, error, isLoading } = usePlanner();
    const searchData = appRoute.useSearch({ select: (s) => s.data })

    if (error) {
        return;
    }
    
    if (isLoading) {
        return <ItineraryDetailsLoading />
    }

    if (!data) {
        return <ItineraryDetailsEmpty />
    }

    const itinerary = data.extended.itineraries?.all?.find(i => searchData?.selected === getPlanKey(i))
    if (!itinerary) {
        return <ItineraryDetailsEmpty />;
    }

    const { startTime, legs } = itinerary;
    if (!startTime) {
        return;
    }

    return (
        <>
            <div className={cn(
                "flex flex-col gap-3 md:gap-5 mb-1 md:mb-4",
                className
            )}>
                <ItineraryDetailsHeader {...itinerary} />

                <ItineraryCardAlerts legs={itinerary.legs} />
 
                <ItineraryDetailsLegs {...itinerary} />

                <ItineraryDetailsFooter
                    startTime={startTime}
                    legs={legs}
                />
            </div>
        </>
    )
}
