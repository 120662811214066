import { KeyCombo, Keys } from "@/components/ui/primitives/key-board-shortcuts"
import { MenuItemProps, MenuShortcut } from "@/components/ui/primitives/menu-item"
import { InjectMapProps } from "@/types/leaflet"
import { LatLng } from "leaflet";

export type SetPlaceItemProps = {
    contextOpenedAt: LatLng | undefined;
    location: "from" | "to"
} & InjectMapProps & MenuItemProps

export function SetPlaceItemContent({ location, variant }: Pick<SetPlaceItemProps, "location" | "variant">) {
    if (location === "from") {
        return (
            <>
                Start

                <MenuShortcut variant={variant}>
                    <KeyCombo keyNames={[Keys.Command, Keys.Shift, "F"]} />
                </MenuShortcut>
            </>
        )
    }

    return (
        <>
            End

            <MenuShortcut variant={variant}>
                <KeyCombo keyNames={[Keys.Command, Keys.Shift, "E"]} />
            </MenuShortcut>
        </>
    )
}