import { cn } from "@/lib/utils";
import { ComponentClassName } from "@/types/utility";
import { ReactNode } from "@tanstack/react-router";

export function CardMessageWrapper({ children, icon, className }: ComponentClassName<{ icon: ReactNode }>) {
    return (
        <div className={cn("text-center text-pretty w-full flex flex-col justify-center items-center gap-4 mt-12 mb-64 text-foreground px-4 2xl:px-16", className)}>
            {icon}

            {children}
        </div>
    )
}
