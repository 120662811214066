import { Icons } from "@/components/ui/icons";
import { metersToMilesFormatter } from "@/lib/formatters/distance-formatters";
import { sentenceCaseFormatter } from "@/lib/formatters/string-formatters";
import { AbsoluteDirectionSchema, RelativeDirectionSchema, StepSchema } from "@/services/otp/validations/planner.graphql";

export function ItineraryDetailsStep({ bogusName, absoluteDirection, relativeDirection, streetName, distance }: StepSchema) {
    const action = getAction(relativeDirection, absoluteDirection).toLowerCase()

    return (
        <div className="flex flex-row gap-2 items-center text-sm text-foreground">
            <span>
                {getIcon(relativeDirection)}
            </span>
            <span className="flex flex-row gap-1 items-center">
                {sentenceCaseFormatter(action?.replace('_', ' '))}
                {bogusName 
                    ? <>{" "} on {" "} the {" "} {streetName}</>
                    : <>{" "} on {" "} {streetName}</>
                }
            </span>
            <span className="text-subtle-foreground">
                {metersToMilesFormatter(distance)}
            </span>
        </div>
    )
}

function getAction(relativeDirection: RelativeDirectionSchema | null | undefined, absoluteDirection: AbsoluteDirectionSchema | null | undefined) {
    if (relativeDirection === "DEPART") {
        return `HEAD ${absoluteDirection}`
    }
        
    return `${relativeDirection}`
}

function getIcon(relativeDirection: RelativeDirectionSchema | null | undefined) {
    if (!relativeDirection) {
        return <Icons.itinerary.walk />
    }

    const PotentialIcon = Icons.itinerary.directions[relativeDirection];
    if (!PotentialIcon) {
        return <Icons.itinerary.walk />
    }

    return <PotentialIcon />
}