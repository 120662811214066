import { z } from "zod";
import { graphqlResponse, idSchema } from "./base.grpahql";
import { alertSchema } from "./errors.graphql";

export const agencySchema = z.object({
    id: idSchema.optional(),
    gtfsId: z.string().optional(),
    name: z.string().optional(),
    url: z.string().optional(),
    timezone: z.string().optional(),
    lang: z.string().optional().nullable(),
    phone: z.string().optional().nullable(),
    fareUrl: z.string().url().optional().nullable(),
    /**
     * @see routeSchema
     */
    routes: z.array(z.unknown()).optional().nullable(),
    alerts: z.array(alertSchema).optional().nullable(),
})

export const agenciesResponse = z.object({
    agencies: z.array(agencySchema).optional().nullable()
})


export const agenciesGraphqlResponse = graphqlResponse(
    agenciesResponse,
);

export type AgenciesResponseScheme = z.infer<typeof agenciesResponse>