const METER_PER_KILOMETER = 1000;
const METER_PER_MILE = 1609.34

/**
 * 
 */
export class Distance {
    private _meters: number;

    private static dist(value: number, scale: number): Distance {
        if (Number.isNaN(value)) {
            throw new Error("value can't be NaN");
        }

        const tmp = value * scale;
        const meters = Distance.round(tmp + (value >= 0 ? 0.5 : -0.5));
        if ((meters > Distance.maxValue.totalMeters) || (meters < Distance.minValue.totalMeters)) {
            throw new Error("DistanceTooLong");
        }

        return new Distance(meters);
    }


    private static round(n: number): number {
        if (n < 0) {
            return Math.ceil(n);
        } else if (n > 0) {
            return Math.floor(n);
        }

        return 0;
    }

    public static get zero(): Distance {
        return new Distance(0);
    }

    public static get maxValue(): Distance {
        return new Distance(Number.MAX_SAFE_INTEGER);
    }

    public static get minValue(): Distance {
        return new Distance(Number.MIN_SAFE_INTEGER);
    }

    public static fromKilometers(value: number): Distance {
        return Distance.dist(value, METER_PER_KILOMETER);
    }

    public static fromMiles(value: number): Distance {
        return Distance.dist(value, METER_PER_MILE);
    }

    public static fromMeters(value: number): Distance {
        return Distance.dist(value, 1);
    }
    
    constructor(meters: number) {
        this._meters = meters;
    }

    public get kilometers(): number {
        return Distance.round((this._meters / METER_PER_KILOMETER) % 1);
    }

    public get meters(): number {
        return Distance.round((this._meters) % 1000);
    }

    public get totalKilometers(): number {
        return this._meters / METER_PER_KILOMETER;
    }

    public get totalMiles(): number {
        return this._meters / METER_PER_MILE;
    }

    public get totalMeters(): number {
        return this._meters;
    }

    public add(d: Distance): Distance {
        const result = this._meters + d.totalMeters;
        return new Distance(result);
    }

    public subtract(d: Distance): Distance {
        const result = this._meters - d.totalMeters;
        return new Distance(result);
    }
}

