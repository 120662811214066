export const ITINERARY_CARD_QUERY = `
query QUERY(
  $date: String
  $time: String
  $from: InputCoordinates
  $to: InputCoordinates
  $transportModes: [TransportMode]
  $fromPlace: String
  $toPlace: String
  $wheelchair: Boolean
  $numItineraries: Int
  $searchWindow: Long
  $pageCursor: String
  $bikeReluctance: Float
  $bikeWalkingReluctance: Float
  $carReluctance: Float
  $walkReluctance: Float
  $waitReluctance: Float
  $walkSpeed: Float
  $bikeSpeed: Float
  $bikeSwitchTime: Int
  $bikeSwitchCost: Int
  $optimize: OptimizeType
  $triangle: InputTriangle
  $arriveBy: Boolean
  $preferred: InputPreferred
  $unpreferred: InputUnpreferred
  $walkBoardCost: Int
  $bikeBoardCost: Int
  $banned: InputBanned
  $transferPenalty: Int
  $modeWeight: InputModeWeight
  $debugItineraryFilter: Boolean
  $allowKeepingRentedBicycleAtDestination: Boolean
  $keepingRentedBicycleAtDestinationCost: Int
  $boardSlack: Int
  $alightSlack: Int
  $minTransferTime: Int
  $nonpreferredTransferPenalty: Int
  $maxTransfers: Int
  $startTransitStopId: String
  $omitCanceled: Boolean
  $ignoreRealtimeUpdates: Boolean
  $locale: String
  $allowedTicketTypes: [String]
  $allowedVehicleRentalNetworks: [String]
  $bannedVehicleRentalNetworks: [String]
  $walkSafetyFactor: Float
  $parking: VehicleParkingInput
) {
  plan(
    date: $date
    time: $time
    from: $from
    to: $to
    transportModes: $transportModes
    fromPlace: $fromPlace
    toPlace: $toPlace
    wheelchair: $wheelchair
    numItineraries: $numItineraries
    searchWindow: $searchWindow
    pageCursor: $pageCursor
    bikeReluctance: $bikeReluctance
    bikeWalkingReluctance: $bikeWalkingReluctance
    carReluctance: $carReluctance
    walkReluctance: $walkReluctance
    waitReluctance: $waitReluctance
    walkSpeed: $walkSpeed
    bikeSpeed: $bikeSpeed
    bikeSwitchTime: $bikeSwitchTime
    bikeSwitchCost: $bikeSwitchCost
    optimize: $optimize
    triangle: $triangle
    arriveBy: $arriveBy
    preferred: $preferred
    unpreferred: $unpreferred
    walkBoardCost: $walkBoardCost
    bikeBoardCost: $bikeBoardCost
    banned: $banned
    transferPenalty: $transferPenalty
    modeWeight: $modeWeight
    debugItineraryFilter: $debugItineraryFilter
    allowKeepingRentedBicycleAtDestination: $allowKeepingRentedBicycleAtDestination
    keepingRentedBicycleAtDestinationCost: $keepingRentedBicycleAtDestinationCost
    boardSlack: $boardSlack
    alightSlack: $alightSlack
    minTransferTime: $minTransferTime
    nonpreferredTransferPenalty: $nonpreferredTransferPenalty
    maxTransfers: $maxTransfers
    startTransitStopId: $startTransitStopId
    omitCanceled: $omitCanceled
    ignoreRealtimeUpdates: $ignoreRealtimeUpdates
    locale: $locale
    allowedTicketTypes: $allowedTicketTypes
    allowedVehicleRentalNetworks: $allowedVehicleRentalNetworks
    bannedVehicleRentalNetworks: $bannedVehicleRentalNetworks
    walkSafetyFactor: $walkSafetyFactor
    parking: $parking
  ) {
    date
    itineraries {
      duration
      generalizedCost
      waitingTime
      startTime
      endTime
      legs {
        from {
          lat
          lon
          name
        }
        to {
          lat
          lon
          name
        }
        startTime
        endTime
        legGeometry {
          points
        }
        mode
        arrivalDelay
        duration
        distance
        generalizedCost
        agency {
          gtfsId
          id
          name
          url
          phone
          fareUrl
          alerts {
            feed
            alertHeaderText
            alertDescriptionText
            alertSeverityLevel
          }
        }
        trip {
          gtfsId
        }
        route {
          gtfsId
          longName
        }
        intermediateStops {
					id
          gtfsId
          name
        }
        dropoffType
        pickupType
        pickupBookingInfo {
          message
          pickupMessage
          dropOffMessage
          minimumBookingNoticeSeconds
          maximumBookingNoticeSeconds
          contactInfo {
            contactPerson
            phoneNumber
            eMail
            faxNumber
            infoUrl
            bookingUrl
            additionalDetails
          }
          latestBookingTime {
            time
            daysPrior
          }
          earliestBookingTime {
            time
            daysPrior
          }
        }
        dropOffBookingInfo {
          message
          pickupMessage
          dropOffMessage
          minimumBookingNoticeSeconds
          maximumBookingNoticeSeconds
          contactInfo {
            contactPerson
            phoneNumber
            eMail
            faxNumber
            infoUrl
            bookingUrl
            additionalDetails
          }
          latestBookingTime {
            time
            daysPrior
          }
          earliestBookingTime {
            time
            daysPrior
          }
        }
        steps {
					distance
          bogusName
          streetName
          stayOn
          relativeDirection
          absoluteDirection
          lat
          lon
        }
      }
    }

    routingErrors {
      code
      inputField
      description
    }
  }
}
` as const


export const DEFAULT_QUERY = `
query QUERY(
  $date: String
  $time: String
  $from: InputCoordinates
  $to: InputCoordinates
  $transportModes: [TransportMode]
  $fromPlace: String
  $toPlace: String
  $wheelchair: Boolean
  $numItineraries: Int
  $searchWindow: Long
  $pageCursor: String
  $bikeReluctance: Float
  $bikeWalkingReluctance: Float
  $carReluctance: Float
  $walkReluctance: Float
  $waitReluctance: Float
  $walkSpeed: Float
  $bikeSpeed: Float
  $bikeSwitchTime: Int
  $bikeSwitchCost: Int
  $optimize: OptimizeType
  $triangle: InputTriangle
  $arriveBy: Boolean
  $preferred: InputPreferred
  $unpreferred: InputUnpreferred
  $walkBoardCost: Int
  $bikeBoardCost: Int
  $banned: InputBanned
  $transferPenalty: Int
  $modeWeight: InputModeWeight
  $debugItineraryFilter: Boolean
  $allowKeepingRentedBicycleAtDestination: Boolean
  $keepingRentedBicycleAtDestinationCost: Int
  $boardSlack: Int
  $alightSlack: Int
  $minTransferTime: Int
  $nonpreferredTransferPenalty: Int
  $maxTransfers: Int
  $startTransitStopId: String
  $omitCanceled: Boolean
  $ignoreRealtimeUpdates: Boolean
  $locale: String
  $allowedTicketTypes: [String]
  $allowedVehicleRentalNetworks: [String]
  $bannedVehicleRentalNetworks: [String]
  $walkSafetyFactor: Float
  $parking: VehicleParkingInput
) {
  plan(
    date: $date
    time: $time
    from: $from
    to: $to
    transportModes: $transportModes
    fromPlace: $fromPlace
    toPlace: $toPlace
    wheelchair: $wheelchair
    numItineraries: $numItineraries
    searchWindow: $searchWindow
    pageCursor: $pageCursor
    bikeReluctance: $bikeReluctance
    bikeWalkingReluctance: $bikeWalkingReluctance
    carReluctance: $carReluctance
    walkReluctance: $walkReluctance
    waitReluctance: $waitReluctance
    walkSpeed: $walkSpeed
    bikeSpeed: $bikeSpeed
    bikeSwitchTime: $bikeSwitchTime
    bikeSwitchCost: $bikeSwitchCost
    optimize: $optimize
    triangle: $triangle
    arriveBy: $arriveBy
    preferred: $preferred
    unpreferred: $unpreferred
    walkBoardCost: $walkBoardCost
    bikeBoardCost: $bikeBoardCost
    banned: $banned
    transferPenalty: $transferPenalty
    modeWeight: $modeWeight
    debugItineraryFilter: $debugItineraryFilter
    allowKeepingRentedBicycleAtDestination: $allowKeepingRentedBicycleAtDestination
    keepingRentedBicycleAtDestinationCost: $keepingRentedBicycleAtDestinationCost
    boardSlack: $boardSlack
    alightSlack: $alightSlack
    minTransferTime: $minTransferTime
    nonpreferredTransferPenalty: $nonpreferredTransferPenalty
    maxTransfers: $maxTransfers
    startTransitStopId: $startTransitStopId
    omitCanceled: $omitCanceled
    ignoreRealtimeUpdates: $ignoreRealtimeUpdates
    locale: $locale
    allowedTicketTypes: $allowedTicketTypes
    allowedVehicleRentalNetworks: $allowedVehicleRentalNetworks
    bannedVehicleRentalNetworks: $bannedVehicleRentalNetworks
    walkSafetyFactor: $walkSafetyFactor
    parking: $parking
  ) {
    date
    from {
      lat
      lon
    },
    to {
      lat
      lon
    },
    itineraries {
      legs {
        startTime
        endTime
        from {
          lat
          lon
        }
        to {
          lat
          lon
        }
        departureDelay
        legGeometry {
          points
        }
        generalizedCost
        agency {
          id
          name
          url
          phone
          fareUrl
          alerts {
            feed
            alertHeaderText
            alertDescriptionText
            alertSeverityLevel
            effectiveStartDate
            effectiveEndDate
            entities {
              __typename
            }
          }
        }
        route {
          id
          shortName
          longName
        }
        realTime
        distance
        intermediateStops {
					id
          lat
          lon
          
        }
        steps {
					distance
          lat
          lon
          absoluteDirection
          relativeDirection
          elevationProfile {
            distance
            elevation
          }
          bogusName
          streetName
          stayOn
          relativeDirection
          absoluteDirection
          exit
          area
        }
        mode
        arrivalDelay
        duration
        from {
          name
          lat
          lon
          arrivalTime
          departureTime
          stop {
            geometries {
              geoJson
            }
          }
          vertexType
          name
        }
        to {
          name
          lat
          lon
          arrivalTime
          departureTime
          stop {
            geometries {
              geoJson
            }
          }
          vertexType
          name
        }
      }
      duration
      generalizedCost
      waitingTime
      walkTime
      walkDistance
      elevationGained
      elevationLost
      arrivedAtDestinationWithRentedBicycle
      accessibilityScore
      numberOfTransfers
      startTime
      endTime
    }
    nextPageCursor
    previousPageCursor
    routingErrors {
      code
      inputField
      description
    }
    messageStrings
    debugOutput {
      totalTime
      timedOut
    }
  }
}
` as const

export const AGENCIES = `
query AGENCIES {
  agencies {
    gtfsId
    name
    phone
    url
  }
}
` as const


export const ROUTES_AND_STOPS_QUERY = `
query ROUTES_AND_STOPS {
  stops {
    id
    name
    lat
    lon
    vehicleType
    locationType
    vehicleMode
    code
  }
}
` as const

export const ROUTES_AND_AGENCIES_QUERY = `
query ROUTES(
  $ids: [String]
  $feeds: [String]
  $name: String
  $transportModes: [Mode]
) {
  routes(
    ids: $ids
    feeds: $feeds
    name: $name
    transportModes: $transportModes
  ) {
    id
    gtfsId
    longName
    
    agency {
      id
      gtfsId
      name
      url
      phone
    }
  }
}
`

export const ROUTES_SLIM_QUERY = `
query ROUTES(
  $ids: [String]
  $feeds: [String]
  $name: String
  $transportModes: [Mode]
) {
  routes(
    ids: $ids
    feeds: $feeds
    name: $name
    transportModes: $transportModes
  ) {
    id
    gtfsId
    longName
  }
}
` as const

export const ROUTES_QUERY = `
query ROUTES(
  $ids: [String]
  $feeds: [String]
  $name: String
  $transportModes: [Mode]
) {
  routes(
    ids: $ids
    feeds: $feeds
    name: $name
    transportModes: $transportModes
  ) {
    id
    gtfsId
    shortName
    longName
    mode
    type
    desc
    url
    color
    textColor
    bikesAllowed
    patterns {
      id
      directionId
      name
      code
    }
  }
}
` as const
