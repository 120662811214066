"use client";

import * as React from "react";
import type * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "@/lib/utils";
import { Icons } from "../../icons";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/containers/tool-tip";
import { useMediaQuery } from "@/hooks/use-media-query";

type InformationTokenProps = PopoverPrimitive.PopoverTriggerProps & {
  content: React.ReactNode;
};

export function InformationToken({ content, ...props }: InformationTokenProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <>
      <Tooltip open={open} onOpenChange={setOpen}>
        <InformationTokenTrigger setOpen={setOpen} {...props} />
        <TooltipContent>{content}</TooltipContent>
      </Tooltip>
    </>
  );
}

function InformationTokenTrigger({
  className,
  children,
  setOpen,
  ...rest
}: Omit<InformationTokenProps, "content"> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isSmall = useMediaQuery("(max-width: 768px)");

  if (isSmall) {
    return (
      <TooltipTrigger
        asChild
        onClick={() => setOpen((prev) => !prev)}
        className={cn(
          "flex flex-row items-center gap-1.5 md:hidden",
          className,
        )}
        {...rest}
      >
        <div>
          {children}
          <Icons.alerts.default />
        </div>
      </TooltipTrigger>
    );
  }

  return (
    <TooltipTrigger
      className={cn("hidden cursor-default flex-row items-center gap-1.5 md:flex", className)}
      {...rest}
    >
      {children}
      <Icons.alerts.default />
    </TooltipTrigger>
  );
}
