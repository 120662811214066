import * as React from "react"

/**
 * React hook to bind to media query events
 * 
 * Used to decide when to render components.
 * 
 * @example const isDesktop = useMediaQuery("(min-width: 768px)")
 * @example const isTablet = useMediaQuery("(max-width: 768px)")
 * @example const isMobile = useMediaQuery("(max-width: 640px)")
 * 
 * @param query 
 * @returns True when media query matches, otherwise false
 */
export function useMediaQuery(query: string) {
  const [value, setValue] = React.useState(false)

  React.useEffect(() => {
    function onChange(event: MediaQueryListEvent) {
      setValue(event.matches)
    }

    const result = matchMedia(query)
    result.addEventListener("change", onChange)
    setValue(result.matches)

    return () => result.removeEventListener("change", onChange)
  }, [query])

  return value
}