import { appRoute } from "@/components/routes/app-route";
import { ItinerarySchema } from "@/services/otp/validations/planner.graphql";
import { useEffect, useState } from "react";

export function useIsSelectedPlan(plan: ItinerarySchema) {
    const data = appRoute.useSearch({ select: (search) => search.data })
    const [isSelected, setIsSelected] = useState(false)
    useEffect(() => {
        if (!plan) {
            return;
        }

        setIsSelected(data?.selected === getPlanKey(plan))
    }, [data, plan])

    return isSelected
}

export function getPlanKey({ legs, generalizedCost, startTime, endTime }: ItinerarySchema) {
    if (!legs || legs.length <= 0 || !startTime || !endTime) {
        return '';
    }

    const match = legs.find(l => l.route?.longName)

    return `${match?.route?.longName}-${match?.trip?.gtfsId}-${startTime}-${endTime}-${generalizedCost}`
}