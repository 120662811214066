import { cn } from "@/lib/utils"
import { ComponentClassName } from "@/types/utility"
import { Link } from "@/components/ui/primitives/link"
import { HelpCircle } from "lucide-react"
import { appRoute } from "../routes/app-route"
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/containers/tool-tip"

const OPEN_HELP_BUTTON_CONTENT = 'Help'

export function OpenHelpButton({ className }: ComponentClassName) {
    const search = appRoute.useSearch();
    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <Link
                    id="place"
                    size={"icon"}
                    variant={"outline"}
                    to="/help"
                    search={search}
                    mask={{ to: "/help" }}
                    className={cn(
                        "relative grid place-items-center h-full w-16",
                        "!rounded-none border-r-transparent duration-500 overflow-visible",
                        className
                    )}
                >
                    <HelpCircle className="w-4 h-4" />
                </Link>
            </TooltipTrigger>

            <TooltipContent>
                {OPEN_HELP_BUTTON_CONTENT}
            </TooltipContent>
        </Tooltip>
    )
}
