import React, { useEffect } from "react"
import { aws } from "@/services/aws"
import { SearchForSuggestionsResult } from "@aws-sdk/client-location"
import { LoaderIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Combobox } from "@/components/ui/form/combo-box"
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "@/components/ui/form/command"
import { Icons } from "@/components/ui/icons"

import { useLocationSuggestions } from "./use-location-suggestions"
import { useSetAppSearch } from "@/components/routes/app-route.hooks"
import { AppPlace } from "@/components/routes/app-route.zod"
import { appRoute } from "@/components/routes/app-route"
import { LocationSearchStaticOptions } from "./location-search-static-options"
import { InjectMapProps } from "@/types/leaflet"

export function LocationSearch({ map }: InjectMapProps) {
  const { from, to } = appRoute.useSearch()
  const { setFrom, setTo } = useSetAppSearch(map)

  return (
    <>
      <SearchLocation
        place={from}
        set={setFrom}
        popoverClassName="w-[calc(100svw-1rem)] ml-2 mr-2"
        className="!rounded-l-full sm:!rounded-none"
      />
      <SearchLocation
        place={to}
        set={setTo}
        popoverClassName="w-[calc(100svw-1rem)] ml-2 mr-2"
        className="!rounded-full !rounded-l-none" />
    </>
  )
}

function SearchLocation({
  className,
  popoverClassName,
  place,
  set,
}: {
  className: string
  popoverClassName?: string
  place?: AppPlace
  set: (v: AppPlace) => void
}) {
  const [value, setValue] = React.useState<string>()
  const [search, setSearch] = React.useState<string>()
  useEffect(() => {
    setValue(place?.key)
  }, [place])

  return (
    <>
      <Combobox
        value={value}
        shouldFilter={false}
        placeholder={"Enter an address or place e.g. 1 York St"}
        icon={<Icons.input.search className="w-4 h-4" />}
        onValueChange={(v) => {
          setSearch(v)
        }}
        empty={<CommandEmpty></CommandEmpty>}
        popoverClassName={popoverClassName}
        className={cn(
          "!rounded-none border-r-transparent h-full pl-6 pr-4 md:px-6",
          className
        )}
      >
        <SearchItems
          search={search}
          setValue={async (suggestion) => {
            setValue(suggestion.Text)

            const res = await aws.query.getPlace({
              PlaceId: suggestion.PlaceId,
            })

            set({
              key: suggestion.Text ?? res.Place?.Label ?? "",
              latlng: [
                res.Place?.Geometry?.Point?.[1] ?? 0,
                res.Place?.Geometry?.Point?.[0] ?? 0,
              ]
            })
          }}
        />
      </Combobox>
    </>
  )
}

function SearchItems({
  search,
  setValue,
}: {
  search?: string
  setValue: (value: SearchForSuggestionsResult) => void
}) {
  const { query, isLoading } = useLocationSuggestions(search, 500)
  const { data, error } = query

  if (!search) {
    return <LocationSearchStaticOptions setValue={setValue}>
      <CommandGroup heading={"Start typing to see more results near you."} />
    </LocationSearchStaticOptions>
  }

  if (error) {
    return <LocationSearchStaticOptions setValue={setValue}>
      <CommandGroup heading={error.message} />
    </LocationSearchStaticOptions>
  }

  if (isLoading) {
    return <LocationSearchStaticOptions setValue={setValue}>
      <CommandGroup
        heading="Loading near you..."
        className="grid place-items-center mb-4"
      >
        <LoaderIcon className="w-4 h-4 animate-spin" />
      </CommandGroup>
    </LocationSearchStaticOptions>
  }

  return <LocationSearchStaticOptions setValue={setValue}>
    {data?.Results && data.Results.length > 0 && (
      <CommandGroup heading={"Near you"}>
        {data.Results.map((o) => {
          return (
            <CommandItem
              key={o.Text}
              value={o.Text}
              className="text-base sm:text-sm"
              onSelect={() => setValue(o)}
            >
              {o.Text}
            </CommandItem>
          )
        })}
      </CommandGroup>
    )}
  </LocationSearchStaticOptions>
}