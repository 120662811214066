import { dateToHoursAndMinutesFormatter } from "@/lib/formatters/date-formatters";
import { cn } from "@/lib/utils";
import React from "react";

export interface ItineraryDateProps
  extends Omit<React.TimeHTMLAttributes<HTMLTimeElement>, "children"> {
  formatter?: (date: Date) => React.ReactNode;
  datetime?: Date;
}

export const ItineraryDate = React.forwardRef<
  HTMLTimeElement,
  ItineraryDateProps
>((
  {
    className,
    datetime,
    formatter = (d) => dateToHoursAndMinutesFormatter(d),
    ...props
  },
  ref,
) => {
  if (!datetime) {
    return <></>;
  }

  return (
    <time
      ref={ref}
      className={cn(
        "w-10 md:w-16 block font-normal leading-none text-subtle-foreground text-xs",
        className,
      )}
      {...props}
    >
      {formatter(datetime)}
    </time>
  );
});

ItineraryDate.displayName = "TimelineDate";
