import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import { LucideProps } from "lucide-react";
import React from "react";

const iconVariants = cva("flex items-center justify-center rounded-full", {
  variants: {
    variant: {
      primary: "bg-primary text-primary-foreground",
      secondary: "bg-secondary text-secondary-foreground",
      destructive: "bg-destructive text-destructive-foreground",
    },
    size: {
      sm: "h-2 w-2",
      md: "h-6 w-6",
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});

export interface ItineraryIconProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof iconVariants> {
  icon?: React.ReactElement<LucideProps>;
  wrapperClassName?: string;
}

export const ItineraryIcon = React.forwardRef<
  HTMLSpanElement,
  ItineraryIconProps
>(
  (
    {
      className,
      wrapperClassName,
      size,
      variant,
      icon,
      ...props
    },
    ref,
  ) => {
    if (!icon) {
      return <></>
    }

    return (
      <span className="absolute rounded-full -start-[0.8rem] ring-4 ring-background">
        <span
          ref={ref}
          className={cn(iconVariants({ size, variant, className }))}
          {...props}
        >
          <span className={cn("scale-[0.75]", wrapperClassName)}>{icon}</span>
        </span>
      </span>
    );
  },
);

ItineraryIcon.displayName = "ItineraryIcon";
