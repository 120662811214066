/**
 * Values were generated from ts types from https://transform.tools/typescript-to-zod
 */

import { z } from "zod"

const messageSchema = z.union([
  z.literal("PLAN_OK"),
  z.literal("SYSTEM_ERROR"),
  z.literal("GRAPH_UNAVAILABLE"),
  z.literal("OUTSIDE_BOUNDS"),
  z.literal("PATH_NOT_FOUND"),
  z.literal("NO_TRANSIT_TIMES"),
  z.literal("REQUEST_TIMEOUT"),
  z.literal("BOGUS_PARAMETER"),
  z.literal("GEOCODE_FROM_NOT_FOUND"),
  z.literal("GEOCODE_TO_NOT_FOUND"),
  z.literal("GEOCODE_FROM_TO_NOT_FOUND"),
  z.literal("TOO_CLOSE"),
  z.literal("LOCATION_NOT_ACCESSIBLE"),
  z.literal("UNDERSPECIFIED_TRIANGLE"),
  z.literal("TRIANGLE_NOT_AFFINE"),
  z.literal("TRIANGLE_OPTIMIZE_TYPE_NOT_SET"),
  z.literal("TRIANGLE_VALUES_NOT_SE")
])

const apiTripSearchMetadataSchema = z.object({
  searchWindowUsed: z.number()
})

const transitTimingOutputSchema = z.object({
  tripPatternFilterTime: z.number(),
  accessEgressTime: z.number(),
  raptorSearchTime: z.number(),
  itineraryCreationTime: z.number()
})

const elevationMetadataSchema = z.object({
  ellipsoidToGeoidDifference: z.number(),
  geoidElevation: z.boolean()
})

export const vertexTypeSchema = z.union([
  z.literal("NORMAL"),
  z.literal("BIKESHARE"),
  z.literal("BIKEPARK"),
  z.literal("TRANSIT"),
  z.literal("PARKANDRIDE"),
])

const parkingSpaceSchema = z.object({
  bicycleSpaces: z.number(),
  carSpaces: z.number(),
  wheelchairAccessibleCarSpaces: z.number()
})

const fareSchema = z.unknown()

const geometrySchema = z.object({
  length: z.number().optional(),
  points: z.string().optional(),
})

const relativeDirectionSchema = z.union([
  z.literal("DEPART"),
  z.literal("HARD_LEFT"),
  z.literal("LEFT"),
  z.literal("SLIGHTLY_LEFT"),
  z.literal("CONTINUE"),
  z.literal("SLIGHTLY_RIGHT"),
  z.literal("RIGHT"),
  z.literal("HARD_RIGHT"),
  z.literal("CIRCLE_CLOCKWISE"),
  z.literal("CIRCLE_COUNTERCLOCKWISE"),
  z.literal("ELEVATOR"),
  z.literal("UTURN_LEFT"),
  z.literal("UTURN_RIGHT")
])

const absoluteDiretionSchema = z.union([
  z.literal("NORTH"),
  z.literal("NORTHEAST"),
  z.literal("EAST"),
  z.literal("SOUTHEAST"),
  z.literal("SOUTH"),
  z.literal("SOUTHWEST"),
  z.literal("WEST"),
  z.literal("NORTHWEST")
])

const alertSchema = z.object({
  alertHeaderText: z.string(),
  alertDescriptionText: z.string().optional().nullable(),
  alertUrl: z.string().optional().nullable(),
  effectiveStartDate: z.number().optional().nullable(),
  effectiveEndDate: z.number().optional().nullable()
})

const contactInfoSchema = z.object({
  contactPerson: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  eMail: z.string().optional().nullable(),
  faxNumber: z.string().optional().nullable(),
  infoUrl: z.string().optional().nullable(),
  bookingUrl: z.string().optional().nullable(),
  additionalDetails: z.string().optional().nullable()
})

const bookingTimeSchema = z.object({
  time: z.number(),
  daysPrior: z.number()
})

const systemNoticeSchema = z.object({
  tag: z.string(),
  text: z.string()
})

const plannerErrorSchema = z.object({
  id: z.number(),
  msg: z.string(),
  message: messageSchema,
  missing: z.array(z.string()).optional().nullable()
})

const debugOutputSchema = z.object({
  precalculationTime: z.number().optional().nullable(),
  directStreetRouterTime: z.number().optional().nullable(),
  transitRouterTime: z.number().optional().nullable(),
  filteringTime: z.number().optional().nullable(),
  renderingTime: z.number().optional().nullable(),
  totalTime: z.number().optional().nullable(),
  transitRouterTimes: transitTimingOutputSchema.optional().nullable()
})

const vehicleParkingSchema = z.object({
  id: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  entranceId: z.string().optional().nullable(),
  entranceName: z.string().optional().nullable(),
  detailsUrl: z.string().optional().nullable(),
  imageUrl: z.string().optional().nullable(),
  note: z.string().optional().nullable(),
  tags: z.array(z.string()).optional().nullable(),
  hasBicyclePlaces: z.boolean().optional().nullable(),
  hasAnyCarPlaces: z.boolean().optional().nullable(),
  hasCarPlaces: z.boolean().optional().nullable(),
  hasWheelchairAccessibleCarPlaces: z.boolean().optional().nullable(),
  capacity: parkingSpaceSchema.optional().nullable(),
  availability: parkingSpaceSchema.optional().nullable(),
  realtime: z.boolean().optional().nullable(),
})

const stepSchema = z.object({
  distance: z.number().optional().nullable(),
  relativeDirection: relativeDirectionSchema.optional().nullable(),
  streetName: z.string().optional().nullable(),
  absoluteDirection: absoluteDiretionSchema.optional().nullable(),
  exit: z.string().optional().nullable(),
  stayOn: z.boolean().optional().nullable(),
  area: z.boolean().optional().nullable(),
  bogusName: z.boolean().optional().nullable(),
  lon: z.number().optional().nullable(),
  lat: z.number().optional().nullable(),
  elevation: z.string().optional().nullable(),
  walkingBike: z.boolean().optional().nullable(),
  alerts: z.array(alertSchema).optional().nullable()
})

const bookingInfoSchema = z.object({
  contactInfo: contactInfoSchema.optional().nullable(),
  bookingMethods: z.array(z.string()).optional().nullable(),
  earliestBookingTime: bookingTimeSchema.optional().nullable(),
  latestBookingTime: bookingTimeSchema.optional().nullable(),
  minimumBookingNoticeSeconds: z.number().optional().nullable(),
  maximumBookingNoticeSeconds: z.number().optional().nullable(),
  message: z.string().optional().nullable(),
  pickupMessage: z.string().optional().nullable(),
  dropOffMessage: z.string().optional().nullable()
})

export const placeSchema = z.object({
  name: z.string().optional().nullable(),
  stopId: z.string().optional().nullable(),
  stopCode: z.string().optional().nullable(),
  platformCode: z.string().optional().nullable(),
  lon: z.number().optional().nullable(),
  lat: z.number().optional().nullable(),
  arrival: z.number().optional().nullable(),
  departure: z.number().optional().nullable(),
  zoneId: z.string().optional().nullable(),
  stopIndex: z.number().optional().nullable(),
  stopSequence: z.number().optional().nullable(),
  vertexType: vertexTypeSchema.optional().nullable(),
  bikeShareId: z.string().optional().nullable(),
  networks: z.array(z.string()).optional().nullable(),
  vehicleParking: vehicleParkingSchema.optional().nullable()
})

const legSchema = z.object({
  startTime: z.number(),
  endTime: z.number(),
  departureDelay: z.number().optional().nullable(),
  arrivalDelay: z.number().optional().nullable(),
  realTime: z.boolean().optional().nullable(),
  isNonExactFrequency: z.boolean().optional().nullable(),
  headway: z.number().optional().nullable(),
  distance: z.number().optional().nullable(),
  generalizedCost: z.number().optional().nullable(),
  pathway: z.boolean().optional().nullable(),
  mode: z.string().optional().nullable(),
  transitLeg: z.boolean().optional().nullable(),
  route: z.string().optional().nullable(),
  agencyName: z.string().optional().nullable(),
  agencyUrl: z.string().optional().nullable(),
  agencyBrandingUrl: z.string().optional().nullable(),
  agencyTimeZoneOffset: z.number().optional().nullable(),
  routeColor: z.string().optional().nullable(),
  routeType: z.number().optional().nullable(),
  routeId: z.string().optional().nullable(),
  routeTextColor: z.string().optional().nullable(),
  interlineWithPreviousLeg: z.boolean().optional().nullable(),
  tripShortName: z.string().optional().nullable(),
  tripBlockId: z.string().optional().nullable(),
  headsign: z.string().optional().nullable(),
  agencyId: z.string().optional().nullable(),
  tripId: z.string().optional().nullable(),
  serviceDate: z.string().optional().nullable(),
  routeBrandingUrl: z.string().optional().nullable(),
  from: placeSchema,
  to: placeSchema,
  intermediateStops: z.array(placeSchema).optional().nullable(),
  legGeometry: geometrySchema.optional().nullable(),
  legElevation: z.string().optional().nullable(),
  steps: z.array(stepSchema).optional().nullable(),
  alerts: z.array(alertSchema).optional().nullable(),
  routeShortName: z.string().optional().nullable(),
  routeLongName: z.string().optional().nullable(),
  boardRule: z.string().optional().nullable(),
  alightRule: z.string().optional().nullable(),
  pickupBookingInfo: bookingInfoSchema.optional().nullable(),
  dropOffBookingInfo: bookingInfoSchema.optional().nullable(),
  rentedBike: z.boolean().optional().nullable(),
  accessibilityScore: z.number().optional().nullable(),
  duration: z.number().optional().nullable()
})

const itinerarySchema = z.object({
  duration: z.number().optional().nullable(),
  startTime: z.number(),
  endTime: z.number(),
  walkTime: z.number().optional().nullable(),
  transitTime: z.number().optional().nullable(),
  waitingTime: z.number().optional().nullable(),
  walkDistance: z.number().optional().nullable(),
  walkLimitExceeded: z.boolean().optional().nullable(),
  generalizedCost: z.number().optional().nullable(),
  elevationLost: z.number().optional().nullable(),
  elevationGained: z.number().optional().nullable(),
  transfers: z.number().optional().nullable(),
  fare: fareSchema.optional().nullable(),
  legs: z.array(legSchema).optional().nullable(),
  systemNotices: z.array(systemNoticeSchema).optional().nullable(),
  tooSloped: z.boolean().optional().nullable(),
  arrivedAtDestinationWithRentedBicycle: z.boolean().optional().nullable(),
  accessibilityScore: z.number().optional().nullable()
})

const planSchema = z.object({
  date: z.number(),
  from: placeSchema,
  to: placeSchema,
  itineraries: z.array(itinerarySchema)
})

/**
 * Plan response schema to be used to validate rest resopnses from OTP REST requests to __/otp/routers/{ignoreRouterId}/plan__
 */
export const planResponseSchema = z.object({
  requestParameters: z.unknown(),
  debugOutput: debugOutputSchema.optional().nullable(),
  elevationMetadata: elevationMetadataSchema.optional().nullable(),
  plan: planSchema.optional().nullable(),
  error: plannerErrorSchema.optional().nullable(),
  previousPageCursor: z.string().optional().nullable(),
  nextPageCursor: z.string().optional().nullable(),
  metadata: apiTripSearchMetadataSchema.optional().nullable()
})

export type PlanResponseSchema = z.infer<typeof planResponseSchema>
export type PlanLegSchema = z.infer<typeof legSchema>
export type PlanItinerarySchema = z.infer<typeof itinerarySchema>
export type PlanStepSchema = z.infer<typeof stepSchema>
export type PlanPlaceSchema = z.infer<typeof placeSchema>