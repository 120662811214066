import { cn } from "@/lib/utils";
import { ComponentClassName } from "@/types/utility";


export function ContentWrapper({ className, eventsCaptureWrapperClassName, children }: ComponentClassName<{ eventsCaptureWrapperClassName?: string}>) {
    return (
        <div className={cn(
            "duration-1000 animate-in fade-in",
            className
        )}>
            <div className={cn("pointer-events-auto", eventsCaptureWrapperClassName)}>
                {children}
            </div>
        </div>
    )
}
