import { Map } from "@/components/map"

export function App() {
  return (
    <AppInternal />
  )
}

function AppInternal() {
  return (
    <>
      <Map className={"animate-in fade-in-0 duration-1000"} />
    </>
  )
}