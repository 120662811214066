import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/utils"
import { focusRing } from "../shared/component-state"

const Tabs = TabsPrimitive.Root

const tabsListVariants = cva(
  "w-full flex justify-start !rounded-none bg-transparent p-0",
  {
    variants: {
      variant: {
        outline: "flex !border-b space-x-4",
        block: "inline-flex !rounded-md bg-muted p-1 text-muted-foreground",
      },
    },
    defaultVariants: {
      variant: "outline",
    },
  }
)
const tabsTriggerVariants = cva(
  "relative !rounded-none bg-transparent px-4 py-2 text-default text-muted-foreground hover:text-foreground shadow-none transition data-[state=active]:shadow-none",
  {
    variants: {
      variant: {
        outline: "border-b-2 border-b-transparent data-[state=active]:border-b-primary/90 data-[state=active]:text-primary/90 hover:border-b-border",
        block: "inline-flex items-center justify-center whitespace-nowrap !rounded-sm px-3 py-1.5 text-sm font-medium transition-all disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm"
      },
    },
    defaultVariants: {
      variant: "outline",
    },
  }
)
interface TabsListProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>,
    VariantProps<typeof tabsListVariants> {}

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  TabsListProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn("tablist-root", tabsListVariants({ variant, className }))}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

interface TabsTriggerProps
  extends React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>,
    VariantProps<typeof tabsTriggerVariants> {}

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  TabsTriggerProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn("tab-root", tabsTriggerVariants({ variant, className }), focusRing)}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName


const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "w-full mt-5 animate-in fade-in slide-in-from-bottom-2 duration-200",
      focusRing,
      "ring-black/0 focus-visible:ring-black border-black/0 focus-visible:border-black/0",
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }