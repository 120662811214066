import { getLatLng } from "@/lib/utils"

import "@elfalem/leaflet-curve"

import { useEffect, useState } from "react"
import { CurveLatLngExpression } from "@elfalem/leaflet-curve"
import L, { curve, CurveOptions, LatLngExpression, LatLngTuple } from "leaflet"
import { useMap } from "react-leaflet"

type CurveProps = CurveOptions & {
  start?: LatLngExpression
  end?: LatLngExpression
}

export function Curve({ start, end, ...rest }: CurveProps) {
  const map = useMap()
  const [layer] = useState(L.layerGroup())

  useEffect(() => {
    if (!layer) {
      return
    }

    layer.clearLayers()

    if (!start || !end) {
      return
    }

    const givenStart = getLatLng(start)
    const givenEnd = getLatLng(end)
    const curveStart: CurveLatLngExpression = givenEnd.lng <= givenStart.lng ? [givenEnd.lat, givenEnd.lng] : [givenStart.lat, givenStart.lng]
    const curveEnd: CurveLatLngExpression = givenEnd.lng <= givenStart.lng ? [givenStart.lat, givenStart.lng] : [givenEnd.lat, givenEnd.lng]

    const curveMidpoint = getCurveMidpoint(curveStart, curveEnd)

    const c = curve(
      [
        "M",
        curveStart,
        "Q",
        curveMidpoint,
        curveEnd,
      ],
      {
        ...rest
      }
    )

    layer.addLayer(c)
  }, [start, end, layer, rest])

  useEffect(() => {
    if (!map || !layer) {
      return
    }

    map.addLayer(layer)
    return () => {
      map.removeLayer(layer)
    }
  }, [map, layer])

  return <></>
}

function getCurveMidpoint(start: LatLngTuple, end: LatLngTuple): CurveLatLngExpression {
  const offsetX = end[1] - start[1];
  const offsetY = end[0] - start[0];

  const radius = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
  const theta = Math.atan2(offsetY, offsetX);

  const thetaOffset = 3.14 / 10;

  const radius2 = radius / 2 / Math.cos(thetaOffset);
  const theta2 = theta + thetaOffset;

  const midpointX = radius2 * Math.cos(theta2) + start[1];
  const midpointY = radius2 * Math.sin(theta2) + start[0];

  return [midpointY, midpointX];
}