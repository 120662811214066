import { Alert } from "@/components/ui/containers/alert";
import { Icons } from "@/components/ui/icons";
import Typography from "@/components/ui/typeography/typeography";
import { ItinerarySchema } from "@/services/otp/validations/planner.graphql";
import { Link } from "@tanstack/react-router";

export function ItineraryDetailsFooter({ legs }: ItinerarySchema) {

    return (
        <Alert
            variant={"default"}
        >
            <span className="flex flex-col gap-2 text-md mb-4">
                <Typography variant={"h3"}>How to Book</Typography>

                <AgencyCallouts legs={legs} />
            </span>
            <span className="flex flex-col gap-2 text-md mb-4">
                <Typography variant={"h3"}>Trip Details</Typography>

                <BookingCallouts legs={legs} />
            </span>
        </Alert>
    )
}

function BookingCallouts({ legs }: Pick<ItinerarySchema, "legs">) {
    const match = legs?.find(l => l.pickupBookingInfo ?? l.dropOffBookingInfo)
    const bookingInfo = match?.pickupBookingInfo ?? match?.dropOffBookingInfo;
    if (!bookingInfo) {
        return;
    }

    return (
        <>
            <div className="flex flex-row gap-3 items-start">
                <span>
                    <Icons.itinerary.indicator.booking />
                </span>
                <span>{bookingInfo.message}</span>
            </div>
        </>
    )
}

function AgencyCallouts({ legs }: Pick<ItinerarySchema, "legs">) {
    const match = legs?.find(l => l.agency)
    if (!match || !match.agency) {
        return;
    }

    return (
        <>
            <div className="flex flex-row gap-3 items-center">
                <span>
                    <Icons.itinerary.indicator.phone />
                </span>
                <span>
                    <a
                        className="hover:underline"
                        href={`tel:${match.agency.phone}`}
                    >
                        {match.agency.phone}
                    </a>
                    <span className="sr-only">Call Agency at ${match.agency.phone}</span>
                </span>
            </div>
            <div className="flex flex-row gap-3 items-center">
                <span>
                    <Icons.itinerary.indicator.agencyUrl />
                </span>
                <span>
                    <Link
                        target="_blank"
                        className="hover:underline"
                        to={match.agency.fareUrl ?? undefined}
                    >
                        {match.agency.fareUrl}
                    </Link>
                </span>
            </div>
        </>
    )
}