import { z } from "zod"
import { bikesAllowedSchema, graphqlResponse, idSchema, modeSchema, patternSchema, stopSchema } from "./base.grpahql"
import { agencySchema } from "./agency.graphql"
import { alertSchema } from "./errors.graphql"

export const routeSchema = z.object({
    id: idSchema.optional(),
    gtfsId: z.string().optional(),
    agency: agencySchema.optional().nullable(),
    shortName: z.string().optional().nullable(),
    longName: z.string().optional().nullable(),
    mode: modeSchema.optional().nullable(),
    type: z.number().optional().nullable(),
    desc: z.string().optional().nullable(),
    url: z.string().url().optional().nullable(),
    color: z.string().optional().nullable(),
    textColor: z.string().optional().nullable(),
    bikeAllowed: bikesAllowedSchema.optional().nullable(),
    pattern: z.array(patternSchema).optional().nullable(),
    stops: z.array(stopSchema).optional().nullable(),
    /**
     * @see tripSchema
     */
    trips: z.array(z.unknown()).optional().nullable(),
    alerts: z.array(alertSchema).optional().nullable(),
    sortOrder: z.string().optional().nullable(),
})

export const routeResponse = z.object({
    routes: z.array(routeSchema).optional().nullable()
})

export const routesGraphqlResponse = graphqlResponse(
    routeResponse,
)

export type RoutesGraphqlResponse = z.infer<typeof routesGraphqlResponse>
export type RoutesResponse = z.infer<typeof routeResponse>
export type RouteSchema = z.infer<typeof routeSchema>