import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { MenuItem, MenuItemProps } from "@/components/ui/primitives/menu-item";

export function ClearMarkersItem({ variant }: MenuItemProps) {
    const { clear } = useSetAppSearch()

    return (
        <MenuItem
            variant={variant}
            onSelect={() => {
                clear()
            }}
        >
            Clear Markers
        </MenuItem>
    )
}