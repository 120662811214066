import { useSetAppSearch } from "@/components/routes/app-route.hooks"
import { Button } from "@/components/ui/form/button"
import { usePlanner } from "@/hooks/use-planner"
import { cn } from "@/lib/utils"
import { InjectMapProps } from "@/types/leaflet"
import { ComponentClassName } from "@/types/utility"
import { Dot, NotebookPen } from "lucide-react"

export function OpenPlannerButton({ className, map }: ComponentClassName<InjectMapProps>) {
    const { setPlanModel } = useSetAppSearch(map)

    return (
        <>
            <Button
                id="place"
                size={"icon"}
                variant={"outline"}
                onClick={() => {
                    setPlanModel(true)
                }}
                className={cn(
                    "relative grid place-items-center h-full w-16",
                    "!rounded-none border-r-transparent duration-500 overflow-visible",
                    className
                )}
            >
                <NotebookPen className="w-4 h-4" />
                <OpenPlannerIndicator />
            </Button>
        </>
    )
}

function OpenPlannerIndicator() {
    const { error, hasValidQuery } = usePlanner()

    if (error) {
        return (
            <>
                <Dot className="absolute pointer-events-none animate-ping duration-1000 w-20 h-20 stroke-red-500 dark:stroke-red-700 -top-[1.9rem] -right-[1.9rem]" />
                <Dot className="absolute pointer-events-none w-20 h-20 stroke-red-500 dark:stroke-red-700 -top-[1.9rem] -right-[1.9rem]" />
            </>
        )
    }

    if (!hasValidQuery) {
        return;
    }

    return (
        <Dot className="absolute pointer-events-none w-20 h-20 stroke-blue-500 dark:stroke-blue-700 -top-[1.9rem] -right-[1.9rem]" />
    )
}