import {
  GetPlaceCommand,
  SearchPlaceIndexForSuggestionsCommand,
  type GetPlaceCommandInput,
  type GetPlaceCommandOutput,
  type SearchPlaceIndexForSuggestionsCommandInput,
  type SearchPlaceIndexForSuggestionsCommandOutput,

  
  SearchPlaceIndexForPositionCommand,
  type SearchPlaceIndexForPositionCommandInput,
  type SearchPlaceIndexForPositionCommandOutput,
} from "@aws-sdk/client-location"

import { env } from "@/env.setup"
import { aws } from "."

const index = env.VITE_AWS_PLACES_INDEX_NAME

export const query = {
  suggest: suggest,
  getPlace: getPlace,
  searchPlaceForPosition: searchPlaceForPosition,
} as const

type FindAddressCandidatesProps = Omit<GetPlaceCommandInput, "IndexName">
async function getPlace({
  Language,
  ...props
}: FindAddressCandidatesProps): Promise<GetPlaceCommandOutput> {
  const command = new GetPlaceCommand({
    IndexName: index,
    Language: Language ?? "en",
    ...props,
  })

  return aws.client.send(command)
}

type SuggestProps = Omit<
  SearchPlaceIndexForSuggestionsCommandInput,
  "IndexName"
>

async function suggest({
  FilterCategories,
  FilterCountries,
  Language,
  MaxResults,
  ...props
}: SuggestProps): Promise<SearchPlaceIndexForSuggestionsCommandOutput> {
  const command = new SearchPlaceIndexForSuggestionsCommand({
    IndexName: index,
    FilterCategories: FilterCategories ?? undefined,
    FilterCountries: FilterCountries ?? ["USA"],
    Language: Language ?? "en",
    MaxResults: MaxResults ?? 5,
    ...props,
  })

  return aws.client.send(command)
}

type GetPlaceForPositionProps = Omit<SearchPlaceIndexForPositionCommandInput, "IndexName">
async function searchPlaceForPosition({
  Language,
  ...props
}: GetPlaceForPositionProps): Promise<SearchPlaceIndexForPositionCommandOutput> {
  const command = new SearchPlaceIndexForPositionCommand({
    IndexName: index,
    Language: Language ?? "en",
    ...props,
  })

  return aws.client.send(command)
}