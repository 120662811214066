import { format } from "date-fns"

export function dateToDayAndYearFormatter(dateTime?: string | number | Date | null) {
    if (!dateTime) {
        return 'N/A'
    }

    return format(dateTime, "MMMM do',' yyyy")
}

export function dateToHoursAndMinutesFormatter(dateTime?: string | number | Date | null, options = { seconds: false }) {
    if (!dateTime) {
        return 'N/A'
    }

    const formatter = options?.seconds
        ? "hh:mm:ss aa"
        : "hh:mm aa"

    return format(dateTime, formatter)
}

export function dateToFullMonthDayYearFormatter(dateTime?: string | number | Date | null) {
    if (!dateTime) {
        return 'N/A'
    }

    return format(dateTime, "eeee, MMMM do',' yyyy")
}
