import { ReactNode } from "@tanstack/react-router"
import { appRoute } from "../../routes/app-route";
import { cn } from "@/lib/utils";
import { ContentWrapper } from "./content-wrapper";
import { MobileSafeDiv } from "@/components/ui/containers/mobile-safe-div";
import { ComponentClassName } from "@/types/utility";

type DynamicLayoutProps = Omit<ComponentClassName, "children"> & {
    children: {
        search: ReactNode,
        card: ReactNode,
        action: ReactNode,
        secondaryAction: ReactNode,
        help: ReactNode,
    },
    eventsCaptureWrapperClassName?: string
}

export function DynamicLayout({ children, className, eventsCaptureWrapperClassName }: DynamicLayoutProps) {
    const { action, secondaryAction, search, card, help } = children;
    const data = appRoute.useSearch({ select: (search) => search.data })

    return (
        <MobileSafeDiv className={cn(
            "pointer-events-none items-stretch justify-stretch absolute w-full grid grid-cols-1 gap-4 p-2 sm:p-4 transition-all duration-500 z-10",
            data?.open ? "md:grid-cols-[450px,_minmax(400px,_1fr)] 2xl:grid-cols-[550px,_minmax(400px,_1fr)]" : "md:grid-cols-[0px,_minmax(400px,_1fr)]",
            className
        )}>
            {/** Card */}
            <ContentWrapper
                eventsCaptureWrapperClassName={cn(
                    "h-full",
                    data?.open ? "" : "lg:pointer-events-none",
                    eventsCaptureWrapperClassName
                )}
                className={cn(
                    "hidden md:block slide-in-from-bottom-8 sm:slide-in-from-bottom-0 sm:slide-in-from-left-32 duration-300 overflow-hidden",
                    data?.open ? "opacity-100" : "opacity-100 lg:opacity-0"
                )}
            >
                {card}
            </ContentWrapper>

            <div
                className={cn(
                    "grid gap-8",
                    data?.open ? "grid-cols-[0px,_minmax(300px,_1fr),_auto]" : "grid-cols-[auto,_minmax(300px,_1fr),_auto]"
                )}
            >
                {/** Secondary Action : Planner Closed */}
                <ContentWrapper
                    eventsCaptureWrapperClassName={cn(
                        data?.open ? "pointer-events-none" : "",
                        eventsCaptureWrapperClassName

                    )}
                    className={cn(
                        "hidden lg:block duration-100 place-self-start",
                        data?.open ? "opacity-0" : "opacity-100"
                    )}
                >
                    {secondaryAction}
                </ContentWrapper>

                {/** Search */}
                <ContentWrapper
                    eventsCaptureWrapperClassName={eventsCaptureWrapperClassName}
                    className={cn(
                        "col-span-3 sm:col-span-2 lg:col-span-1",
                    )}
                >
                    {search}
                </ContentWrapper>

                {/** Help */}
                <ContentWrapper
                    eventsCaptureWrapperClassName={eventsCaptureWrapperClassName}
                    className={cn(
                        "hidden sm:block duration-100"
                    )}
                >
                    {help}
                </ContentWrapper>

                <div className="col-span-3 grid grid-cols-2">
                    {/** Secondary Action : Planner Open */}
                    <ContentWrapper
                        eventsCaptureWrapperClassName={cn(
                            data?.open ? "" : "lg:pointer-events-none",
                            eventsCaptureWrapperClassName

                        )}
                        className={cn(
                            "self-end justify-self-start block duration-100",
                            data?.open ? "opacity-100" : "opacity-100 lg:opacity-0",
                        )}
                    >
                        {secondaryAction}
                    </ContentWrapper>

                    {/** Action */}
                    <ContentWrapper
                        eventsCaptureWrapperClassName={cn(
                            data?.open ? "md:pointer-events-none" : "",
                            eventsCaptureWrapperClassName

                        )}
                        className={cn(
                            "place-self-end",
                            data?.open ? "opacity-100 md:opacity-0" : "opacity-100"
                        )}
                    >
                        {action}
                    </ContentWrapper>

                </div>


            </div>
        </MobileSafeDiv>
    )
}
