"use client"

import React from "react"
import { type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"
import { Icons } from "@/components/ui/icons"
import { focusRing } from "@/components/ui/shared/component-state"

import { inputVariants } from "./input-variants"

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, hasError, type, ...props }: InputProps, forwardedRef) => {
    const [typeState, setTypeState] = React.useState(type)

    const isPassword = type === "password"
    const isSearch = type === "search"

    return (
      <div className="relative w-full focus-within:z-10">
        <input
          ref={forwardedRef}
          type={isPassword ? typeState : type}
          className={cn(inputVariants({ hasError, className }), {
            "pl-8": isSearch,
            "pr-10": isPassword,
          })}
          {...props}
        />
        {isSearch && (
          <div
            className={cn(
              // base
              "pointer-events-none absolute bottom-0 left-2 flex h-full items-center justify-center",
              // text color
              "text-gray-400 dark:text-gray-600"
            )}
          >
            <Icons.input.search
              className="size-[1.125rem] shrink-0"
              aria-hidden="true"
            />
          </div>
        )}
        {isPassword && (
          <div
            className={cn(
              "absolute bottom-0 right-0 flex h-full items-center justify-center px-3"
            )}
          >
            <button
              aria-label="Change password visibility"
              className={cn(
                // base
                "h-fit w-fit rounded-sm outline-none transition-all",
                // text
                "text-gray-400  dark:text-gray-600",
                // hover
                "hover:text-gray-500 hover:dark:text-gray-500",
                focusRing
              )}
              type="button"
              onClick={() => {
                setTypeState(typeState === "password" ? "text" : "password")
              }}
            >
              <span className="sr-only">
                {typeState === "password" ? "Show password" : "Hide password"}
              </span>
              {typeState === "password" ? (
                <Icons.input.passwordHidden
                  aria-hidden="true"
                  className="size-5 shrink-0"
                />
              ) : (
                <Icons.input.passwordVisible
                  aria-hidden="true"
                  className="size-5 shrink-0"
                />
              )}
            </button>
          </div>
        )}
      </div>
    )
  }
)

Input.displayName = "Input"

export { Input, type InputProps }
