import { Icons } from "@/components/ui/icons";
import { CardMessageWrapper } from "./itineraries-state.contianer";

export function ItineraryCardLoading() {
    return <CardMessageWrapper
        className="animate-pulse"
        icon={<Icons.plan.loading />}
    >
        We are currently working on finding you the best routes. Hold on while we make some calculations!
    </CardMessageWrapper>
}
