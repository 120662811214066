import { ReactNode, useEffect, useState } from "react";
import { LatLngBounds, LatLngTuple, LeafletEvent, Map } from "leaflet";
import { useMap, useMapEvents } from "react-leaflet";
import { useSetAppSearch } from "../routes/app-route.hooks";
import { appRoute } from "../routes/app-route";
import { useMediaQuery } from "@/hooks/use-media-query";

const mobile_southWestMichigan = [42.65012181368025, -86.02380469847343] as const satisfies LatLngTuple
const mobile_northEastMichigan = [45.02695045318546, -83.48856310970515] as const satisfies LatLngTuple

const southWestMichigan = [43.78931128584271, -86.42944335937501] as const satisfies LatLngTuple
const northEastMichigan = [45.396505357545486, -83.32305908203126] as const satisfies LatLngTuple
/**
 * Represents the bounding box containing all of the available regions
 * of Michigan (matched expected OTP Regions bounding box).
 */
const MICHIGAN_BBOX = new LatLngBounds(southWestMichigan, northEastMichigan)
/**
 * Represents the bounding box containing all of the available regions
 * of Michigan (matched expected OTP Regions bounding box).
 */
const MOBILE_MICHIGAN_BBOX = new LatLngBounds(mobile_southWestMichigan, mobile_northEastMichigan)


export function MapCamera(): ReactNode {
    return (
        <>
            <Set />
            <Sync />
        </>
    );
}

/**
 * Sets map search param whenever movement on map ends
 * @returns React Component 
 */
function Set(): ReactNode {
    const { set } = useSetAppSearch()
    const map = useMapEvents({
        moveend: (e) => setMapData(e)
    })

    const setMapData = (_e: LeafletEvent) => {
        set({
            m: getAtParam(map)
        },
            {
                replace: true,
                resetScroll: false
            }
        )
    }

    return undefined;
}

/**
 * Ensures map view matches route search params.
 * 
 * __*If no search param is given, uses bounding box of County Geojson*__
 * @returns React Component 
 */
function Sync(): ReactNode {
    const mapParams = appRoute.useSearch({ select: (search) => search.m })
    const isMobile = useMediaQuery("(max-width: 640px)")
    const [initialLocation] = useState(mapParams);
    const map = useMap()

    useEffect(() => {
        if (!map) {
            return;
        }

        if (initialLocation) {
            const split = initialLocation.split(atParamDelimiter)
            const lat = split[0]
            const lng = split[1]
            const zoom = split[2]
            if (!lat || !lng || !zoom) {
                return;
            }


            map.setView({ lat: +lat, lng: +lng }, +zoom)
        } else if (isMobile) {
            map.fitBounds(MOBILE_MICHIGAN_BBOX, { animate: true })
        } else {
            map.fitBounds(MICHIGAN_BBOX, { animate: true })
        }
    }, [map, initialLocation, isMobile])

    return undefined;
}

const atParamDelimiter = ","
function getAtParam(map: Map) {
    const center = map.getCenter()
    const zoom = map.getZoom()
    return `${center.lat.toFixed(7)}${atParamDelimiter}${center.lng.toFixed(7)}${atParamDelimiter}${zoom.toFixed(0)}`
}