import { cn } from "@/lib/utils"
import { type VariantProps } from "class-variance-authority"
import React from "react"
import { typographyVariants } from "./typeography-variants"

export interface TypographyProps
  extends Omit<React.HTMLAttributes<HTMLHeadingElement>, "color">,
    VariantProps<typeof typographyVariants> {}

const Typography = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ className, variant, affects, color, override, ...props }, ref) => {
    const Comp = variant || "p"
    return (
      <Comp
        className={cn(typographyVariants({ variant, affects, color, override, className }))}
        ref={ref}
        {...props}
      />
    )
  },
)
Typography.displayName = "Typeography"

export default Typography
