import { tryGetPlace } from "@/lib/places";
import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { useCallback } from "react";
import { AppPlace } from "@/components/routes/app-route.zod";
import { MenuItem } from "@/components/ui/primitives/menu-item";
import { SetPlaceItemContent, SetPlaceItemProps } from "./set-place-item.content";

export function SetPlaceItemStatic({ map, location, variant, contextOpenedAt }: SetPlaceItemProps) {
    const { setFrom, setTo } = useSetAppSearch(map)
    const setLocation = useCallback(
        (place: AppPlace | undefined) => {
            return location === "from"
                ? setFrom(place)
                : setTo(place)
        },
        [location, setFrom, setTo]
    )

    return (
        <>
            <MenuItem
                id="set_place_static"
                variant={variant}
                className="gap-4"
                onSelect={async () => {
                    if (!contextOpenedAt) {
                        return;
                    }

                    const potentialPlace = await tryGetPlace(contextOpenedAt);

                    setLocation(potentialPlace)
                }}>
                <SetPlaceItemContent location={location} />
            </MenuItem>

        </>
    )
}
