import { clsx, type ClassValue } from "clsx"
import { LatLngExpression } from "leaflet"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function getLatLng(latlng: LatLngExpression) {
  const formatted = Array.isArray(latlng)
    ? { lat: latlng[0], lng: latlng[1], alt: latlng[0] }
    : latlng

  return formatted
}
