import { z } from "zod";
import { alertSchema } from "./errors.graphql";

export function graphqlResponse<TData extends z.ZodRawShape>(data: z.ZodObject<TData>) {
    return z.object({
        data: data.optional(),
        error: z.unknown(),
    })
}

export const idSchema = z.string();

/**
 * @see https://geojson.org/
 */
export const geoJsonSchema = z.unknown()

export const realtimeStateSchema = z.union([
    z.literal("SCHEDULED"),
    z.literal("UPDATED"),
    z.literal("CANCELED"),
    z.literal("ADDED"),
    z.literal("MODIFIED"),
])

export const pickupDropoffTypeSchema = z.union([
    z.literal("SCHEDULED"),
    z.literal("NONE"),
    z.literal("CALL_AGENCY"),
    z.literal("COORDINATE_WITH_DRIVER"),
])

export const modeSchema = z.union([
    z.literal("AIRPLANE"),
    z.literal("BUS"),
    z.literal("CABLE_CAR"),
    z.literal("COACH"),
    z.literal("FERRY"),
    z.literal("FUNICULAR"),
    z.literal("GONDOLA"),
    z.literal("RAIL"),
    z.literal("SUBWAY"),
    z.literal("TRAM"),
    z.literal("CARPOOL"),
    z.literal("TAXI"),
    z.literal("TROLLEYBUS"),
    z.literal("MONORAIL"),
    z.literal("CAR"),
    z.literal("WALK"),
])

export const localTimeSpanSchema = z.object({
    from: z.number(),
    to: z.number(),
})

export const localTimeSpanDateSchema = z.object({
    timeSpans: z.array(localTimeSpanSchema).optional().nullable(),
    date: z.string(),
})

export const stopTimeSchema = z.object({
    /**
     * @see stopSchema
     */
    stop: z.unknown().optional().nullable(),
    stopPosition: z.number().optional().nullable(),
    scheduledArrival: z.number().optional().nullable(),
    realtimeArrival: z.number().optional().nullable(),
    arrivalDelay: z.number().optional().nullable(),
    scheduledDeparture: z.number().optional().nullable(),
    realtimeDeparture: z.number().optional().nullable(),
    departureDelay: z.number().optional().nullable(),
    timepoint: z.boolean().optional().nullable(),
    realtime: z.boolean().optional().nullable(),
    realtimeState: realtimeStateSchema.optional().nullable(),
    pickupType: pickupDropoffTypeSchema.optional().nullable(),
    dropoffType: pickupDropoffTypeSchema.optional().nullable(),
    serviceDay: z.number().optional().nullable(),
    /**
     * @see tripSchema
     */
    trip: z.unknown().optional().nullable(),
    headsign: z.string().optional().nullable(),
})



export const openingHoursSchema = z.object({
    osm: z.string().optional().nullable(),
    /**
     * @deprecated NOT IMPLEMENTED YET AND WILL ALWAYS RETURN NULL
     */
    dates: z.array(localTimeSpanDateSchema).optional().nullable(),
})

export const bikesAllowedSchema = z.union([
    z.literal("NO_INFORMATION"),
    z.literal("ALLOWED"),
    z.literal("NOT_ALLOWED"),
])

export const wheelchairBoardingSchema = z.union([
    z.literal("NO_INFORMATION"),
    z.literal("POSSIBLE"),
    z.literal("NOT_POSSIBLE"),
])

export const vehicleParkingSpacesSchema = z.object({
    bicycleSpaces: z.number().optional().nullable(),
    carSpaces: z.number().optional().nullable(),
    wheelchairAccessibleCarSpaces: z.number().optional().nullable(),
})

export const vehicleStopStatusSchema = z.union([
    z.literal("STOPPED_AT"),
    z.literal("IN_TRANSIT_TO"),
    z.literal("INCOMING_AT"),
])

export const vehicleParkingStateSchema = z.union([
    z.literal("OPERATIONAL"),
    z.literal("TEMPORARILY_CLOSED"),
    z.literal("CLOSED"),
])

export const vehicleParkingSchema = z.object({
    id: idSchema,
    vehicleParkingId: z.string().optional().nullable(),
    name: z.string(),
    realtime: z.boolean().optional().nullable(),
    lon: z.number().optional().nullable(),
    lat: z.number().optional().nullable(),
    detailsUrl: z.string().url().optional().nullable(),
    tags: z.array(z.string()).optional().nullable(),
    note: z.string().optional().nullable(),
    state: vehicleParkingStateSchema.optional().nullable(),
    bicyclePlaces: z.boolean().optional().nullable(),
    anyCarPlaces: z.boolean().optional().nullable(),
    carPlaces: z.boolean().optional().nullable(),
    wheelchairAccessibleCarPlaces: z.boolean().optional().nullable(),
    capacity: vehicleParkingSpacesSchema.optional().nullable(),
    availability: vehicleParkingSpacesSchema.optional().nullable(),
    openingHours: openingHoursSchema.optional().nullable(),
})


export const vehiclePositionSchema = z.object({
    vehicleId: z.string().optional().nullable(),
    label: z.string().optional().nullable(),
    lat: z.number().optional().nullable(),
    lon: z.number().optional().nullable(),
    stopRelationship: z.number().optional().nullable(),
    speed: z.number().optional().nullable(),
    heading: z.number().optional().nullable(),
    lastUpdated: z.number().optional().nullable(),
    /**
     * @see tripSchema
     */
    trip: z.unknown().optional().nullable(),
})

export const offsetDateTimeSchema = z.string().datetime();
export const durationSchema = z.string().duration();

export const realTimeEstimateSchema = z.object({
    time: offsetDateTimeSchema.optional(),
    delay: durationSchema.optional(),
})

export const legTimeSchema = z.object({
    scheduledTime: offsetDateTimeSchema.optional(),
    estimated: realTimeEstimateSchema.optional().nullable(),
})

export const occupancyStatusSchema = z.union([
    z.literal("NO_DATA_AVAILABLE"),
    z.literal("EMPTY"),
    z.literal("MANY_SEATS_AVAILABLE"),
    z.literal("FEW_SEATS_AVAILABLE"),
    z.literal("STANDING_ROOM_ONLY"),
    z.literal("CRUSHED_STANDING_ROOM_ONLY"),
    z.literal("FULL"),
    z.literal("NOT_ACCEPTING_PASSENGERS"),
])

export const tripOccupancySchema = z.object({
    occupancyStatus: occupancyStatusSchema.optional().nullable(),
})

/**
 * Encoded Polyline.
 * @see https://developers.google.com/maps/documentation/utilities/polylinealgorithm
 */
export const polylineSchema = z.string()

export const coordinatesSchema = z.object({
    lat: z.number().optional().nullable(),
    lon: z.number().optional().nullable(),
})

export const geometrySchema = z.object({
    length: z.number().optional().nullable(),
    points: polylineSchema.optional().nullable(),
})

export const stopGeometriesSchema = z.object({
    geoJson: geoJsonSchema.optional().nullable(),
    googleEncoded: z.array(geometrySchema).optional().nullable()
})

export const locationTypeSchema = z.union([
    z.literal("STOP"),
    z.literal("STATION"),
    z.literal("ENTRANCE"),
])

export const clusterSchema = z.object({
    id: idSchema.optional(),
    gtfsId: z.string().optional(),
    name: z.string().optional(),
    lat: z.number().optional(),
    lon: z.number().optional(),
    /**
     * @see stopSchema
     */
    stop: z.unknown().optional().nullable(),
})

export const stopAtDistanceSchema = z.object({
    id: idSchema.optional(),
    /**
     * @see stopSchema
     */
    stop: z.unknown().optional().nullable(),
    distance: z.number().optional().nullable(),
})

export const stoptimesInPatternSchema = z.object({
    /**
     * @see patternSchema
     */
    pattern: z.unknown().optional().nullable(),
    stoptimes: z.array(stopTimeSchema).optional().nullable(),
})

export const positionBetweenStopsSchema = z.object({
    previousPosition: z.number().optional().nullable(),
    nextPosition: z.number().optional().nullable(),
})
export const positionAtStopSchema = z.object({
    position: z.number().optional().nullable(),
})

export const stopPositionSchema = z.union([
    positionAtStopSchema,
    positionBetweenStopsSchema,
])

export const stopSchema = z.object({
    id: idSchema.optional(),
    stopTimesForPattern: z.array(stopTimeSchema).optional().nullable(),
    gtfsId: z.string().optional(),
    name: z.string().optional(),
    lon: z.number().optional().nullable(),
    lat: z.number().optional().nullable(),
    geometries: stopGeometriesSchema.optional().nullable(),
    code: z.string().optional().nullable(),
    desc: z.string().optional().nullable(),
    zoneId: z.string().optional().nullable(),
    url: z.string().url().optional().nullable(),
    locationType: locationTypeSchema.optional().nullable(),
    /**
     * @see stopSchema
     */
    parentStation: z.unknown().optional().nullable(),
    wheelchairBoarding: wheelchairBoardingSchema.optional().nullable(),
    direction: z.string().optional().nullable(),
    timezone: z.string().optional().nullable(),
    vehicleType: z.number().optional().nullable(),
    vehicleMode: modeSchema.optional().nullable(),
    platformCode: z.string().optional().nullable(),
    cluster: clusterSchema.optional().nullable(),
    /**
     * @see stopSchema
     */
    stops: z.array(z.unknown()).optional().nullable(),
    /**
     * @see routeSchema
     */
    routes: z.array(z.unknown()).optional().nullable(),
    /**
     * @see patternSchema
     */
    patterns: z.array(z.unknown()).optional().nullable(),
    transfers: z.array(stopAtDistanceSchema).optional().nullable(),
    stoptimesForServiceDate: z.array(stoptimesInPatternSchema).optional().nullable(),
    stoptimesForPatterns: z.array(stoptimesInPatternSchema).optional().nullable(),
    stoptimesWithoutPatterns: z.array(stopTimeSchema).optional().nullable(),
    alerts: z.array(alertSchema).optional().nullable(),
})

export const stopRelationshipSchema = z.object({
    status: vehicleStopStatusSchema.optional(),
    stop: stopSchema.optional(),
})

export const patternSchema = z.object({
    id: idSchema.optional(),
    /**
     * @see routeSchema
     */
    route: z.unknown().optional(),
    directionId: z.number().optional().nullable(),
    name: z.string().optional().nullable(),
    code: z.string().optional(),
    headsign: z.string().optional().nullable(),
    /**
     * @see tripSchema
     */
    trips: z.array(z.unknown()).optional().nullable(),
    /**
     * @see tripSchema
     */
    tripsForDate: z.array(z.unknown()).optional().nullable(),
    /**
     * @see stopSchema
     */
    stops: z.array(z.unknown()).optional().nullable(),
    geometry: z.array(coordinatesSchema).optional().nullable(),
    patternGeometry: geometrySchema.optional().nullable(),
    alters: z.array(alertSchema).optional().nullable(),
    vehiclePositions: z.array(vehiclePositionSchema).optional().nullable(),
    /**
     * @see patternSchema
     */
    originalTripPattern: z.unknown().optional().nullable(),
})

export type ModeSchema = z.infer<typeof modeSchema>