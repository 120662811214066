import { Icons } from "@/components/ui/icons";
import { CardMessageWrapper } from "./itineraries-state.contianer";
import { AppItinerary } from "@/types/otp/+extended";
import Typography from "@/components/ui/typeography/typeography";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/containers/accordion";
import { ItineraryListContent } from "../itinerary-list/itinerary-list.content";

export function ItineraryCardNoRoutes({
    potentialFutureItineraries,
}: {
    potentialFutureItineraries?: AppItinerary[] | null
}) {
    return (
        <>
            <CardMessageWrapper
                className="mb-32"
                icon={<Icons.plan.noRoute />}
            >
                There are no routes between the selected locations with these settings. Please select again closer to the areas served by the pilot agencies or update the filter to generate different routes.

            </CardMessageWrapper>
            {potentialFutureItineraries && potentialFutureItineraries.length > 0 &&
                <>
                    <Typography variant={"h4"}>
                        Are you flexible with time?
                    </Typography>
                    <Accordion type="single" collapsible className="w-full">
                        <AccordionItem value="item-1">
                            <AccordionTrigger>Future plans</AccordionTrigger>
                            <AccordionContent>
                                <ItineraryListContent itineraries={potentialFutureItineraries} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </>
            }
        </>
    )
}

