import { Providers } from "../providers"
import { Toaster } from "@/components/ui/containers/toast"
import { SiteFooter } from "./site-footer"

import React from 'react';

export function BaseLayout({ children }: { children: React.ReactNode }) {
  return (
    <div vaul-drawer-wrapper="">
      <div className="relative flex min-h-[100dvh] max-h-[100dvh] w-full flex-col bg-background">
        <main className="grid flex-grow">
          <Providers>
            {children}
          </Providers>
        </main>

        <Toaster />
        <SiteFooter className="hidden md:block" />
      </div>
    </div>
  )
}
