import { useDropMarker } from "@/hooks/use-drop-marker";
import { tryGetPlace } from "@/lib/places";
import { LatLng, LeafletMouseEvent } from "leaflet";
import { useCallback, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useSetAppSearch } from "../routes/app-route.hooks";
import { AppPlace } from "../routes/app-route.zod";
import { RouteMarker } from "./map-markers";
import { MAP_ASSETS } from "@/config/assets";
import { useThrottle } from "@uidotdev/usehooks";

export function MapDropMarker() {
    const map = useMap()
    const [latlng, setLatLng] = useState<LatLng | undefined>()
    const throttledLatLng = useThrottle(latlng, 50);
    const [dropping, setDropping] = useDropMarker()
    const { setFrom, setTo } = useSetAppSearch(map)
    const setLocation = useCallback(
        (place: AppPlace | undefined) => {
            return dropping === "from"
                ? setFrom(place)
                : setTo(place)
        },
        [dropping, setFrom, setTo]
    )

    useEffect(() => {
        if (!map) {
            return
        }

        const keyPressFn = async (e: KeyboardEvent) => {
            if (dropping && e.code === 'Escape') {
                setDropping(undefined);
                return;
            }

            if (dropping) {
                return;
            }

            if (!e.ctrlKey || !e.shiftKey) {
                return;
            }

            if (e.code === 'KeyF') {
                setDropping('from')
                return;
            }

            if (e.code === 'KeyE') {
                setDropping('to')
                return;
            }
        }

        const dropPinFn = async (e: LeafletMouseEvent) => {
            if (!dropping) {
                return;
            }

            const potentialPlace = await tryGetPlace(e.latlng);

            setLocation(potentialPlace)

            setDropping(undefined)
            setLatLng(undefined)
        }

        const mouseOverFn = async (e: LeafletMouseEvent) => {
            setLatLng(e.latlng)
        }

        const unsubscribe = map.addOneTimeEventListener("click", dropPinFn)
        const unsubscribeMouse = map.addOneTimeEventListener("mousemove", mouseOverFn)
        document.addEventListener("keydown", keyPressFn)

        return () => {
            unsubscribe.removeEventListener("click", dropPinFn)
            unsubscribeMouse.removeEventListener("mousemove", mouseOverFn)
            document.removeEventListener("keydown", keyPressFn)
        }
    }, [dropping, map, setDropping, setFrom, setLocation, setTo])

    if (!dropping || !throttledLatLng) {
        return;
    }

    if (dropping === 'from') {
        return <RouteMarker
        marker={{
          position: throttledLatLng,
          icon: MAP_ASSETS.marker.start,
        }}
      />
    }

    if (dropping === 'to') {
        return <RouteMarker
        marker={{
          position: throttledLatLng,
          icon: MAP_ASSETS.marker.end,
        }}
      />
    }

    return undefined
}