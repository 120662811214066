import { appRoute } from "@/components/routes/app-route";
import { ItineraryDetails } from "./itinerary-details";
import { ItineraryList } from "./itinerary-list";
import { PlanOverview } from "./plan-overview";
import { ItinerariesFilter } from "./filters";
import { PlanOverviewRedirect } from "./plan-overview.redirect";
import { PropsWithChildren } from "react";
import { useMediaQuery } from "@/hooks/use-media-query";
import { Separator } from "@/components/ui/primitives/separator";
import { ScrollAreaNative } from "@/components/ui/containers/scroll-area.native";

export function ItinerariesContent({ children }: PropsWithChildren) {
    const isMobileOnlyLandscape = useMediaQuery("only screen and (max-height: 600px) and (orientation: landscape)");
    const { data: searchData } = appRoute.useSearch();
    if (searchData?._t === "t") {
        return (
            <>
                <PlanOverviewRedirect />

                <ScrollAreaNative>
                    <ItineraryDetails className="mr-3 ml-2 my-3" />
                    {children}
                </ScrollAreaNative>
            </>
        )
    }

    if (isMobileOnlyLandscape) {
        return (
            <>
                <PlanOverview />
                <ScrollAreaNative>
                    <ItinerariesFilter />

                    <Separator className="my-2 bg-transparent" />

                    <ItineraryList />
                    {children}
                </ScrollAreaNative>
            </>
        )
    }

    return (
        <>
            <PlanOverview />
            <ItinerariesFilter />

            <ScrollAreaNative>
                <ItineraryList />
                {children}
            </ScrollAreaNative>
        </>
    )
}
