import { appRoute } from "@/components/routes/app-route";
import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/containers/tabs";
import { Label } from "@/components/ui/form/label";
import { DateTimePicker } from "@/components/ui/form/time-picker/date-time-picker";
import { PlanOptionsExtended } from "./itineraries-filter.extended";
import { DEFAULT_APP_PLANNER_MODE } from "@/config/otp";

function INTERNAL__getDefaultTime() {
  const d = new Date();
  d.setHours(12, 0, 0, 0)
  return d
}

export function ItinerariesFilter() {
  const { set, options } = useOptionForm()

  const defaultValue = !options || options === "now" ? "now" : "plan";

  return (
    <div className="flex flex-col gap-3">
      <Tabs value={defaultValue}>
        <TabsList variant={"block"}>
          <TabsTrigger
            className="flex-1"
            variant={"block"}
            value="now"
            onClick={() => {
              set((prev) => {
                if (!prev?.data) {
                  return prev;
                }

                prev.data.opts = "now"

                return prev
              })
            }}
          >
            Now
          </TabsTrigger>
          <TabsTrigger
            className="flex-1"
            variant={"block"}
            value="plan"
            onClick={() => {
              set((prev) => {
                if (!prev?.data) {
                  return prev;
                }

                prev.data.opts = {
                  mode: DEFAULT_APP_PLANNER_MODE,
                  time: INTERNAL__getDefaultTime().toString(),
                }

                return prev
              })
            }}
          >
            Plan
          </TabsTrigger>
        </TabsList>

        <TabsContent value="now" className="mt-2">
          <PlanOptionsExtended />
        </TabsContent>

        <TabsContent value="plan" className="mt-2">
          <PlanOptionsExtended />
          <OptionForm />
        </TabsContent>
      </Tabs>

    </div>
  )
}

function useOptionForm() {
  const { set } = useSetAppSearch()

  const data = appRoute.useSearch({ select: (s) => s.data })

  return {
    set,
    options: data?.opts
  }
}

function OptionForm() {
  const { set, options } = useOptionForm();

  if (!options || options === "now") {
    return;
  }

  return (
    <form className="flex flex-col gap-3 mt-1">
      <fieldset>
        <Label htmlFor="plan-options" className="font-medium">
          Departure time
        </Label>
        <DateTimePicker
          selected={options.time ? new Date(options.time) : undefined}
          onSelect={(date) => {
            set((prev) => {
              if (!prev?.data?.opts || typeof prev.data.opts !== "object") {
                return prev;
              }

              const newDate = date ?? INTERNAL__getDefaultTime()
              prev.data.opts.time = newDate.toString();

              return prev
            }, {
              replace: true,
            });
          }}
        />
      </fieldset>
    </form>
  )
}
