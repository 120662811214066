import { appRoute } from "@/components/routes/app-route";
import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/containers/tool-tip";
import { Button } from "@/components/ui/form/button";
import { Icons } from "@/components/ui/icons";
import { MAP_ASSETS } from "@/config/assets";

/**
 * React Component to display a simple route on the itinerary details page.
 * Clicking the button will redirect the user back to the planning page.
 * 
 * @returns React Component
 */
export function PlanOverviewRedirect() {
    const from = appRoute.useSearch({ select: (search) => search.from })
    const to = appRoute.useSearch({ select: (search) => search.to })
    const { set } = useSetAppSearch()

    return (
        <Button
            variant={"outline"}
            className="flex flex-row gap-4 px-3 py-8 w-full"
            onClick={() => {
                set((prev) => {
                    if (!prev.data) {
                        return prev;
                    }

                    prev.data._t = "p"
                    return prev
                })
            }}
        >
            <div className="grid grid-cols-[1fr,_auto,_1fr] gap-3 items-center">
                <Tooltip>
                    <TooltipTrigger className="grid grid-cols-[auto,_1fr] items-center" asChild>
                        <div>
                            <img src={MAP_ASSETS.marker.start.iconUrl} className="aspect-square w-4 h-4" />
                            <span className="ml-2 text-nowrap text-start overflow-hidden text-ellipsis">
                                {from?.key}
                            </span>
                        </div>
                    </TooltipTrigger>

                    <TooltipContent>
                        {from?.key}
                    </TooltipContent>
                </Tooltip>

                <Icons.itinerary.transfer />

                <Tooltip>
                    <TooltipTrigger className="grid grid-cols-[auto,_1fr] items-center" asChild>
                        <div>
                            <img src={MAP_ASSETS.marker.end.iconUrl} width={16} />
                            <span className="ml-2 text-nowrap text-start overflow-hidden text-ellipsis">
                                {to?.key}
                            </span>
                        </div>
                    </TooltipTrigger>

                    <TooltipContent>
                        {to?.key}
                    </TooltipContent>
                </Tooltip>
            </div>
        </Button>
    )
}
