import { List, ListItem } from "@/components/ui/displays/list";
import { dateToFullMonthDayYearFormatter } from "@/lib/formatters/date-formatters";
import { estimatedTimeSpanInSecondsRangeFormatter } from "@/lib/formatters/time-span-formatters";
import { ItinerarySchema } from "@/services/otp/validations/planner.graphql";

export function ItineraryDetailsHeader({ startTime, duration }: ItinerarySchema) {
    return (
        <List variant={"simple"} className="text-foreground">
            <ListItem className="py-0 flex flex-row justify-between">
                <span
                    className="flex flex-col gap-1 justify-start items-center"
                >
                    <p className="text-muted-foreground">Date</p>

                    <p className="text-sm">{dateToFullMonthDayYearFormatter(startTime)}</p>
                </span>
                <span
                    className="flex flex-col gap-1 justify-start items-center"
                >
                    <p className="text-muted-foreground">Estimated Trip Duration</p>

                    <p className="text-sm">{estimatedTimeSpanInSecondsRangeFormatter(duration)}</p>
                </span>
            </ListItem>
        </List>
    )
}
