import { QueryName } from "../validations/planner.graphql";
import { generateGraphqlQuery } from "./generate-otp-query.graphql";
import { RouteSchema, RoutesGraphqlResponse, routesGraphqlResponse } from "../validations/routes.graphql";
import { z } from "zod";

export function generateRoutesQuery<
  TQueryString extends string = string,
  TQueryName extends QueryName<TQueryString> = QueryName<TQueryString>,
>(
  query: TQueryString,
  name: TQueryName,
  url: string,
) {
  const q = generateGraphqlQuery(
    query,
    name,
    url,
    routesGraphqlResponse,
  )

  return async () => {
    const [res, fallback] = await Promise.all([
      q(),
      INTERNAL__getFallbackConfiguration(),
    ]);

    const extendedRes = middleware(res, fallback)
    return extendedRes
  };
}

function middleware(res: RoutesGraphqlResponse, fallback: FallbackConfigurationResult) {
  const { data, ...rest } = res

  return {
    ...rest,
    data: data?.routes?.map((r) => extendedData(r, fallback)),
    configuration: fallback,
  }
}

function extendedData(route: RouteSchema, fallback: FallbackConfigurationResult) {
  if (!fallback.data?.routes || fallback.data.routes.length === 0) {
    return {
      ...route,
      __type: "error",
      message: "Unable to find agency with matching gtfsid",
    } satisfies DomainRouteError
  }

  const potentialFallbackConfiguration = fallback.data?.routes
    .find((r) => guardGtfsIdNotNull(route) && r.gtfsId === route.gtfsId)

  if (!potentialFallbackConfiguration) {
    return {
      ...route,
      __type: "fallback",
    } satisfies DomainRouteStateWide
  }

  return {
    ...route,
    __type: "fallback",
    configuration: potentialFallbackConfiguration,
  } satisfies DomainRouteStateWide
}

function guardGtfsIdNotNull(route: RouteSchema) {
  if (!route.gtfsId) {
    throw new Error("generate-routes-query+extendedData - Route.gtfsId cannot be null or undefined.")
  }

  return true
}

// INTERNAL__Mock

const fallbackRouteSchema = z.object({
  gtfsId: z.string(),
  name: z.string().optional(),
  enabled: z.boolean(),
  sortOrder: z.number(),
})

const fallbackConfigurationResultSchema = z.object({
  data: z.object({
    routes: z.array(fallbackRouteSchema)
  }).optional(),
  error: z.unknown().optional()
})

export type FallbackRoute = z.infer<typeof fallbackRouteSchema>
export type FallbackConfigurationResult = z.infer<typeof fallbackConfigurationResultSchema>


export type DomainRouteError = RouteSchema & {
  __type: "error",
  message: string,
}

export type DomainRouteStateWide = RouteSchema & {
  __type: "fallback",
  configuration?: FallbackRoute,
}

export type DomainRouteOption = DomainRouteError | DomainRouteStateWide


async function INTERNAL__getFallbackConfiguration(): Promise<FallbackConfigurationResult> {

  return {
    data: {
      routes: [
        {
          name: "Benzie County Dial-a-Ride",
          gtfsId: "1:BC1",
          enabled: true,
          sortOrder: 1,
        },
        {
          name: "Benzie County Airport Service",
          gtfsId: "1:BC2",
          enabled: true,
          sortOrder: 2,
        },
        {
          name: "Charlevoix County Ironton Ferry",
          gtfsId: "1:CC3",
          enabled: true,
          sortOrder: 3,
        },
      ]
    }
  }
}
