import React, { useState, forwardRef, ReactNode, useCallback } from "react";
import { Marker as LeafletMarker, MarkerProps } from "react-leaflet";
import { createIcon, defaultMarker } from "./utils";
import ReactDOMServer from "react-dom/server";

/**
 * Based on https://github.com/ishaan6395/react-leaflet-enhanced-marker/tree/master
 */
export const Marker = forwardRef<
  React.ComponentRef<typeof LeafletMarker>,
  Omit<MarkerProps, "icon"> & {
    icon: ReactNode,
    enableHighlight?: boolean,
  }
>(({
  icon,
  position,
  enableHighlight = false,
  ...props
}, ref) => {
  const [zoom, setZoom] = useState("100%");
  const [defaultHeight, setDefaultHeight] = useState(20);
  const [defaultWidth, setDefaultWidth] = useState(30);

  const getIcon = useCallback(() => {
    if (icon) {
      const domContent = ReactDOMServer.renderToString(icon);
      return createIcon(domContent, zoom);
    } else {
      return defaultMarker(defaultHeight, defaultWidth);
    }
  }, [defaultHeight, defaultWidth, icon, zoom]);

  const highlight = () => {
    if (!enableHighlight) {
      return;
    }

    setZoom("120%");
    setDefaultHeight(25);
    setDefaultWidth(35);
  };

  const removeHighlight = () => {
    if (!enableHighlight) {
      return;
    }

    setZoom("100%");
    setDefaultHeight(20);
    setDefaultWidth(30);
  };

  return (
    <LeafletMarker
      {...props}
      ref={ref}
      position={position}
      icon={getIcon()}
      eventHandlers={{
        click: (event) => {
          props.eventHandlers?.click?.(event);
        },
        mouseover: (event) => {
          highlight();
          props.eventHandlers?.mouseover?.(event);
        },
        mouseout: (event) => {
          removeHighlight();
          props.eventHandlers?.mouseout?.(event);
        },
      }}
    />
  );
});