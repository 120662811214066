import { appRoute } from "@/components/routes/app-route";
import { DEFAULT_COUNTY_FILTER_STATE } from "@/config/site";

export function useAppFilter() {
    const filters = appRoute.useSearch({ select: s => s.f });

    return {
        isCountyFilterEnabled: typeof filters?.counties === 'undefined' ? DEFAULT_COUNTY_FILTER_STATE : filters.counties,
        ...filters
    }
}
