import React from "react"
import { VariantProps } from "class-variance-authority"
import { Minus, Plus } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/form/button"
import { inputVariants } from "@/components/ui/form/input/input-variants"
import { InjectMapProps } from "@/types/leaflet"

export interface ZoomInOutProps
  extends React.HTMLAttributes<HTMLDivElement>,
  InjectMapProps,
  VariantProps<typeof inputVariants> { }

const ZoomInOut = React.forwardRef<HTMLDivElement, ZoomInOutProps>(
  ({ className, map, ...rest }, ref) => {
    if (!map) {
      return;
    }

    return (
      <>
        <div
          ref={ref}
          className={cn("grid shadow-input", "grid-cols-1", className)}
          {...rest}
        >
          <Button
            id="in"
            size={"icon"}
            variant={"outline"}
            onClick={(e) => {
              map.zoomIn()
              e.preventDefault()
            }}
            className={cn(
              "grid place-items-center",
              "!rounded-full !rounded-b-none border-b-transparent h-14 w-14"
            )}
          >
            <Plus className="w-4 h-4" />
          </Button>

          <Button
            id="out"
            size={"icon"}
            variant={"outline"}
            onClick={(e) => {
              map.zoomOut()
              e.preventDefault()
            }}
            className={cn(
              "grid place-items-center",
              "!rounded-full !rounded-t-none h-14 w-14"
            )}
          >
            <Minus className="w-4 h-4" />
          </Button>
        </div>
      </>
    )
  }
)

ZoomInOut.displayName = "ZoomInOut"

export { ZoomInOut }
