import { ChevronDown, Loader2Icon, Map } from "lucide-react"

import { ComponentClassName } from "@/types/utility"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/form/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuTrigger } from "../ui/primitives/dropdown-menu"
import { ClearMarkersItem } from "./shared/clear-markers-item"
import { SetCurrentLocationItem } from "./shared/set-current-location-item"
import { InjectMapProps } from "@/types/leaflet"
import { ZoomToRouteItem } from "./shared/zoom-to-route-item"
import { SetPlaceItemInteractive } from "./shared/set-place-item.interactive"
import { useDropMarker } from "@/hooks/use-drop-marker"
import { useMediaQuery } from "@/hooks/use-media-query"

const BUTTON_DESCRIPTION = "Open menu to interact with the map."

export function DropdownMenuActions({ map, className }: Omit<ComponentClassName, "children"> & InjectMapProps) {
  const [isDropping] = useDropMarker();
  const isMobile = useMediaQuery("(max-width: 640px)")

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
      >
        <Button
          id="current"
          size={"icon"}
          variant={"outline"}
          className={cn(
            "grid place-items-center h-full w-16 relative",
            "!rounded-full !rounded-r-none border-r-transparent",
            className
          )}
        >
          {
            isDropping ? (
              <Loader2Icon className="animate-spin w-4 h-4" />
            ) : (
              <>
                <Map className="w-4 h-4" />
                <ChevronDown className="w-3 h-3 text-muted-foreground absolute right-3 sm:right-2" />
                <span className="sr-only">{BUTTON_DESCRIPTION}</span>
              </>
            )
          }
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent 
        align={isMobile ? "end" : "start"}
        side={isMobile ? "left" : "bottom"}
      >
        {/** Command - Current location */}
        <SetCurrentLocationItem variant="dropdown" />

        {/** Command - Clear markers */}
        <ClearMarkersItem variant="dropdown" />

        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            Place Marker
          </DropdownMenuSubTrigger>

          <DropdownMenuSubContent>
            {/** Command - Set start */}
            <SetPlaceItemInteractive
              variant="dropdown"
              location="from"
              map={map}
            />

            {/** Command - Set end */}
            <SetPlaceItemInteractive
              variant="dropdown"
              location="to"
              map={map}
            />
          </DropdownMenuSubContent>
        </DropdownMenuSub>

        <DropdownMenuSeparator />

        <DropdownMenuGroup>
          <DropdownMenuLabel>Camera</DropdownMenuLabel>

          <ZoomToRouteItem variant="dropdown" map={map} />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}


