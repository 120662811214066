import { cva } from "class-variance-authority"

import {
  disabledInput,
  focusInput,
  hasErrorInput,
} from "@/components/ui/shared/component-state"

export const inputVariants = cva(
  [
    // base
    "relative block min-w-52 w-full appearance-none rounded-md border border-input px-2.5 py-1.5 shadow-input outline-none sm:text-sm",
    // border color
    "border-border",
    // text color
    "text-foreground",
    // placeholder color
    "placeholder-gray-400 dark:placeholder-gray-500",
    // background color
    "bg-background",
    // file
    [
      '[type="file"]:cursor-pointer',
      "file:-my-1.5 file:-ml-2.5 file:h-[36px] file:cursor-pointer file:rounded-l-md file:border-0 file:px-3 file:py-1.5 file:outline-none focus:outline-none file:rounded-r-none disabled:pointer-events-none file:disabled:pointer-events-none",
      "file:border-solid file:border-gray-300 file:bg-gray-50 file:text-gray-500 file:hover:bg-gray-100 file:dark:border-gray-800 file:dark:bg-gray-950 file:hover:dark:bg-gray-900/20 file:disabled:dark:border-gray-700",
      "file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem]",
      "file:disabled:bg-gray-100 file:disabled:text-gray-500 file:disabled:dark:bg-gray-800",
    ],
    // invalid (optional)
    "aria-[invalid=true]:dark:ring-red-400/20 aria-[invalid=true]:ring-2 aria-[invalid=true]:ring-red-200 aria-[invalid=true]:border-red-500 invalid:ring-2 invalid:ring-red-200 invalid:border-red-500",
    // remove search cancel button (optional)
    "[&::state(webkit-search-cancel-button)]:hidden [&::state(webkit-search-cancel-button)]:hidden [&::state(webkit-search-decoration)]:hidden",
    disabledInput,
    focusInput,
  ],
  {
    variants: {
      hasError: {
        true: hasErrorInput,
      },
    },
    defaultVariants: {},
  }
)
