import { env } from "@/env.setup";
import { logger } from "@/lib/notifications";
import { type z } from "zod";

export interface ValidateConfig<T extends z.ZodTypeAny> {
  dto: unknown;
  schema: T;
  schemaName: string;
}

export function validateSchema<T extends z.ZodTypeAny>(
  config: ValidateConfig<T>
): z.infer<T> {
  return validateSchemaSafe(
    config,
    {
      messagePrefix: 'API Validation Error:',
      shouldThrow: true,
    }
  )
}

export function validateSchemaSafe<T extends z.ZodTypeAny>(
  config: ValidateConfig<T>,
  {
    messagePrefix = 'Validation Error:',
    shouldThrow = false,
  }: {
    messagePrefix?: string,
    shouldThrow?: boolean,
  }
): z.infer<T> | undefined {
  const parsed = config.schema.safeParse(config.dto);
  const { success, error } = parsed;

  if (success) {
    return config.dto;
  } else {
    const message = `${messagePrefix} ${config.schemaName}`;
    captureError(message, {
      dto: config.dto,
      error: error,
    });

    if (shouldThrow) {
      throw new Error(message);
    }
  }
}

function captureError(message: string, extra = {}): void {
  if (env.DEV) {
    logger.error(message, extra);
  } else {
    // TODO: report to AWS/something else
  }
}
