import { useState } from "react"
import { Map as LeafletMap } from "leaflet"

import { ComponentClassName } from "@/types/utility"
import { cn } from "@/lib/utils"

import { MapContent } from "./map-content"
import { MapWrapper } from "./map-wrapper"
import { Overlay } from "../overlay"

/**
 * Main react component to wrap all map functionality, loaders, assets, sub-components, etc.
 *
 * Consists of standard map content (geojson layers, markers, etc.) and the components overlay (i.e., search, route preview, etc.).
 *
 * __*Exposes map-container group metatag to be used as a tailwind query*__
 * @example <div className="bg-green-400 group-[.map-container]:bg-blue-400" />
 * @returns Map React Component
 */
export function Map({ className }: ComponentClassName) {
  const [map, setMap] = useState<LeafletMap | null>(null);

  return (
    <div
      className={cn(
        "relative group map-container flex-1 grid overflow-hidden shadow-card",
        className
      )}
    >
      <MapWrapper ref={setMap}>
        <MapContent />
      </MapWrapper>

      <Overlay map={map} />
    </div>
  )
}
