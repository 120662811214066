 

import type { z } from "zod";
import { validateSchema } from "./validator";
import type { ValidateConfig } from "./validator";
import { FetchProps } from "@/types/api";


export type ValidatedFetchProps<T extends z.ZodTypeAny> = Pick<ValidateConfig<T>, "schema"> & FetchProps

export async function validatedFetch<T extends z.ZodTypeAny>({ url, schema, init = { method: 'GET' } }: ValidatedFetchProps<T>): Promise<z.infer<T>> {
  const res = await fetch(url, init);
  const json: unknown = await res.json()
  const validated = validateSchema({ dto: json, schema: schema, schemaName: typeof url === 'string' ? url : "schema request to url" });

  return validated;
}