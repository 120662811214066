import Typography from "@/components/ui/typeography/typeography";
import { dateToFullMonthDayYearFormatter } from "@/lib/formatters/date-formatters";
import { ItinerarySchema } from "@/services/otp/validations/planner.graphql";

type ItineraryCardHeaderProps = Pick<ItinerarySchema, "legs" | "startTime" | "endTime">;

export function ItineraryCardHeader(props: ItineraryCardHeaderProps) {

    return (
        <span
            className="text-base text-foreground flex flex-col gap-0"
        >
            {tryGetBestName(props)}
        </span>
    )
}

function tryGetBestName({ legs, startTime, endTime }: ItineraryCardHeaderProps) {
    if (!startTime || !endTime) {
        return "";
    }

    const match = legs?.find(l => l?.route?.longName ?? l?.agency?.name ?? l?.trip?.tripShortName)
    const value = match?.route?.longName ?? match?.agency?.name ?? match?.trip?.tripShortName

    if (value) {
        return (
            <>
                <Typography variant={"p"} affects={"large"}>
                    {`${value}`}
                </Typography>
                <Typography variant={"p"} affects={"subtle"} override={"removeMarginsAndLeading"}>
                    {`${dateToFullMonthDayYearFormatter(startTime)}`}
                </Typography>
            </>
        )
    }

    return (
        <>
            <Typography variant={"p"} affects={"large"}>
                {`${dateToFullMonthDayYearFormatter(startTime)}`}
            </Typography>
        </>
    )
}