 
import { aws } from "@/services/aws"
import useSwr from "swr"

import { useDebounce } from "@/hooks/use-debounce"
import { BBox } from "geojson"


/**
 * Represents the south west corner of the state of Michigan
 * 
 * __*Satisfies AWS LatLng coordinate [x_pos, y_pos]*__
 */
const southWestMichigan = [-86.90129356939181, 41.63342264112469] as const
/**
 * Represents the north east corner of the state of Michigan
 * 
 * __*Satisfies AWS LatLng coordinate [x_pos, y_pos]*__
 */
const northEastMichigan = [-82.36503975585276, 45.86986757152029] as const
/**
 * Represents the bounding box containing all of the state of Michigan
 * 
 * __*Satisfies AWS BBox schema [south_west_x_pos, south_west_y_pos, north_east_y_pos, north_east_y_pos]*__
 */
const MICHIGAN_BBOX = [southWestMichigan[0], southWestMichigan[1], northEastMichigan[0], northEastMichigan[1]] as const satisfies BBox

type QueryOutputInternal = Awaited<ReturnType<typeof aws.query.suggest>> | undefined
type UseLocationSearchReturn = {
  query: ReturnType<typeof useSwr<QueryOutputInternal, Error>>
  isLoading: boolean
}

export function useLocationSuggestions(
  search?: string,
  delay: number = 500
): UseLocationSearchReturn {
  const { value: debouncedSearch, debounceTimeout } = useDebounce(search, delay)
  const query = useSwr([debouncedSearch], () => {
    if (!search) {
      return;
    }

    return aws.query.suggest({
      Text: debouncedSearch,
      FilterBBox: MICHIGAN_BBOX,
    })
  })

  return {
    query,
    isLoading: debounceTimeout || query.isLoading,
  }
}
