/* eslint-disable no-empty-pattern */
"use client"

import * as React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react"
import { DayPicker } from "react-day-picker"

import { cn } from "@/lib/utils"
import { buttonVariants } from "../form/button/button-variant"
import { focusRing } from "../shared/component-state"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-2 relative items-center",
        caption_label: "text-default text-accent-foreground font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline", }),
          "p-1 h-8 w-8 text-subtle-foreground",
          focusRing,
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "w-9 font-normal text-center text-default text-subtle-foreground",
        row: "flex w-full mt-0.5",
        cell: cn(
          "text-center p-0 relative focus-within:relative text-default text-accent-foreground",
          focusRing,
        ),
        day: cn(
          "h-9 w-9 p-0 rounded-md hover:bg-muted rounded",
          focusRing,
        ),
        day_today: "font-bold",
        day_selected:
          "aria-selected:bg-primary aria-selected:text-inverted-foreground",
        day_disabled: "text-subtle-foreground disabled:hover:bg-transparent",
        day_outside: "text-subtle-foreground",
        day_range_middle: cn(
          "!rounded-none aria-selected:!bg-muted aria-selected:!text-foreground",
        ),
        day_range_start:
          "rounded-r-none rounded-l-md aria-selected:text-inverted-foreground",
        day_range_end:
          "rounded-l-none rounded-r-md aria-selected:text-inverted-foreground",

        ...classNames,
      }}
      components={{
        IconLeft: ({ }) => <ChevronLeftIcon className="h-4 w-4" />,
        IconRight: ({ }) => <ChevronRightIcon className="h-4 w-4" />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
