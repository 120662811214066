import { createRoute, redirect } from "@tanstack/react-router"
import { App } from "@/components/+app.page"
import { rootRoute } from "./root-route"
import { AppSearch, appSearchSchema } from "./app-route.zod"
import { aws } from "@/services/aws"
import { otpGraphql } from "@/services/otp"
import { logger } from "@/lib/notifications"

const HAS_VISITED_APP_HELP_PAGE_KEY = "mdotIsFirstVisit_HelpPageOpened";
const HAS_VISITED_APP_HELP_PAGE_TRUE_VALUE = "true";

export const appRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: App,
  validateSearch: appSearchSchema,
  beforeLoad: async ({ search }) => {
    firstVisitRedirectGuard(search);

    const services = getServices();

    try {
      await Promise.all(
        services.map((s) => s.setup())
      )
    } catch {
      logger.error("One or more services failed to load during page setup.")
    }
  },
  onLeave: async () => {
    const services = getServices();

    await Promise.all(
      services.map((s) => s.teardown())
    );
  }
})

function firstVisitRedirectGuard(search: AppSearch) {
    if (localStorage[HAS_VISITED_APP_HELP_PAGE_KEY] !== HAS_VISITED_APP_HELP_PAGE_TRUE_VALUE) {
      localStorage.setItem(HAS_VISITED_APP_HELP_PAGE_KEY, HAS_VISITED_APP_HELP_PAGE_TRUE_VALUE);
      throw redirect({ to: '/help', search, mask: { to: "/help" } })
    }
}

function getServices() {
  const services: {
    setup: () => void,
    teardown: () => void,
  }[] = [
    aws,
    otpGraphql
  ]  

  return services;
}