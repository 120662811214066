import React from "react";
import type { ItineraryDateProps } from "./itinerary-date";
import { type ItineraryIconProps } from "./itinerary-icon";
import { type VariantProps, cva } from "class-variance-authority";

const itemVariants = cva(
  "relative border-s-2 list-none", {
  variants: {
    borderStyling: {
      none: "border-none",
      solid: "border-solid",
      dashed: "border-dashed",
      dotted: "border-dotted",
      double: "border-double",
    },
    variant: {
      primary: "border-primary",
      secondary: "border-secondary",
      destructive: "border-destructive",
      border: "border-slate-400 dark:slate-500`",
    },
  },
  defaultVariants: {
    "borderStyling": "solid",
    variant: "border",
  },
});

export interface ItineraryItemProps
  extends Omit<React.LiHTMLAttributes<HTMLLIElement>, "children">,
  VariantProps<typeof itemVariants> {
  title?: string;
  description?: string;

  children: {
    icon?: React.ReactElement<ItineraryIconProps>;
    time?: React.ReactElement<ItineraryDateProps>;
    rest?: React.ReactNode;
  }
}

export const ItineraryItem = React.forwardRef<HTMLLIElement, ItineraryItemProps>(
  (
    {
      className,
      variant,
      borderStyling,
      title,
      description,
      children,
      ...props
    },
    ref,
  ) => {
    const { time, icon, rest } = children

    return (
      <span className="flex flex-row gap-3 last:-mb-10">
        <span>
          {time}
        </span>

        <li
          ref={ref}
          className={itemVariants({ borderStyling, variant, className })}
          {...props}
        >
          <div className="mb-10 ms-6">
            {icon}
            <h3 className="mb-0.5 flex items-center font-semibold text-foreground text-title">
              {title}
            </h3>


            <p className="mb-4 text-muted-foreground text-default">
              {description}
            </p>

            {rest}
          </div>
        </li>
      </span>
    );
  },
);

ItineraryItem.displayName = "ItineraryItem";
