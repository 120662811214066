import { cva } from "class-variance-authority";

export const typographyVariants = cva("text-xl text-pretty", {
  variants: {
    variant: {
      h1: "scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
      h2: "scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0",
      h3: "scroll-m-20 text-2xl font-semibold tracking-tight",
      h4: "scroll-m-20 text-xl font-semibold tracking-tight",
      p: "text-base leading-7 [&:not(:first-child)]:mt-6",
      span: "text-base",
    },
    affects: {
      default: "",
      lead: "text-xl text-muted-foreground",
      large: "text-lg font-semibold",
      smallLight: "text-sm font-normal leading-none",
      small: "text-sm font-medium leading-none",
      xsmall: "text-xs font-normal leading-none",
      muted: "text-sm text-muted-foreground",
      subtle: "text-xs text-subtle-foreground",
      blockquote: "mt-6 border-l-2 pl-6 italic",
      list: "my-6 ml-6 list-disc [&>li]:mt-2",
      sronly: "sr-only",
    },
    color: {
      default: "",
      foreground: "text-foreground",
      subtle: "text-subtle-foreground",
      muted: "text-muted-foreground",
      accent: "text-accent-foreground",
      strong: "text-strong-foreground",

      primary: "text-primary-foreground",
      secondary: "text-secondary-foreground",
      inverted: "text-inverted-foreground",
      destructive: "text-destructive-foreground",
    },
    override: {
      default: "",
      removeMarginsAndLeading: "[&:not(:first-child)]:mt-0 leading-none [&>li]:mt-2",
    }
  },
  defaultVariants: {
    variant: "h1",
    affects: "default",
    color: "default",
    override: "default",
  },
})

