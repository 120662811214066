import { GeoJSON } from "react-leaflet"

import { useCountyGeojson } from "../../hooks/use-county-geojson"
import { useAppFilter } from "@/hooks/use-app-filter";

export function GeoJsonLoader() {
  const { isCountyFilterEnabled } = useAppFilter();

  if (!isCountyFilterEnabled) {
    return;
  }

  return (
    <GeoJsonLoaderInternal />
  )
}

function GeoJsonLoaderInternal() {
  const { data, isLoading, error } = useCountyGeojson()

  if (error) {
    return <>Error...</>
  }

  if (isLoading || !data) {
    return <>Loading...</>
  }

  return (
    <GeoJSON
      key={JSON.stringify(data)}
      data={data}
      eventHandlers={{
        click: (e: unknown) => console.log("geojson-loader + click: event ", e),
      }}
      onEachFeature={(feature, layer) => {
        layer.bindTooltip(feature.id?.toString() || "", {
          permanent: true,
          direction: "center",
          className: "text-[0.70rem] border-0 bg-transparent"
        });
      }}
    />
  )
}