import { Icons } from "@/components/ui/icons";
import { CardMessageWrapper } from "./itineraries-state.contianer";

export function ItineraryCardError({ error: _error }: { error: Error }) {
    return <CardMessageWrapper
        icon={<Icons.plan.error />}
    >
        At the moment, some customers may have difficulty using many planner features. Our team is actively investigating the incident and will update you with more details as we have them.
    </CardMessageWrapper>
}
