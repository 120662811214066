import { UseNavigateResult, useNavigate } from "@tanstack/react-router";
import { AppPlace, AppSearch } from "./app-route.zod";
import { appRoute } from "./app-route";
import { InjectMapProps } from "@/types/leaflet";
import { useMediaQuery } from "@/hooks/use-media-query";
import { DEFAULT_TRANSPORT_TYPE } from "@/config/otp";
import { panToCenter, tryZoomTo } from "@/lib/places";


type NavigateProps = Omit<Parameters<UseNavigateResult<"/">>["0"], "search" | "mask" | "from" | "to">
export function useSetAppSearch(map?: InjectMapProps["map"]) {
    const navigate = useNavigate({ from: appRoute.fullPath })
    const isTablet = useMediaQuery("(max-width: 768px)")

    function setPlanModel(open?: boolean, options?: NavigateProps) {
        return navigate({
            search: (prev) => {
                if (!isAppSearch(prev) || !prev) {
                    panToCenter(map, open, isTablet)
                    return;
                }

                if (open && prev.from && prev.to) {
                    tryZoomTo(map, prev.from, prev.to, isTablet, open)
                } else {
                    panToCenter(map, open, isTablet)
                }

                if (!prev?.data) {
                    prev.data = {
                        _t: "p",
                        open,
                        transportType: DEFAULT_TRANSPORT_TYPE
                    }
                } else {
                    prev.data.open = open
                }

                return prev
            },
            ...options
        })
    }

    function setFrom(place?: AppPlace, options?: NavigateProps) {
        return navigate({
            search: (prev) => {
                if (!isAppSearch(prev) || !prev) {
                    return;
                }

                prev.from = place;
                const { from, to } = prev;

                tryZoomTo(map, from, to, isTablet, prev.data?.open || (!!from && !!to));

                if (from && to && prev.data && prev.data.open) {
                    prev.data._t = 'p'
                    return prev;
                }

                if (from && to && prev.data) {
                    prev.data.open = true;
                    prev.data._t = 'p'

                    return prev;
                }

                if (from && to) {
                    prev.data = { _t: "p", open: true, transportType: DEFAULT_TRANSPORT_TYPE };

                    return prev;
                }

                return prev;
            },
            ...options,
        })
    }

    function setTo(place?: AppPlace, options?: NavigateProps) {
        return navigate({
            search: (prev) => {
                if (!isAppSearch(prev) || !prev) {
                    return;
                }

                prev.to = place;
                const { from, to } = prev;

                tryZoomTo(map, from, to, isTablet, prev.data?.open || (!!from && !!to));

                if (from && to && prev.data && prev.data.open) {
                    prev.data._t = 'p'
                    return prev;
                }

                if (from && to && prev.data) {
                    prev.data.open = true;
                    prev.data._t = 'p'

                    return prev;
                }

                if (from && to) {
                    prev.data = { _t: "p", open: true, transportType: DEFAULT_TRANSPORT_TYPE };

                    return prev;
                }

                return prev;
            },
            ...options,
        })
    }

    function clear(options?: NavigateProps) {
        return navigate({
            search: (prev) => {
                if (!isAppSearch(prev) || !prev) {
                    return;
                }

                const { from: _from, to: _to, data: _data, ...rest } = prev;

                return rest
            },
            ...options
        })
    }

    function set(props: AppSearch | ((prev: Partial<AppSearch>) => AppSearch), options?: NavigateProps) {
        if (typeof props === 'function') {
            return navigate({
                search: props,
                ...options,
            })
        }


        return navigate({
            search: (prev) => {
                return {
                    ...prev,
                    ...props
                }
            },
            ...options,
        })
    }

    return {
        setPlanModel: setPlanModel,
        setFrom: setFrom,
        setTo: setTo,
        clear: clear,
        set: set,
    }
}

function isAppSearch(potentialSearch: unknown): potentialSearch is AppSearch {
    if (!potentialSearch || typeof potentialSearch !== "object") {
        return false;
    }

    if (!(
        "data" in potentialSearch ||
        "to" in potentialSearch ||
        "from" in potentialSearch ||
        "m" in potentialSearch ||
        "f" in potentialSearch
    )) {
        return false;
    }

    return true;
}
