"use client"

import * as React from "react"
import { CommandList } from "cmdk"

import { cn } from "@/lib/utils"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/containers/popover"
import { Button, type ButtonVariantProps } from "@/components/ui/form/button"
import {
  Command,
  CommandEmpty,
  CommandInput,
  type CommandItem,
} from "@/components/ui/form/command"
import { Icons } from "@/components/ui/icons"
import { Tooltip, TooltipContent, TooltipTrigger } from "../../containers/tool-tip"

export type ComboboxProps = {
  icon?: React.ReactNode
  value: string | number | undefined
  placeholder?: React.ReactNode
  className?: string
  popoverClassName?: string
  shouldFilter?: React.ComponentProps<typeof Command>["shouldFilter"]
  filter?: React.ComponentProps<typeof Command>["filter"]
  onValueChange?: React.ComponentProps<typeof CommandInput>["onValueChange"]
  empty?: React.ReactElement<React.ComponentProps<typeof CommandEmpty>>

  children?:
  | React.ReactElement<React.ComponentProps<typeof CommandItem>>[]
  | React.ReactElement<React.ComponentProps<typeof CommandItem>>
  | React.ReactNode
}

export function Combobox({
  value,
  icon,
  placeholder = "Select...",
  children,
  size = "default",
  variant = "outline",
  empty = <CommandEmpty>No options found.</CommandEmpty>,
  onValueChange,
  className,
  popoverClassName,
  filter,
  shouldFilter,
}: ComboboxProps & ButtonVariantProps) {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover
      open={open}
      onOpenChange={(open) => {
        setOpen(open)
        if (!open) {
          return
        }

        onValueChange?.("")
      }}
    >
      <Tooltip>
        <PopoverTrigger asChild>
          <TooltipTrigger className="w-full grid grid-cols-[auto,_1fr,_auto] items-center" asChild>
            <Button
              role="combobox"
              aria-expanded={open}
              size={size}
              variant={variant}
              className={cn("w-full justify-start text-left gap-2", className)}
            >
              <span>
                {icon}
              </span>
              <span className="ml-2 text-start text-nowrap overflow-hidden text-ellipsis">
                {value ? value : placeholder}
              </span>
              <div className="h-5 w-5">
                <Icons.input.expand className="h-5 w-5 opacity-50" />
              </div>
            </Button>
          </TooltipTrigger>
        </PopoverTrigger>

        <TooltipContent>
          {value ? value : placeholder}
        </TooltipContent>
      </Tooltip>

      <PopoverContent className={cn("p-0", popoverClassName)} onOpenAutoFocus={undefined}>
        <Command
          loop
          filter={filter}
          shouldFilter={shouldFilter}
        >
          <CommandInput placeholder="Search..." onValueChange={onValueChange} />
          <CommandList onClick={() => setOpen(false)}>
            {empty}
            {children}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
