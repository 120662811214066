import { S3_EXPECTED_LOCATIONS_GEOJSON_FILE_NAME, S3_UPLOAD_DIRECTORY_NAME } from "@/config/backend"
import { downloadData } from "aws-amplify/storage"
import { GeoJsonObject } from "geojson"
import useSWR from "swr"

const COUNTY_GEOJSON_KEY = "COUNTY_GEOJSON_KEY"

export function useCountyGeojson() {
  return useSWR([COUNTY_GEOJSON_KEY], getGeojson, {
    refreshWhenHidden: false,
    refreshWhenOffline: false,
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  })
}

async function getGeojson(): Promise<GeoJsonObject> {
  const { body } = await downloadData({
    path: `${S3_UPLOAD_DIRECTORY_NAME}/${S3_EXPECTED_LOCATIONS_GEOJSON_FILE_NAME}`,
  }).result;

  const probablyGeojsonString = await body.text()
  const probablyGeojson = JSON.parse(probablyGeojsonString)
  
  return probablyGeojson
}