import { z } from "zod"
import { vertexTypeSchema } from "./planner"
import { bikesAllowedSchema, geometrySchema, idSchema, legTimeSchema, occupancyStatusSchema, patternSchema, stopSchema, stopTimeSchema, modeSchema, wheelchairBoardingSchema, stopPositionSchema, realtimeStateSchema, pickupDropoffTypeSchema, durationSchema, graphqlResponse } from "./base.grpahql"
import { alertSchema, debugOutputSchema, routingErrorSchema, systemNoticeSchema } from "./errors.graphql"
import { agencySchema } from "./agency.graphql"
import { routeSchema } from "./routes.graphql"

type GqlOperation = "query" | "mutation" | "subscription "
export type QueryName<T> = T extends `${string}${GqlOperation} ${infer TName}${"("}${infer TRest}`
    ? TName | QueryName<TRest>
    : T extends `${string}${GqlOperation} ${infer TName}${" {"}${infer TRest}`
    ? TName | QueryName<TRest>
    : never

export const tripSchema = z.object({
    id: idSchema.optional(),
    gtfsId: z.string().optional(),
    route: routeSchema.optional(),
    serviceId: z.string().optional().nullable(),
    activeDates: z.array(z.string()).optional().nullable(),
    tripShortName: z.string().optional().nullable(),
    tripHeadsign: z.string().optional().nullable(),
    routeShortName: z.string().optional().nullable(),
    directionId: z.string().optional().nullable(),
    blockId: z.string().optional().nullable(),
    shapeId: z.string().optional().nullable(),
    wheelchairAccessible: wheelchairBoardingSchema.optional().nullable(),
    bikesAllowed: bikesAllowedSchema.optional().nullable(),
    pattern: patternSchema.optional().nullable(),
    stops: z.array(stopSchema).optional().nullable(),
    semanticHash: z.string().optional(),
    stoptimes: z.array(stopTimeSchema).optional().nullable(),
    departureStoptime: stopTimeSchema.optional().nullable(),
    arrivalStoptime: stopTimeSchema.optional().nullable(),
    stoptimesForDate: z.array(stopTimeSchema).optional().nullable(),
    geometry: z.array(z.array(z.number())).optional().nullable(),
    tripGeometry: geometrySchema.optional().nullable(),
    alerts: z.array(alertSchema).optional().nullable(),
    occupancy: occupancyStatusSchema.optional().nullable(),
})

const vehicleRentalStationSchema = z.unknown();
const rentalVehicleSchema = z.unknown();
const vehicleParking = z.unknown();

export const placeSchema = z.object({
    name: z.string().optional().nullable(),
    vertexType: vertexTypeSchema.optional().nullable(),
    lon: z.number().optional(),
    lat: z.number().optional(),
    arrival: legTimeSchema.optional().nullable(),
    departure: legTimeSchema.optional().nullable(),
    stop: stopSchema.optional().nullable(),
    stopPosition: stopPositionSchema.optional().nullable(),
    vehicleRentalStation: vehicleRentalStationSchema.optional().nullable(),
    rentalVehicle: rentalVehicleSchema.optional().nullable(),
    vehicleParking: vehicleParking.optional().nullable(),
})

const elevationProfileSchema = z.object({
    distance: z.number().optional().nullable(),
    elevation: z.number().optional().nullable(),
})

const relativeDirectionSchema = z.union([
  z.literal("DEPART"),
  z.literal("HARD_LEFT"),
  z.literal("LEFT"),
  z.literal("SLIGHTLY_LEFT"),
  z.literal("CONTINUE"),
  z.literal("SLIGHTLY_RIGHT"),
  z.literal("RIGHT"),
  z.literal("HARD_RIGHT"),
  z.literal("CIRCLE_CLOCKWISE"),
  z.literal("CIRCLE_COUNTERCLOCKWISE"),
  z.literal("ELEVATOR"),
  z.literal("UTURN_LEFT"),
  z.literal("UTURN_RIGHT"),
  z.literal("ENTER_STATION"),
  z.literal("EXIT_STATION"),
  z.literal("FOLLOW_SIGNS"),
])

const absoluteDiretionSchema = z.union([
  z.literal("NORTH"),
  z.literal("NORTHEAST"),
  z.literal("EAST"),
  z.literal("SOUTHEAST"),
  z.literal("SOUTH"),
  z.literal("SOUTHWEST"),
  z.literal("WEST"),
  z.literal("NORTHWEST")
])


const stepSchema = z.object({
    distance: z.number().optional().nullable(),
    lon: z.number().optional().nullable(),
    lat: z.number().optional().nullable(),
    elevationProfile: z.array(elevationProfileSchema).optional().nullable(),
    relativeDirection: relativeDirectionSchema.optional().nullable(),
    absoluteDirection: absoluteDiretionSchema.optional().nullable(),
    exit: z.string().optional().nullable(),
    streetName: z.string().optional().nullable(),
    stayOn: z.boolean().optional().nullable(),
    area: z.boolean().optional().nullable(),
    bogusName: z.boolean().optional().nullable(),
    walkingBike: z.boolean().optional().nullable(),
    alerts: z.array(alertSchema).optional().nullable(),
})

const contactInfoSchema = z.object({
    contactPerson: z.string().optional().nullable(),
    phoneNumber: z.string().optional().nullable(),
    eMail: z.string().optional().nullable(),
    infoUrl: z.string().optional().nullable(),
    bookingUrl: z.string().optional().nullable(),
    additionalDetails: z.string().optional().nullable(),
})

const bookingTimeSchema = z.object({
    time: z.string().optional().nullable(),
    daysPrior: z.number().optional().nullable(),
})

const bookingInfoSchema = z.object({
    contactInfo: contactInfoSchema.optional().nullable(),
    earliestBookingTime: bookingTimeSchema.optional().nullable(),
    latestBookingTime: bookingTimeSchema.optional().nullable(),
    minimumBookingNoticeSeconds: z.number().optional().nullable(),
    maximumBookingNoticeSeconds: z.number().optional().nullable(),
    message: z.string().optional().nullable(),
    pickupMessage: z.string().optional().nullable(),
    dropOffMessage: z.string().optional().nullable(),
})

const fareProductUseSchema = z.object({
    id: z.string().optional(),
})

const moneySchema = z.object({
    amount: z.number().optional(),
    currency: z.object({
        code: z.string().optional(),
        digits: z.number().optional(),
    }).optional(),
})

const rideHailingEstimateSchema = z.object({
    provider: z.object({
        id: z.string().optional(),
    }).optional(),
    minPrice: moneySchema.optional(),
    maxPrice: moneySchema.optional(),
    arrival: durationSchema.optional(),
    productName: z.string().optional().nullable(),
})

const legSchema = z.object({
    /**
     * @deprecated
     */
    startTime: z.number().optional().nullable(),
    /**
     * @deprecated
     */
    endTime: z.number().optional().nullable(),
    // start: legTimeSchema.optional(),
    // end: legTimeSchema.optional(),
    mode: modeSchema.optional().nullable(),
    duration: z.number().optional().nullable(),
    generalizedCost: z.number().optional().nullable(),
    legGeometry: geometrySchema.optional().nullable(),
    agency: agencySchema.optional().nullable(),
    realTime: z.boolean().optional().nullable(),
    realtimeState: realtimeStateSchema.optional().nullable(),
    distance: z.number().optional().nullable(),
    transitLeg: z.boolean().optional().nullable(),
    walkingBike: z.boolean().optional().nullable(),
    rentedBike: z.boolean().optional().nullable(),
    from: placeSchema.optional().nullable(),
    to: placeSchema.optional().nullable(),
    route: routeSchema.optional().nullable(),
    trip: tripSchema.optional().nullable(),
    serviceDate: z.string().optional().nullable(),
    intermediateStops: z.array(stopSchema).optional().nullable(),
    intermediatePlaces: z.array(placeSchema).optional().nullable(),
    intermediatePlace: z.boolean().optional().nullable(),
    steps: z.array(stepSchema).optional().nullable(),
    headsign: z.string().optional().nullable(),
    pickupType: pickupDropoffTypeSchema.optional().nullable(),
    dropoffType: pickupDropoffTypeSchema.optional().nullable(),
    interlineWithPreviousLeg: z.boolean().optional().nullable(),
    dropOffBookingInfo: bookingInfoSchema.optional().nullable(),
    pickupBookingInfo: bookingInfoSchema.optional().nullable(),
    alerts: z.array(alertSchema).optional().nullable(),
    /**
     * @see legSchema
     */
    nextLegs: z.array(z.unknown()).optional().nullable(),
    rideHailingEstimate: rideHailingEstimateSchema.optional().nullable(),
    accessibilityScore: z.number().optional().nullable(),
    fareProducts: z.array(fareProductUseSchema).optional().nullable(),

})

const gramsSchema = z.unknown()

const emissionsSchema = z.object({
    co2: gramsSchema.optional().nullable(),
})

const itinerarySchema = z.object({
    /**
     * @deprecated
     */
    startTime: z.number().optional().nullable(),
    /**
     * @deprecated
     */
    endTime: z.number().optional().nullable(),
    // start: offsetDateTimeSchema.optional().nullable(),
    // end: offsetDateTimeSchema.optional().nullable(),
    duration: z.number().optional().nullable(),
    generalizedCost: z.number().optional().nullable(),
    waitingTime: z.number().optional().nullable(),
    walkTime: z.number().optional().nullable(),
    walkDistance: z.number().optional().nullable(),
    emissionsPerPersion: emissionsSchema.optional().nullable(),
    legs: z.array(legSchema).optional(),
    elevationGained: z.number().optional().nullable(),
    arrivedAtDestinationWithRentedBicycle: z.boolean().optional().nullable(),
    systemNotices: z.array(systemNoticeSchema).optional(),
    accessibilityScore: z.number().optional().nullable(),
    numberOfTransfers: z.number().optional(),
})

export const planResponse = z.object({
        plan: z.object({
            date: z.number().optional().nullable(),
            from: placeSchema.optional(),
            to: placeSchema.optional(),
            itineraries: z.array(itinerarySchema).optional(),
            messageEnums: z.array(z.string()).optional(),
            messageStrings: z.array(z.string()).optional(),
            routingErrors: z.array(routingErrorSchema).optional(),
            nextPageCursor: z.string().optional().nullable(),
            previousPageCursor: z.string().optional().nullable(),
            searchWindowUsed: z.number().optional().nullable(),
            debugOutput: debugOutputSchema.optional().nullable(),
        }).optional().nullable()
    })

export const planGraphqlResponse = graphqlResponse(
    planResponse,
)


export type PlanGraphqlResponse = z.infer<typeof planGraphqlResponse>
export type PlanResponse = z.infer<typeof planResponse>
export type ItinerarySchema = z.infer<typeof itinerarySchema>
export type LegSchema = z.infer<typeof legSchema>
export type StepSchema = z.infer<typeof stepSchema>
export type StopSchema = z.infer<typeof stopSchema>
export type RelativeDirectionSchema = z.infer<typeof relativeDirectionSchema>
export type AbsoluteDirectionSchema = z.infer<typeof absoluteDiretionSchema>
export type PlaceSchema = z.infer<typeof placeSchema>

