import { cn } from "@/lib/utils";
import React from "react";

export interface ItineraryProps extends Omit<React.OlHTMLAttributes<HTMLOListElement>, ""> {
  
}

export const Itinerary = React.forwardRef<HTMLOListElement, ItineraryProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <ol ref={ref} className={cn( "relative flex flex-col gap-0" , className) }{...props}>
        {children}
      </ol>
    );
  },
);

Itinerary.displayName = "Itinerary";
