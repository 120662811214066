import { cn } from "@/lib/utils";
import { HTMLAttributes, forwardRef } from "react";
import { useMobileSafeHeight } from "./use-mobile-safe-height";
import { logger } from "@/lib/notifications";

export type MobileSafeDivProps = HTMLAttributes<HTMLDivElement>

/**
 * React Component wrapping HTML Div to provide mobile friendly full height.
 * 
 * Mobile devices usually container elements which obstruct views (floating islands, camera, etc.). 
 * Component will override using a simple ###vh for mobile devices to position elements around these
 * critical points on the device.
 * 
 * __*ENFORCES DIV IS 100% HEIGHT*__.
 */
export const MobileSafeDiv = forwardRef<
    HTMLDivElement,
    MobileSafeDivProps
>(({ style, className, ...rest }, ref) => {
    const height = useMobileSafeHeight()

    if (style?.height) {
      logger.warn(
        '<MobileSafeDiv /> overrides the height property of the style prop'
      )
    }

    const styleWithRealHeight = {
      ...style,
      height: height ? `${height}px` : '100vh'
    }

    return (
        <div
            ref={ref}
            style={styleWithRealHeight}
            className={cn(
                "",
                className
            )}
            {...rest}
        >

        </div>
    )
});

MobileSafeDiv.displayName = "MobileSafeDiv";