import { LocationClient } from "@aws-sdk/client-location"
import { withAPIKey } from "@aws/amazon-location-utilities-auth-helper"

import { getKey } from "./get-key"

const region = "us-east-2"

type SetupClientReturn = LocationClient

export async function setupClient(): Promise<SetupClientReturn> {
  const key = await getKey()

  if (!key) {
    throw new Error(
      "Unable to connect to aws places index server. Key request failed."
    )
  }

  const authHelper = await withAPIKey(key)

  const client = new LocationClient({
    region: region,
    ...authHelper.getLocationClientConfig(),
  })

  return client
}
