import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/containers/card";
import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { Button } from "@/components/ui/form/button";
import { getPlanKey, useIsSelectedPlan } from "@/hooks/use-is-selected-plan";
import { cn } from "@/lib/utils";
import { ItineraryCardDetails } from "./itinerary-card.details";
import { ItineraryCardHeader } from "./itinerary-card.header";
import { ItineraryCardAlerts } from "./itinerary-card.alerts";
import { ItineraryCardGraphic } from "./itinerary-card.graphic";
import { AppItinerary } from "@/types/otp/+extended";

export function ItineraryCard(plan: AppItinerary) {
    const { duration, legs } = plan

    const { set } = useSetAppSearch()
    const isSelected = useIsSelectedPlan(plan);
    if (!legs || legs.length <= 0 || !duration) {
        return;
    }

    return (
        <Card
            className={cn(
                "cursor-pointer",
                isSelected ? "border-s-blue-400 dark:border-s-blue-600 border-s-4" : ""
            )}
            onClick={() => {
                set((prev) => {
                    if (!prev.data) {
                        return prev
                    }

                    const planKey = getPlanKey(plan);
                    if (planKey && isSelected && prev.data._t !== "t") {
                        prev.data._t = "t"
                    } else {
                        prev.data.selected = getPlanKey(plan);
                    }

                    return prev
                })
            }}
        >
            <CardHeader className="py-4 px-6">
                <CardTitle>
                    <ItineraryCardHeader
                        legs={plan.legs}
                        startTime={plan.startTime}
                        endTime={plan.endTime}
                    />
                </CardTitle>
            </CardHeader>

            <CardContent>
                <ItineraryCardDetails
                    startTime={plan.startTime}
                    duration={plan.duration}
                />

                <ItineraryCardGraphic
                    legs={plan.legs}
                />
            </CardContent>

            <CardFooter className="flex flex-col gap-2">
                <ItineraryCardAlerts
                    legs={plan.legs}
                />

                {isSelected &&
                    <Button className="w-full" variant={"outline"}>Details</Button>
                }
            </CardFooter>
        </Card>
    )
}
