import { useEffect, useState } from "react"

/**
 * React hook to wrap state changes with a given amount of delay. Used
 * for high write changes which are not critical. Use as first response to
 * reduce load on server actions/ updates.
 *
 * `Default delay`: 500ms
 *
 * @example
 *  const [search, setSearch] = useState('');
 *  // debounceSearch will not trigger other hooks
 *  // to run unless user stops typing for 1s.
 *  const { value } = useDebounce(search, 1000);
 *
 *  useEffect(() => {
 *      console.log("debounce", debouncedSearch);
 * }, [debouncedSearch])
 *
 * return (
 *      <>
 *           <input
 *              value={search}
 *              onChange={(event) => {
 *                  setSearch(event.target.value);
 *              }}
 *           />
 *      </>
 * )
 */
export function useDebounce<TValue = unknown>(value: TValue, ms: number = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [debounceTimeout, setDebounceTimeout] = useState(false)

  useEffect(() => {
    setDebounceTimeout(true)
    const handler = setTimeout(() => {
      setDebouncedValue(value)
      setDebounceTimeout(false)
    }, ms)

    return () => {
      clearTimeout(handler)
    }
  }, [value, ms])

  return {
    value: debouncedValue,
    debounceTimeout,
  }
}
