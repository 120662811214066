/* eslint-disable @typescript-eslint/no-explicit-any */
export type GenerateHashOptions = {
    /**
     * List of keys to ignore when generating hash
     */
    ignore?: string[] | string | undefined
}

function pad(hash: string, len: number): string {
    while (hash.length < len) {
        hash = '0' + hash;
    }
    return hash;
}

function fold(hash: number, text: string): number {
    let i;
    let chr;
    let len;
    if (text.length === 0) {
        return hash;
    }
    for (i = 0, len = text.length; i < len; i++) {
        chr = text.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }
    return hash < 0 ? hash * -2 : hash;
}

function foldObject(hash: any, o: any, seen: any[], opts?: GenerateHashOptions): number {
    return Object.keys(o)
    .filter((k) => {
        const keyFilter = opts?.ignore;
        if (!keyFilter) {
            return true;
        }

        if (typeof keyFilter === 'string') {
            return keyFilter !== k
        }

        if (Array.isArray(keyFilter)) {
            return !keyFilter.find((f) => f === k)
        }

        return true;
    })
    .sort()
    .reduce(foldKey, hash);

    function foldKey(hash: any, key: string) {
        return foldValue(hash, o[key], key, seen, opts);
    }
}

function foldValue(input: number, value: any | null | undefined, key: string, seen: any[], opts?: GenerateHashOptions): number {
    const hash = fold(fold(fold(input, key), toString(value)), typeof value);
    if (value === null) {
        return fold(hash, 'null');
    }
    if (value === undefined) {
        return fold(hash, 'undefined');
    }
    if (typeof value === 'object' || typeof value === 'function') {
        if (seen.indexOf(value) !== -1) {
            return fold(hash, '[Circular]' + key);
        }
        seen.push(value);

        const objHash = foldObject(hash, value, seen, opts)

        if (!('valueOf' in value) || typeof value.valueOf !== 'function') {
            return objHash;
        }

        try {
            return fold(objHash, String(value.valueOf()))
        } catch (err) {
            if (err instanceof Error) {
                return fold(objHash, '[valueOf exception]' + (err.stack || err.message))
            } else if (typeof err === 'string') {
                return fold(objHash, '[valueOf exception]' + err)
            }
        }
    }
    return fold(hash, value.toString());
}

function toString(o: any) {
    return Object.prototype.toString.call(o);
}

export function generateHash(o: any, opts?: GenerateHashOptions) {
    const options = opts ?? { ignore: undefined }
    return pad(foldValue(0, o, '', [], options).toString(16), 8);
}