import { Icons } from "@/components/ui/icons";
import { CardMessageWrapper } from "./itineraries-state.contianer";

export function ItineraryCardEmpty() {
    return <CardMessageWrapper
        icon={<Icons.plan.empty />}
    >
        Plans and routes will be here automatically when two locations are set. Select two points on the map to start generating plans.
    </CardMessageWrapper>
}
