import { logger } from "../notifications";
import { TimeSpan } from "../time";

const formatTime = Intl.NumberFormat("us", { maximumFractionDigits: 0, minimumIntegerDigits: 2 });

type FormatterOptions = {
  long: boolean,  
}

const DEFAULT_OPTIONS = {
  long: false,
} satisfies FormatterOptions

const SHORT_FORMAT_HOURS = "hr";
const LONG_FORMAT_HOURS = "hours";
const SHORT_FORMAT_MINUTES = "min";
const LONG_FORMAT_MINUTES = "minutes";

export function timeSpanInSecondsFormatter(seconds: number | null | undefined, opts = DEFAULT_OPTIONS): string {
  if (typeof seconds !== 'number') {
    return 'N/A'
  }

  return timeSpanFormatter(TimeSpan.fromSeconds(seconds), opts)
}

export function timeSpanFormatter(timespan: TimeSpan, opts = DEFAULT_OPTIONS): string {
  const min = opts.long ? LONG_FORMAT_MINUTES : SHORT_FORMAT_MINUTES;
  const hr = opts.long ? LONG_FORMAT_HOURS : SHORT_FORMAT_HOURS;
  
  if (timespan.hours <= 0) {
    return `${formatTime.format(timespan.minutes)} ${min}`;
  }
  
  if (timespan.minutes <= 0) {
    return `${formatTime.format(timespan.hours)} ${hr}`;
  }
  
  return `${formatTime.format(timespan.hours)} ${hr} ${formatTime.format(timespan.minutes)} ${min}`;
}


export function estimatedTimeSpanInSecondsRangeFormatter(seconds: number | null | undefined): string {
  if (typeof seconds !== 'number') {
    return 'N/A'
  }

  return estimatedTimeSpanRangeFormatter(TimeSpan.fromSeconds(seconds))
}

export function estimatedTimeSpanRangeFormatter(timespan: TimeSpan): string {
  const minutes = timespan.totalMinutes
  const start = getStartTime(minutes);
  const offset = getEndTimeOffset(start);
  if (!offset) {
    logger.error("ERROR FORMATTING TIMESPAN: estimatedTimeSpanRangeFormatter unable to format the given value (", timespan.totalMinutes, " minutes)")
    return 'UNABLE TO FORMAT TIMESPAN'
  }
  
  const end = start + offset;

  return `${timeSpanFormatter(TimeSpan.fromMinutes(start))} - ${timeSpanFormatter(TimeSpan.fromMinutes(end))}`;
}

const MINUTE_SPAN_TO_ROUND = 15;
function getStartTime(minutes: number) {
  const rounded = Math.ceil(minutes / MINUTE_SPAN_TO_ROUND) * MINUTE_SPAN_TO_ROUND;
  
  return rounded;
}

/**
 * __*ALL NUMBERS ARE REPRESENTED AS MINUTES*__
 */
const TIMESPAN_ESTIMATION_OFFSETS = [
  {
    lower: Number.MIN_SAFE_INTEGER,
    upper: 15,
    estimate: 30,
  },
  {
    lower: 15,
    upper: 30,
    estimate: 30,
  },
  {
    lower: 30,
    upper: 45,
    estimate: 45,
  },
  {
    lower: 45,
    upper: 60,
    estimate: 60,
  },
  {
    lower: 60,
    upper: 90,
    estimate: 60,
  },
  {
    lower: 90,
    upper: 180,
    estimate: 90,
  },
  {
    lower: 180,
    upper: 360,
    estimate: 120,
  },
  {
    lower: 360,
    upper: Number.MAX_SAFE_INTEGER,
    estimate: 120,
  },
] satisfies {
  lower: number,
  upper: number,
  estimate: number,
}[]


function getEndTimeOffset(minutes: number) {
  const match = TIMESPAN_ESTIMATION_OFFSETS.find((o) => {
    return o.lower <= minutes &&
      o.upper > minutes
  })

  if (!match) {
    return;
  }

  return match.estimate;
}