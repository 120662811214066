import { estimatedTimeSpanInSecondsRangeFormatter } from "@/lib/formatters/time-span-formatters";
import { List, ListItem } from "@/components/ui/displays/list";
import { ItinerarySchema } from "@/services/otp/validations/planner.graphql";

export function ItineraryCardDetails({ startTime: _startTime, duration }:
    Pick<ItinerarySchema, "startTime" | "duration">
) {
    return (
        <List variant={"simple"} className="text-foreground">
            <ListItem className="py-0 flex flex-row gap-2 flex-wrap justify-between">
                <span></span>
                {/* <span
                    className="flex flex-col gap-1 justify-start items-center"
                >
                    <p className="text-muted-foreground">Departure</p>

                    <p className="text-sm">{dateToHoursAndMinutesFormatter(startTime)}</p>
                </span> */}
                <span
                    className="flex flex-col gap-1 justify-start items-center"
                >
                    <p className="text-muted-foreground">Estimated Trip Duration</p>

                    <p className="text-sm">{estimatedTimeSpanInSecondsRangeFormatter(duration)}</p>
                </span>
            </ListItem>
        </List>
    )
}