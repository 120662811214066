import { AppPlace } from "@/components/routes/app-route.zod";
import { InjectMapProps } from "@/types/leaflet";
import { LatLngBounds, LatLngLiteral, PointExpression } from "leaflet";

/**
 * Map left padding to center route within the rigth side of the page
 * when card is shown.
 * 
 * Used for map animations and fly by views.
 */
const CARD_PAN_OFFSET_DX = 700;

/**
 * Map padding offset for correcting card offset when opened and closed
 * 
 * Used for map panning and animations.
 */
const CARD_PAN_OFFSET_HALF_DX = CARD_PAN_OFFSET_DX / 2;

export function panToCenter(map?: InjectMapProps["map"], open?: boolean, isTablet?: boolean) {
    if (isTablet || !map) {
        return;
    }

    const movement: PointExpression = !open
        ? [CARD_PAN_OFFSET_HALF_DX, 0]
        : [-CARD_PAN_OFFSET_HALF_DX, 0]

    map.panBy(movement, {
        animate: true,
        noMoveStart: true,
        duration: 0.5,
    })
}

export function tryZoomTo(map?: InjectMapProps["map"], from?: AppPlace, to?: AppPlace, isTablet?: boolean, willBeOpen?: boolean) {
    if (!map) {
        return;
    }

    if (!from || !to) {
        const potentialCenter = from?.latlng ?? to?.latlng;
        if (!potentialCenter) {
            return;
        }

        const bounds = new LatLngBounds(
            potentialCenter,
            potentialCenter
        )

        map.flyToBounds(bounds, {
            paddingTopLeft: isTablet ? undefined : willBeOpen ? [CARD_PAN_OFFSET_DX, 0] : [CARD_PAN_OFFSET_HALF_DX, 0],
            paddingBottomRight: isTablet ? undefined : willBeOpen ? [0, -25] : [0, -25],
            animate: true,
            noMoveStart: true,
            duration: 0.25,
            maxZoom: 10,
        })
        return;
    }

    const bounds = new LatLngBounds(
        from.latlng,
        to.latlng
    )

    map.flyToBounds(bounds, {
        paddingTopLeft: isTablet ? undefined : willBeOpen ? [CARD_PAN_OFFSET_DX, 0] : [CARD_PAN_OFFSET_HALF_DX, 0],
        paddingBottomRight: isTablet ? undefined : willBeOpen ? [0, -25] : [0, -25],
        animate: true,
        noMoveStart: true,
        duration: 0.25,
        maxZoom: 14.5,
    })
}

export async function tryGetPlace({ lat, lng }: LatLngLiteral): Promise<AppPlace | undefined> {
    return {
        key: `${lng.toFixed(5)},${lat.toFixed(5)}`,
        latlng: [lat, lng],
    };
}
