import { KeyCombo, Keys } from "@/components/ui/primitives/key-board-shortcuts";
import { MenuItem, MenuItemProps, MenuShortcut } from "@/components/ui/primitives/menu-item";

export function ReloadPageItem({ variant }: MenuItemProps) {
    return (
        <MenuItem
            variant={variant}
            onSelect={() => {
                window.location.reload()
            }}>
            Reload

            <MenuShortcut variant={variant}>
                <KeyCombo keyNames={[Keys.Control, 'R']} />
            </MenuShortcut>
        </MenuItem>
    )
}