import { Polyline } from "react-leaflet"
import { decode } from "@mapbox/polyline"
import { ComponentClassName } from "@/types/utility"
import { LegSchema, ItinerarySchema, PlaceSchema, } from "@/services/otp/validations/planner.graphql"
import { cn } from "@/lib/utils"
import { usePlanner } from "@/hooks/use-planner"
import { appRoute } from "../routes/app-route"
import { Curve } from "../ui/leaflet/curve"
import { getPlanKey, useIsSelectedPlan } from "@/hooks/use-is-selected-plan"
import { type LatLngExpression } from "leaflet"
import { TRANSER_TRIP_ID_KEY, DEVIATED_ROUTE_ID_KEY } from "@/config/otp"
import { Icons } from "../ui/icons"
import { Marker } from "../ui/leaflet/marker/marker"

export function OtpRoute() {
  const { isLoading, data, error } = usePlanner()

  if (error || isLoading || !data) {
    return
  }

  if (!data.plan || !data.plan.itineraries || data.plan.itineraries.length <= 0) {
    return;
  }

  return (
    <>
      {data.plan.itineraries.map((i) => {
        return <Legs
          key={getPlanKey(i)}
          {...i}
        />
      })}
    </>
  )
}

function Legs(plan: ItinerarySchema) {
  const { legs } = plan;
  const isSelected = useIsSelectedPlan(plan);
  if (!legs || legs.length <= 0) {
    return;
  }

  if (!isSelected) {
    return;
  }

  return (
    <>
      <OffRoute legs={legs} />

      {
        legs.map((l) => {
          return (
            <Leg
              key={`${getPlanKey(plan)}-${l?.legGeometry?.points}`}
              leg={l}
              className={cn(
                "transition-opacity animate-in fade-in duration-300 ease-in-out",
              )}
            />
          )
        })
      }
    </>
  )
}

function Leg(props: ComponentClassName & { leg: LegSchema }) {
  const { mode, route, trip } = props.leg;

  if (route?.gtfsId && route.gtfsId.includes(DEVIATED_ROUTE_ID_KEY)) {
    return <LegDevitedRoute {...props} />
  }

  if (trip?.gtfsId && trip.gtfsId.includes(TRANSER_TRIP_ID_KEY)) {
    return <LegOverCountyLine {...props} />
  }

  if (mode === 'WALK') {
    return <LegDevitedRoute
      {...props}
      dashArray={[8, 10]}
      className="stroke-route-foreground-4"
    />
  }

  return <LegCurved {...props} />

}

function LegCurved({ className, leg }: ComponentClassName & { leg: LegSchema }) {
  const { from, to } = leg
  if (!from?.lat || !from?.lon || !to?.lat || !to?.lon) {
    return;
  }

  return (
    <>
      <Curve
        className={cn(
          "stroke-[4px] opacity-90",
          "stroke-route-foreground-1",
          className
        )}
        start={[from.lat, from.lon]}
        end={[to.lat, to.lon]}
      />
      <Curve
        className={cn(
          "stroke-[12px] opacity-25",
          "stroke-route-foreground-1",
          className
        )}
        start={[from.lat, from.lon]}
        end={[to.lat, to.lon]}
      />
    </>
  )
}

function LegOverCountyLine({ className, leg }: ComponentClassName & { leg: LegSchema }) {
  const { from, to } = leg
  const mid = betweenTwoPoints(from, to)
  if (!from?.lat || !from?.lon || !to?.lat || !to?.lon || !mid) {
    return;
  }

  return (
    <>
      <Marker
        riseOnHover
        riseOffset={24}
        position={mid}
        icon={<div className="relative grid place-items-center">
          <span className="absolute min-w-max bg-background rounded-full p-2 shadow-card shadow-sm border-border border">
            <Icons.itinerary.indicator.multipleTransfers className="w-2 h-2 md:w-3.5 md:h-3.5" />
          </span>
        </div>
        }
      >
      </Marker>

      {/** Second arch - background */}
      <Curve
        className={cn(
          "stroke-[12px] opacity-25",
          "stroke-route-foreground-2",
          className
        )}
        start={mid}
        end={[to.lat, to.lon]} />
      <Curve
        className={cn(
          "stroke-[4px] opacity-100",
          "stroke-route-foreground-3",
          className
        )}
        start={mid}
        end={[to.lat, to.lon]} />

      {/** First arch - background */}
      <Curve
        className={cn(
          "stroke-[12px] opacity-25",
          "stroke-route-foreground-2",
          className
        )}
        start={[from.lat, from.lon]}
        end={mid} />
      <Curve
        className={cn(
          "stroke-[4px] opacity-100",
          "stroke-route-foreground-2",
          className
        )}
        start={[from.lat, from.lon]}
        end={mid} />
    </>
  )
}

function betweenTwoPoints(start?: PlaceSchema | null, end?: PlaceSchema | null): LatLngExpression | undefined {
  if (!start?.lat || !start?.lon || !end?.lat || !end?.lon) {
    return;
  }

  const midLat = (start.lat + end.lat) / 2
  const midLng = (start.lon + end.lon) / 2

  return [midLat, midLng]
}

function LegDevitedRoute({ className, leg, dashArray }: ComponentClassName & { leg: LegSchema, dashArray?: string | number[] }) {
  const { legGeometry: encoded } = leg;

  if (!encoded?.points) {
    return;
  }

  const positions = decode(encoded.points)

  if (!positions || positions.length <= 0) {
    return;
  }

  return (
    <>
      <Polyline
        positions={positions}
        className={cn(
          "stroke-[4px] opacity-90",
          "stroke-route-foreground-1",
          className
        )}
        dashArray={dashArray}
        lineJoin="round"
      />

      <Polyline
        positions={positions}
        className={cn(
          "stroke-[12px] opacity-25",
          "stroke-route-foreground-1",
          className
        )}
      />
    </>
  )
}

/**
 * Leaflet map graphics representing Open Trip Planner route steps not handled by OTP. E.g., route
 * from the starting position to the first leg, route from last leg to end position, etc.
 * 
 * @returns React Component
 */
function OffRoute({ className, legs }: ComponentClassName & { legs: LegSchema[] }) {
  const from = appRoute.useSearch({ select: (search) => search.from })
  const to = appRoute.useSearch({ select: (search) => search.to })

  const { legGeometry: firstEncoded } = legs[0];
  const { legGeometry: secondEncoded } = legs[legs.length - 1];
  if (!firstEncoded?.points || !secondEncoded?.points) {
    return;
  }

  const firstPositions = decode(firstEncoded.points)

  const secondPositions = decode(secondEncoded.points)


  return (
    <>
      <Curve
        dashArray={[2, 8]}
        className={cn("stroke-route-foreground-4 opacity-40", className)}
        start={from?.latlng}
        end={firstPositions[0]} />
      <Curve
        dashArray={[2, 8]}
        className={cn("stroke-route-foreground-4 opacity-40", className)}
        start={to?.latlng}
        end={secondPositions[secondPositions.length - 1]} />

    </>
  )
}