import { Distance } from "../distance";

const METERS_FORMATTER = new Intl.NumberFormat("en-US", { 
  style: 'unit',
  unit: 'meter',
  unitDisplay: 'short',
  maximumFractionDigits: 1
});

const MILES_FORMATTER = new Intl.NumberFormat("en-US", { 
  style: 'unit',
  unit: 'mile',
  unitDisplay: 'short',
  maximumFractionDigits: 2
});


export function metersFromNumberFormatter(meters: number | null | undefined) {
  if (typeof meters !== "number") {
    return;
  }

  return metersFormatter(Distance.fromMeters(meters))
}

export function metersToMilesFormatter(meters: number | null | undefined) {
  if (typeof meters !== "number") {
    return;
  }

  return milesFormatter(Distance.fromMeters(meters))
}


export function milesFormatter(d: Distance) {
  return `${MILES_FORMATTER.format(d.totalMiles)}`;
}

export function metersFormatter(d: Distance) {
  return `${METERS_FORMATTER.format(d.totalMeters)}`;
}