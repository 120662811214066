import { useSetAppSearch } from "@/components/routes/app-route.hooks";
import { AppTransportType } from "@/components/routes/app-route.zod";
import { Button } from "@/components/ui/form/button";
import { Label } from "@/components/ui/form/label";
import { RadioCardGroup, RadioCardItem } from "@/components/ui/form/radio-card-group";
import Typography from "@/components/ui/typeography/typeography";
import { useAppTransportType } from "@/hooks/use-app-transport-type";
import { cn } from "@/lib/utils";
import { RadioGroupProps } from "@radix-ui/react-radio-group";
import React, { useCallback } from "react";

const TRIGGER_CONTENT = "Options"

interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    asChild?: boolean
}

export const ItineraryFilterTrigger = React.forwardRef<HTMLButtonElement, ButtonProps>((_props, _ref) => {

    return (
        <span className="flex flex-row gap-3">
            <FilterRadioGroupMedical
            />

            {/** Based on current requests, medical trips are the primary and only filter. Bypass modal based filtering until expanding options */}
            {/* <FilterButtonWithIndicator
                ref={ref}
                {...props}
            /> */}
        </span>
    )
});
ItineraryFilterTrigger.displayName = "ItineraryFilterTrigger";

export const FilterButtonWithIndicator = React.forwardRef<HTMLButtonElement, ButtonProps>(({
    className,
    ...props
}, ref) => {

    return (
        <Button
            ref={ref}
            variant="outline"
            className={cn("relative overflow-visible flex flex-row gap-1 flex-1", className)}
            {...props}
        >
            <Typography variant={"p"} affects={"smallLight"} override={"removeMarginsAndLeading"}>
                {TRIGGER_CONTENT}
            </Typography>
            <FilterButtonIndicator />
        </Button>
    )
});

FilterButtonWithIndicator.displayName = "TriggerButtonWithIndicator"

function FilterButtonIndicator() {
    const transportType = useAppTransportType()
    if (transportType !== "m") {
        return;
    }

    return (
        <p className="text-xs text-subtle-foreground font-thin">(Modified)</p>
    )
}

export const FilterRadioGroupMedical = React.forwardRef<HTMLDivElement, RadioGroupProps>(({
    className,
    ...props
}, ref) => {
    const { set } = useSetAppSearch()
    const transportType = useAppTransportType()
    const setTransportType = useCallback((value: AppTransportType) => {
        set((prev) => {
            if (!prev.data?.transportType) {
                return prev;
            }

            prev.data.transportType = value

            return prev
        })


    }, [set])

    return (
        <>
            <form className="w-full">
                <fieldset className="space-y-1">
                    <Label htmlFor="restrictions" className="font-medium">
                        Restrictions
                    </Label>
                    <RadioCardGroup
                        id="restrictions"
                        ref={ref}
                        value={transportType}
                        onValueChange={setTransportType}
                        className={cn("grid grid-cols-2 gap-4 text-sm md:grid-cols-2", className)}
                        {...props}
                    >
                        <RadioCardItem key={'no-filter'} value={'n' satisfies AppTransportType}>
                            <div>
                                <span className="text-xs">
                                    No restrictions
                                </span>
                                <Typography color={"muted"} variant={"p"} affects={"xsmall"} override={"removeMarginsAndLeading"} className="mt-1 leading-4">
                                    All services will be shown below.
                                </Typography>
                            </div>
                        </RadioCardItem>
                        <RadioCardItem key={'restricted-services'} value={'m' satisfies AppTransportType}>
                            <div>
                                <span className="text-xs">
                                    Eligibility Restricted Services Only
                                </span>
                                <Typography color={"muted"} variant={"p"} affects={"xsmall"} override={"removeMarginsAndLeading"} className="mt-1 leading-4">
                                    Medical, veteran, etc.
                                </Typography>
                            </div>
                        </RadioCardItem>
                    </RadioCardGroup>
                </fieldset>
            </form>
        </>
    )
})
FilterRadioGroupMedical.displayName = "FilterRadioGroupMedical"
