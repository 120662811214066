import { ComponentClassName } from "@/types/utility"
import { cn } from "@/lib/utils"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/containers/popover"
import { Button } from "@/components/ui/form/button"
import { Icons } from "@/components/ui/icons"

import { OpenPlannerButton } from "./open-planner-button"
import { InjectMapProps } from "@/types/leaflet"
import { OpenHelpButton } from "./open-help-button"
import { DropdownMenuActions } from "../menus/dropdown-menu.actions"

export function MoreActionsMobile({ className, map }: ComponentClassName<InjectMapProps>) {
  return (
    <Popover>
      <PopoverTrigger asChild className={cn("sm:hidden", className)}>
        <Button
          variant={"outline"}
          size={"icon"}
          className="w-20 h-20 sm:w-16 sm:h-16 !rounded-full"
        >
          <Icons.actions.more className="w-4 h-4" />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        sideOffset={8}
        className={
          "flex-col-reverse gap-1 flex bg-transparent w-auto p-0 shadow-none border-transparent"
        }
      >
        <DropdownMenuActions className="grid h-20 w-20 sm:hidden !rounded-full" map={map} />
        
        <OpenPlannerButton map={map} className="grid h-20 w-20 sm:hidden !rounded-full" />

        <OpenHelpButton className="grid h-20 w-20 sm:hidden !rounded-full" />
      </PopoverContent>
    </Popover>
  )
}
