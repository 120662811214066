import { LocationClient } from "@aws-sdk/client-location"

import { setupClient } from "./provider"
import { query } from "./query"

let clientPromise: Promise<LocationClient> | undefined
const nullClient: LocationClient = new LocationClient()

export const aws: {
  query: typeof query
  client: LocationClient
  setup: () => Promise<void>
  teardown: () => void
} = {
  query: query,
  client: nullClient,
  setup: async () => {
    clientPromise = clientPromise ? clientPromise : setupClient()
    aws.client = await clientPromise
  },
  teardown: () => {
    aws.client = nullClient
    clientPromise = undefined
  },
} as const
