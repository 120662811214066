import { z } from "zod";

export const pickupInformationSchemaExtended = z.object({
    prior_notice_duration_max: z.number().optional(),
    prior_notice_duration_min: z.union([z.string(), z.number()]).optional(),
    prior_notice_start_day: z.number().optional(),
    prior_notice_start_time: z.number().optional(),
    prior_notice_last_day: z.number().optional(),
    prior_notice_last_time: z.number().optional(),
    pickup_message: z.number().optional(),
})
