import { validatedFetch } from '@/services/zod/validated-fetch';
import { z } from 'zod';
import { ValidateConfig } from '@/services/zod/validator';
import { QueryName } from '@/services/otp/validations/planner.graphql';

export function generateGraphqlQuery<
    TReturnArgs = unknown,
    TQueryString extends string = string,
    TQueryName extends QueryName<TQueryString> = QueryName<TQueryString>,
    TZodValidation extends z.ZodTypeAny = z.ZodTypeAny,
>(
    query: TQueryString,
    name: TQueryName,
    url: string,
    validation: ValidateConfig<TZodValidation>["schema"]
) {
    return (props?: TReturnArgs) => {
        return validatedFetch({
            url: url,
            schema: validation,
            init: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    operationName: name,
                    query: query,
                    variables: props
                })
            }
        });
    }
}
