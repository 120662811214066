import { startOfToday as fnsStartOfToday } from "date-fns"

/**
 * Start of today.
 */
export const startOfToday = fnsStartOfToday()

/**
 * Today
 */
export const defaultDate = new Date()