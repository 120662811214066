import { PlanQueryParams } from "@/types/otp/plan.graphql";
import { ItinerarySchema, LegSchema, QueryName, planGraphqlResponse } from "../validations/planner.graphql";
import { generateGraphqlQuery } from "./generate-otp-query.graphql";
import { z } from "zod";
import { OtpDate, OtpTime } from "@/types/otp/plan.graphql";
import { addSeconds } from "date-fns";
import { PickupInformationExtended, AppItinerary, ItineraryCategories, ItinerarySchemaExtended, LegSchemaExtended, PlanResponseExtended } from "@/types/otp/+extended";
import { pickupInformationSchemaExtended } from "../validations/+extended";
import { validateSchemaSafe } from "@/services/zod/validator";
import { generateHash } from "@/lib/hash";
import { GENERATE_HASH_IGNORE_KEYS_FOR_LEGS } from "@/config/otp";

export function generatePlannerQuery<
  TQueryString extends string = string,
  TQueryName extends QueryName<TQueryString> = QueryName<TQueryString>,
>(
  query: TQueryString,
  name: TQueryName,
  url: string,
) {
  const q = generateGraphqlQuery<
    PlanQueryParams,
    TQueryString,
    TQueryName,
    typeof planGraphqlResponse
  >(
    query,
    name,
    url,
    planGraphqlResponse
  );

  return async (props?: PlanQueryParams) => {
    const res = await q(props);
    const extendedRes = middleware(res, props)
    return extendedRes
  };
}

function middleware(res: z.infer<typeof planGraphqlResponse>, props?: PlanQueryParams) {
  const itineraries = filterItineraries(res, props);
  const { data, ...rest } = res

  return {
    ...rest,
    data: {
      ...data,
      extended: {
        itineraries
      }
    } satisfies PlanResponseExtended
  }
}

/**
 * Filters and removes itineraries based on specific query parameters.
 * 
 * MIDDLEWARE IS USED SINCE OTP CURRENTLY DOES NOT SUPPORT SOME PARAMETERS WITH FLEX ROUTES
 * 
 * @param props 
 * @param res
 */
function filterItineraries(res: z.infer<typeof planGraphqlResponse>, props?: PlanQueryParams) {
  if (!res.data?.plan?.itineraries || !props) {
    return;
  }

  const bannedRoutes = props.banned?.routes?.split(",")
  const bannedAgencies = props.banned?.agencies?.split(",")
  const bannedTrips = props.banned?.agencies?.split(",")
  const searchWindow = getSearchWindow(props)

  const seenHashes: Set<string> = new Set()
  const seenFutureHashes: Set<string> = new Set()

  const all: AppItinerary[] = [] satisfies AppItinerary[]
  const valid: ItinerarySchemaExtended[] = [] satisfies ItinerarySchemaExtended[]
  const future: ItinerarySchema[] = [] satisfies ItinerarySchema[]
  const filtered: ItinerarySchema[] = [] satisfies ItinerarySchema[]
  for (const itinerary of res.data.plan.itineraries) {
    if (!itinerary.legs) {
      all.push(itinerary)
      filtered.push(itinerary)
      continue;
    }

    const routeIds: string[] = [];
    let containsOnlyWalking = true;
    const legMatchesFilter = itinerary.legs.find((l) => {
      containsOnlyWalking &&= l.mode === 'WALK';
      if (l.route?.gtfsId) {
        routeIds.push(l.route?.gtfsId);
      }

      return (
        l.route?.gtfsId && bannedRoutes && bannedRoutes.includes(l.route.gtfsId) ||
        l.agency?.gtfsId && bannedAgencies && bannedAgencies.includes(l.agency.gtfsId) ||
        l.trip?.gtfsId && bannedTrips && bannedTrips.includes(l.trip.gtfsId)
      );
    })

    if (legMatchesFilter ||
      containsOnlyWalking ||
      (typeof props.maxRouteCountMiddleware === "number" && routeIds.length > props.maxRouteCountMiddleware)
    ) {
      all.push(itinerary)
      filtered.push(itinerary)
      continue;
    }

    const legHash = generateHash(itinerary.legs, { ignore: GENERATE_HASH_IGNORE_KEYS_FOR_LEGS })
    const isFuture = isFutureItinerary(itinerary, props, searchWindow);
    if (isFuture && seenFutureHashes.has(legHash)) {
      all.push(itinerary)
      filtered.push(itinerary)
      continue;
    }

    if (isFuture && !seenFutureHashes.has(legHash)) {
      seenFutureHashes.add(legHash)
      all.push(itinerary)
      future.push(itinerary)
      continue;
    }

    if (seenHashes.has(legHash)) {
      all.push(itinerary)
      filtered.push(itinerary)
      continue;
    }

    seenHashes.add(legHash)

    const extended = extendItinerary(itinerary);
    if (extended) {
      all.push(extended)
      valid.push(extended)
    } else {
      all.push(itinerary)
      filtered.push(itinerary)
    }
  }

  return {
    valid,
    filtered,
    future,
    all: all,
  } satisfies ItineraryCategories
}

function isFutureItinerary(itinerary: ItinerarySchema, props?: PlanQueryParams, searchWindow?: Date) {
  if (!props) {
    return;
  }

  if (props.arriveBy) {
    return itinerary.endTime && searchWindow && new Date(itinerary.endTime) > searchWindow
  }

  return itinerary.startTime && searchWindow && new Date(itinerary.startTime) > searchWindow
}

function getSearchWindow(props?: PlanQueryParams) {
  if (!props || !props.searchWindowMiddleware) {
    return undefined;
  }

  const startTime = getSearchWindowStartTime(props.date, props.time);

  const queryStartTime = addSeconds(startTime, props.searchWindowMiddleware)

  return queryStartTime
}

export function getSearchWindowStartTime(date?: OtpDate, time?: OtpTime, now = new Date()) {
  if (date && time) {
    return new Date(`${date} ${time}`)
  }

  if (date) {
    return new Date(`${date}`)
  }

  if (time) {
    return new Date(`${now.toDateString()} ${date}`)
  }

  return new Date()
}

function extendItinerary(i: ItinerarySchema): ItinerarySchemaExtended | undefined {
  if (!i.legs || i.legs.length <= 0) {
    return undefined;
  }

  const legs: LegSchemaExtended[] = [];
  for (const leg of i.legs) {
    const agencyInformation = tryGetPickupInformation(leg)

    legs.push({
      ...leg,
      extended: {
        pickupInformation: agencyInformation
      }
    })
  }

  return {
    ...i,
    legs: legs
  };
}

function tryGetPickupInformation(leg: LegSchema): PickupInformationExtended | undefined {
  const messageToParse = leg.pickupBookingInfo?.pickupMessage ?? leg.dropOffBookingInfo?.pickupMessage
  if (!messageToParse) {
    return;
  }

  return validateSchemaSafe({
    dto: JSON.parse(messageToParse),
    schema: pickupInformationSchemaExtended,
    schemaName: "PICKUP INFORMATION EXTENDED",
  }, {
    messagePrefix: "Pickup Information Validation Error:"
  });
}
