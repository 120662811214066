import { ReactNode, useState, forwardRef, ComponentRef } from "react"
import { MapContainer } from "react-leaflet"
import { MapContextMenu } from "../menus/context-menu.map"
import { Map } from 'leaflet'

export const MapWrapper = forwardRef<ComponentRef<typeof MapContainer>, { children: ReactNode }>(({ children }, ref) => {
  const [map, setMap] = useState<Map | null>(null);

  return (
    <MapContextMenu map={map}>
      <MapContainer
        ref={(map) => {
          setMap(map)

          if (typeof ref === "function") {
            ref(map)
          } else if (ref) {
            ref.current === map
          }
        }}
        className="z-0 h-full w-full"
        attributionControl={false}
        zoomControl={false}
        scrollWheelZoom
        maxZoom={15}
      >
        {children}
      </MapContainer>
    </MapContextMenu>
  )
})

MapWrapper.displayName = "MapWrapper"
