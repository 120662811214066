import { appRoute } from "@/components/routes/app-route";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/containers/tool-tip";
import { MenuItem, MenuItemProps } from "@/components/ui/primitives/menu-item";
import { useMediaQuery } from "@/hooks/use-media-query";
import { tryZoomTo } from "@/lib/places";
import { InjectMapProps } from "@/types/leaflet";

export function ZoomToRouteItem({ map, variant }: InjectMapProps & MenuItemProps) {
    const { data, to, from } = appRoute.useSearch();
    const isTablet = useMediaQuery("(max-width: 768px)")
    const isDisabled = !from && !to;

    return (
        <Tooltip >
            <TooltipTrigger className="w-full cursor-default">
                <MenuItem
                    variant={variant}
                    disabled={isDisabled}
                    onClick={() => {
                        tryZoomTo(map, from, to, isTablet, data?.open)
                    }}
                >
                    Zoom to Route
                </MenuItem>

            </TooltipTrigger>

            <TooltipContent side="left" sideOffset={6}>
                {
                    isDisabled
                        ? "Select start and end points to enable."
                        : "Centers map camera over the route."
                }
            </TooltipContent>
        </Tooltip>
    )
}