import { useEffect, useState } from "react"
import { type LatLngLiteral } from "leaflet"

export function useCurrentLocation() {
  const [position, setPosition] = useState<LatLngLiteral | undefined>()

  useEffect(() => {
    requestCurrentLocation(setPosition)
  }, [])

  return { position }
}

/**
 * Asks permission from user to use current location, approximate address.
 * @param callback
 */
export function requestCurrentLocation(
  callback: (latlng: LatLngLiteral) => void
) {
  navigator.geolocation.getCurrentPosition(
    ({ coords }) => {
      callback({
        lat: coords.latitude,
        lng: coords.longitude,
        alt: coords.altitude ?? undefined,
      })
    },
    (blocked) => {
      if (blocked) {
        const trySetIpLocation = async () => {
          try {
            const res = await fetch("https://ipapi.co/json")
            const position = await res.json()
            if (!("latitude" in position && "longitude" in position)) {
              throw new Error("Unrecognized response from location fetch.")
            }

            callback({ lat: position.latitude, lng: position.longitude })
          } catch (err) {
            console.error(err)
          }
        }

        trySetIpLocation()
      }
    }
  )
}
