import { Icons } from "@/components/ui/icons";
import { ComponentClassName } from "@/types/utility";
import { CardMessageWrapper } from "../../states/itineraries-state.contianer";
import { cn } from "@/lib/utils";

export function ItineraryDetailsLoading({ className }: ComponentClassName) {
    return (
        <CardMessageWrapper
            className={cn("animate-pulse", className)}
            icon={<Icons.plan.loading />}
        >
            We are currently working on finding you the best routes. Hold on while we make some calculations!
        </CardMessageWrapper>
    )
}

