import { TileLayer } from "react-leaflet"

import { GeoJsonLoader } from "./geojson-loader"
import { MapMarkers } from "./map-markers"
import { OtpRoute } from "./otp-route"
import { MapCamera } from "./map-camera"
import { MapDropMarker } from "./map-drop-marker"

export function MapContent() {
  return (
    <>
      <MapTiles />

      <GeoJsonLoader />

      <MapMarkers />

      <OtpRoute />

      <MapCamera />

      <MapDropMarker />
    </>
  )
}

export function MapTiles() {

  return (
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  )
}