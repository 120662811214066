"use client";

import * as React from "react";
import { add, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { startOfToday } from "@/config/time";
import { Button } from "@/components/ui/form/button";
import { Calendar } from "@/components/ui/primitives/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/containers/popover";
import { TimePickerSet } from "./time-picker-set";
import { Separator } from "../../primitives/separator";
import { DatePickerProps } from "./date-time-picker.props";
import { PopoverClose } from "@radix-ui/react-popover";

export const DateTimePicker = React.forwardRef<HTMLDivElement, DatePickerProps>(
  (
    {
      className,
      selected,
      onSelect,
      disabled = { before: startOfToday },
      size = "default",
      variant = "outline",
      ...rest
    },
    ref,
  ) => {
    const [date, setDate] = React.useState<Date | undefined>(selected);
    const [initialDate] = React.useState<Date | undefined>(selected);

    /**
     * carry over the current time when a user clicks a new day
     * instead of resetting to 00:00
     */
    const handleSelect = React.useCallback((newDay: Date | undefined) => {
      if (!newDay) return;
      if (!date) {
        setDate(newDay);
        return;
      }
      const diff = newDay.getTime() - date.getTime();
      const diffInDays = diff / (1000 * 60 * 60 * 24);
      const newDateFull = add(date, { days: Math.ceil(diffInDays) });
      setDate(newDateFull);

    }, [date]);

    return (
      <div
        ref={ref}
        className={cn(
          "grid w-full",
          className,
        )}
        {...rest}>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              size={size}
              variant={variant}
              className={cn(
                "w-full justify-start text-left font-normal",
                !date && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {date ? format(date, "PPP hh:mm aa") : <span>Pick date & time</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="p-0 flex flex-col justify-center items-center" onOpenAutoFocus={undefined}>
            <Calendar
              mode="single"
              disabled={disabled}
              selected={date}
              onSelect={handleSelect}
              initialFocus
            />

            <Separator />

            <TimePickerSet
              className="m-2"
              date={date}
              setDate={setDate}
            />

            <div className="py-3 px-2 md:px-8 w-full gap-2 flex flex-wrap [&>*]:min-w-fit [&>*]:flex-1">
              <PopoverClose asChild>
                <Button
                  variant={"ghost"}
                  className="text-secondary text-pretty"
                  onClick={() => {
                    setDate(initialDate);
                  }}
                >
                  Close without applying
                </Button>
              </PopoverClose>

              <PopoverClose asChild>
                <Button
                  variant={"default"}
                  className="text-primary-foreground text-pretty"
                  onClick={() => {
                    onSelect?.(date, undefined, undefined, undefined);
                  }}
                >
                  Apply date
                </Button>
              </PopoverClose>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  })

DateTimePicker.displayName = "DateTimePicker";
