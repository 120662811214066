import { getPlanKey } from "@/hooks/use-is-selected-plan";
import { ItineraryCard } from "../itinerary-card";
import { AppItinerary } from "@/types/otp/+extended";
import { ItineraryCardNoRoutes } from "../states/itinerary.no-routes";

export function ItineraryListContent({
    itineraries
}: {
    itineraries?: AppItinerary[] | null
}) {
    if (!itineraries || itineraries.length <= 0) {
        return <ItineraryCardNoRoutes />
    }

    return (
        <div className="flex flex-col gap-4">
            {itineraries.map((i) => {
                return (
                    <ItineraryCard
                        key={getPlanKey(i)}
                        {...i}
                    />
                )
            })}
        </div>
    )
}
