import { ComponentClassName } from "@/types/utility";
import { PropsWithChildren, useState } from "react";
import { ContextMenu, ContextMenuContent, ContextMenuGroup, ContextMenuItem, ContextMenuLabel, ContextMenuSeparator, ContextMenuSub, ContextMenuSubContent, ContextMenuSubTrigger, ContextMenuTrigger } from "../ui/primitives/context-menu";
import { LatLng, Map } from "leaflet";
import { SetPlaceItemStatic } from "./shared/set-place-item.static";
import { SetCurrentLocationItem } from "./shared/set-current-location-item";
import { ReloadPageItem } from "./shared/reload-page-item";
import { ClearMarkersItem } from "./shared/clear-markers-item";
import { ZoomToRouteItem } from "./shared/zoom-to-route-item";
import { cn } from "@/lib/utils";
import { useDropMarker } from "@/hooks/use-drop-marker";

type MapContextMenuPropsInternal = ComponentClassName & {
    map: Map | null
}

export type MapContextMenuProps = PropsWithChildren<MapContextMenuPropsInternal>

export function MapContextMenu({ map, className, children }: MapContextMenuProps) {
    const [contextOpenedAt, setContextOpenedAt] = useState<LatLng | undefined>();
    const [isDropping, setDropping] = useDropMarker()

    if (isDropping) {
        return (

            <ContextMenu>
                <ContextMenuTrigger
                    className={cn("select-none", className)}
                >
                    {children}
                </ContextMenuTrigger>
                <ContextMenuContent>
                    <ContextMenuItem
                        onClick={() => setDropping(undefined)}
                    >
                        Cancel
                    </ContextMenuItem>
                </ContextMenuContent>
            </ContextMenu>
        )
    }

    return (
        <>
            <ContextMenu
                onOpenChange={(open) => {
                    if (open) {
                        return;
                    }

                    setContextOpenedAt(undefined);
                }}>
                <ContextMenuTrigger
                    className={cn("select-none", className)}
                    onContextMenu={(e) => {
                        setContextOpenedAt(map?.containerPointToLatLng([e.clientX, e.clientY]))
                    }}
                >
                    {children}
                </ContextMenuTrigger>

                <ContextMenuContent className="md:w-64">
                    {/** Command - Reload Page */}
                    <ReloadPageItem />

                    <ContextMenuSeparator />

                    {/** Command - Current location */}
                    <SetCurrentLocationItem />

                    {/** Command - Clear markers */}
                    <ClearMarkersItem />

                    <ContextMenuSub>
                        <ContextMenuSubTrigger>
                            Place Marker
                        </ContextMenuSubTrigger>

                        <ContextMenuSubContent>

                            {/** Command - Set start */}
                            <SetPlaceItemStatic
                                location="from"
                                contextOpenedAt={contextOpenedAt}
                                map={map}
                            >
                            </SetPlaceItemStatic>

                            {/** Command - Set end */}
                            <SetPlaceItemStatic
                                location="to"
                                contextOpenedAt={contextOpenedAt}
                                map={map}
                            />
                        </ContextMenuSubContent>
                    </ContextMenuSub>

                    <ContextMenuSeparator />

                    <ContextMenuGroup>
                        <ContextMenuLabel>Camera</ContextMenuLabel>

                        <ZoomToRouteItem map={map} />
                    </ContextMenuGroup>
                </ContextMenuContent>
            </ContextMenu>
        </>
    )
}

